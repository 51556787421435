import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGridWrapper from "../dataGridWrapper";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import { IOnboard } from "../../interfaces/IOnboard";
import { IRegisteredOnboard } from "../../interfaces/IRegisteredOnboard";

export default function RegisteredOnboard({
  onRowClick,
  dataUpdated = 0,
}: IRegisteredOnboard) {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [onboardApi, setOnboardApi] = useState();
  const [errorFetching, setErrorFetching] = useState(false);

  useEffect(() => {
    axios
      .get(`${CONFIG.API_URL}onboard?countryCode=${countryCode}`, tokenHeaders)
      .then((response) => {
        setOnboardApi(response.data);
      })
      .catch(() => {
        setErrorFetching(true);
      });
  }, [dataUpdated, countryCode]);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t("onboard.Title"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "featuredTitle",
      headerName: t("onboard.Featured title"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "description",
      headerName: t("onboard.Description"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "notACustomer",
      headerName: t("onboard.URL I'm not a customer"),
      width: 300,
      headerClassName: "header-style",
    },
  ];

  const rows: any = onboardApi;

  if (!isAuthenticated) {
    return (
      <Box>
        <Alert severity="error">{t("userNotSigned.title")}</Alert>
        <Alert sx={{ marginTop: 2 }} severity="warning">
          {t("userNotSigned.warning")}
        </Alert>
      </Box>
    );
  }

  if (errorFetching) {
    return (
      <>
        <Alert severity="error">
          There was an error when fetching your request. Either the Onboard list
          is empty or we are having trouble.
        </Alert>

        <Alert severity="warning" sx={{ marginY: "1rem" }}>
          Try to add a new Onboard screen and refresh the page.
        </Alert>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh data
        </Button>
      </>
    );
  }

  return (
    <Box>
      {onboardApi ? (
        <Box
          sx={{
            width: "100%",
            "& .header-style": {
              backgroundColor: "var(--gray-100)",
            },
          }}
        >
          <DataGridWrapper
            getRowId={(row: IOnboard) => row.onboardId}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            onRowClick={onRowClick}
          />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
