import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import SearchVehiclePills from "../../components/pillsOrdering/searchVehiclePills";

export default function PillsOrdering() {
  const { t } = useTranslation();

  return (
    <BoxContent title={t("menu.pillsordering")} noGlobal>
      <SearchVehiclePills />
    </BoxContent>
  );
}
