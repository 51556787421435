import { Box, Grid } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { AuthContext } from "../../contexts/AuthContext";
import { IManual } from "../../interfaces/IManual";
import { CONFIG } from "../../services/config";
import convertToPtBrDate from "../../utils/convertToPtBrDate";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

// interface FormValues {
//   title: string;
//   image: string;
// }

interface IimageSelected {
  name: string;
  extension: string;
  imageBase: string;
  isVideo: boolean;
}

export default function EditManual({
  data,
  watchDataChange,
  updateData,
  messageError,
}: IManual) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [imageSelected, setImageSelected] = useState<IimageSelected>();

  useEffect(() => {
    const ext = data.imagePath.split(".")?.pop();
    const isVideo = ["mp4", "webm"].includes(ext || "");
    const imageInfo = {
      name: data.name,
      extension: "",
      imageBase: data.imagePath,
      isVideo,
    };
    setImageSelected(imageInfo);
  }, []);

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];
    const preview = document.getElementById("imageChosen") as HTMLImageElement;

    const lastIndex = file.name.lastIndexOf(".");

    const name = file.name.slice(0, lastIndex);
    const extension = file.name.slice(lastIndex + 1);
    const isVideo = ["mp4", "webm"].includes(extension);
    let limitSize;

    if (extension === "mp4") {
      limitSize = 30000000;
    } else {
      limitSize = 2000000;
    }

    if (file.size > limitSize) {
      alert(`${t("alert.imageSize")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png|mp4)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      const imageBase = image[1];
      const imageInfo = {
        name,
        extension,
        imageBase,
        isVideo,
      };

      setImageSelected(imageInfo);
    };

    reader.readAsDataURL(file);
  };

  // const addFormData = yup.object().shape({
  //   title: yup.string().required(t("manual.Required title")),
  // });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm<FormValues>({
  //   resolver: yupResolver(addFormData),
  // });

  const messageImageRequired = () => {
    const contactList = document.querySelector(
      "#requiredContactList",
    ) as HTMLDivElement;
    contactList.style.display = "block";
    setTimeout(() => {
      contactList.style.display = "none";
    }, 5000);
  };

  const editManual = async () => {
    if (!imageSelected) {
      return messageImageRequired();
    }

    try {
      await axios.put(
        `${CONFIG.API_URL}manual?id=${data.id}`,
        {
          name: data.name,
          image: imageSelected ? imageSelected?.imageBase : data.imagePath,
          ext: imageSelected ? `.${imageSelected.extension}` : "",
          active: data.active,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("manual.Image edited"));
  };

  const deleteManual = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}manual?id=${data?.id}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("manual.Image deleted"));
  };

  return (
    <Box>
      <FormInputText
        label={t("manual.Identifier")}
        id="editTitle"
        defaultValue={data.name}
        disabled
      />

      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnImage")}</span>
            <input
              accept=".png, .jpg, .jpeg, .mp4"
              className="imageFile"
              type="file"
              name="fileInput"
              onChange={(event) => {
                convertImageToBase64(event);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          {data.imagePath && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <span>{t("countries.Current image")}:</span>
              <Box>
                <a href={data.imagePath} download={data.name}>
                  {imageSelected?.isVideo ? (
                    <PlayCircleIcon width={130} />
                  ) : (
                    <img
                      src={data.imagePath}
                      alt={data.name}
                      width="130px"
                      id="imageChosen"
                      title={data.name}
                    />
                  )}
                </a>
                <br />
                <Box
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  // onClick={deleteImage}
                >
                  <a
                    style={{
                      textDecoration: "solid",
                      color: "var(--blue-100)",
                    }}
                    href={data.imagePath}
                    download={data.name}
                  >
                    {t("manual.downloadImage")}
                  </a>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          color: "var(--red-100)",
          display: "none",
        }}
        id="imageRequired"
      >
        <span>{t("manual.requiredImage")}</span>
      </Box>

      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        direction="row"
        spacing={2}
      >
        <Grid item xs={6}>
          <p>
            <strong>{t("manual.createdBy")}:</strong> {data.createdBy}
          </p>
          <p>
            <strong>{t("manual.Date")}:</strong>{" "}
            {convertToPtBrDate(data.createdAt)}
          </p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <strong>{t("manual.updatedBy")}:</strong> {data.updatedBy}
          </p>
          <p>
            <strong>{t("manual.Date")}:</strong>{" "}
            {convertToPtBrDate(data.updatedAt)}
          </p>
        </Grid>
      </Grid>

      <ActionButtons
        saveName={t("button.Save")}
        save={editManual}
        confirmDelete={deleteManual}
      />
    </Box>
  );
}
