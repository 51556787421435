import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { IHeaders } from "../interfaces/IHeaders";
import { CONFIG } from "../services/config";
import useCookies from "../services/CookieService";

const refreshToken = async (code: string) => {
  console.log("refresh called");
  const { setCookie } = useCookies();
  await axios
    .post(
      `${CONFIG.VW_ID_BASEURL}v1/token`,
      `grant_type=authorization_code&code=${code}&redirect_uri=${CONFIG.CALLBACK_URL}`,
      {
        headers: {
          Authorization: `Basic ${btoa(
            `${CONFIG.VW_ID_CLIENT_ID}:${CONFIG.VW_ID_CLIENT_SECRET}`,
          )}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    )
    .then((response: any) => {
      response.data
        ? setCookie({
            name: "refreshToken",
            value: response.data.refresh_token,
          })
        : null;
    });
};

const updateUserToken = async (
  setTokenHeaders: Dispatch<SetStateAction<IHeaders>>,
) => {
  const { setCookie, findCookie } = useCookies();
  const refreshTokenCode = findCookie("refreshToken")?.value;

  const response = await axios.post(
    `${CONFIG.VW_ID_BASEURL}v1/token`,
    `grant_type=refresh_token&refresh_token=${refreshTokenCode}&client_id=${CONFIG.VW_ID_CLIENT_ID}`,
    {
      headers: {
        Authorization: `Basic ${btoa(
          `${CONFIG.VW_ID_CLIENT_ID}:${CONFIG.VW_ID_CLIENT_SECRET}`,
        )}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  );

  setCookie({
    name: "accessToken",
    value: response.data.access_token,
    maxSeconds: Number(response.data.expires_in),
  });
  setCookie({
    name: "idToken",
    value: response.data.id_token,
    maxSeconds: Number(response.data.expires_in),
  });
  setCookie({
    name: "refreshToken",
    value: response.data.refresh_token,
  });
  setTokenHeaders({
    headers: {
      authorization: `Bearer ${response.data.access_token}`,
      idToken: response.data.id_token,
      countryCode: `${findCookie("countryCode")?.value}`,
    },
  });
};

export { refreshToken, updateUserToken };
