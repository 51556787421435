import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BoxContent from "../../../components/boxContent";
import QuickAlert from "../../../components/form/quickAlert";
import MyModal from "../../../components/MyModal";
import { AuthContext } from "../../../contexts/AuthContext";
import { IOwnersManual } from "../../../interfaces/IOwnersManual";
import { IQuickAlert } from "../../../interfaces/IQuickAlert";
import { IVehicle } from "../../../interfaces/IVehicle";
import { IVehicleVersion } from "../../../interfaces/IVehicleVersion";
import { Iversions } from "../../../interfaces/Iversions";
import { CONFIG } from "../../../services/config";
import AddManual from "./add-manual";
import Version from "./version";

export default function VersionsList() {
  const { id, name } = useLocation().state as IVehicle;

  const { t } = useTranslation();

  const [allYears, setAllYears] = useState<string[]>([]);

  const [yearFilter, setYearFilter] = useState<string>("All");

  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [vehicleVersions, setVehicleVersions] = useState<IVehicleVersion[]>([]);

  const navigate = useNavigate();

  const [alertConfig, setAlertConfig] = useState<IQuickAlert>({
    visibility: false,
  });

  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const [firstAccess, setFirstAccess] = useState(true);

  useEffect(() => {
    setFirstAccess(!firstAccess);
  }, []);

  useEffect(() => {
    !firstAccess && navigate("../ownersManual");
  }, [countryCode]);

  const updateVersions = () => {
    axios
      .get<Iversions>(
        `${CONFIG.API_URL}versionContent?carId=${id}`,
        tokenHeaders,
      )
      .then(({ data: { vehicleVersions } }) => {
        const vehicleYears = vehicleVersions
          .map(({ vehicleVersionYear }) => vehicleVersionYear.toString())
          .sort((a, b) => Number(a).valueOf() - Number(b).valueOf());
        const uniqueVehicleYears = Array.from(new Set(vehicleYears));
        setAllYears(uniqueVehicleYears);
        setVehicleVersions(vehicleVersions);
      })
      .catch((error: any) => {
        setAlertConfig({
          visibility: true,
          description: t(error.response.data.message),
          severity: "error",
          title: t("ownersManual.errorTitleMessage"),
        });
        setTimeout(() => {
          setAlertConfig({
            visibility: false,
          });
        }, 3000);
      });
  };

  useLayoutEffect(() => {
    updateVersions();
  }, []);

  const closeManual = () => {
    setAddModalOpen(false);
  };

  const saveOwnersManual = (data: IOwnersManual) => {
    axios
      .post(`${CONFIG.API_URL}versionContent`, data, tokenHeaders)
      .then(() => {
        updateVersions();
        closeManual();
        setAlertConfig({
          visibility: true,
          description: t("ownersManual.createSuccessMessage"),
          severity: "success",
          title: t("ownersManual.successTitleMessage"),
        });
        setTimeout(() => {
          setAlertConfig({
            visibility: false,
          });
        }, 3000);
      })
      .catch((error: any) => {
        setAlertConfig({
          visibility: true,
          description: t(error.response.data.message),
          title: t("ownersManual.errorTitleMessage"),
          severity: "error",
        });
        setTimeout(() => {
          setAlertConfig({
            visibility: false,
          });
        }, 3000);
      });
  };

  return (
    <BoxContent title={t("menu.ownersmanual")}>
      <QuickAlert {...alertConfig} />
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <TextField
          select
          id="slcYears"
          label={t("ownersManual.yearFilterField")}
          sx={{ height: 35, width: 180, marginBottom: "1rem" }}
          onChange={($event) => {
            setYearFilter($event.target.value);
          }}
          defaultValue="All"
        >
          <MenuItem value="All">{`${t("ownersManual.allOption")}`}</MenuItem>
          {allYears.map((year: string) => {
            return (
              <MenuItem key={`${year}?id=${Math.random()}`} value={year}>
                {`${year}`}
              </MenuItem>
            );
          })}
        </TextField>
        <Button variant="contained" onClick={() => setAddModalOpen(true)}>
          {t("ownersManual.createTitleModal")}
        </Button>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <h1 style={{ fontSize: 14 }}> {name}</h1>
      </Box>

      {vehicleVersions
        .filter(
          (item) =>
            yearFilter === "All" || `${item.vehicleVersionYear}` === yearFilter,
        )
        .map((item, index) => {
          return (
            <Box
              key={item.versionId + Math.random().toString()}
              sx={{ marginBottom: 2 }}
              className={`year${item.vehicleVersionYear}`}
            >
              <Version
                updateVehicles={updateVersions}
                vehicleVersion={item}
                index={index}
                allVehicleVersions={vehicleVersions}
                setAlertConfig={setAlertConfig}
                name={name}
              />
            </Box>
          );
        })}

      {isAddModalOpen && (
        <MyModal
          title={t("ownersManual.createManual")}
          modalChange={() => closeManual()}
        >
          <AddManual
            allVehicleVersions={vehicleVersions}
            saveOwnersManual={saveOwnersManual}
            submitButtonTitle={t("ownersManual.addButton")}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
