import { yupResolver } from "@hookform/resolvers/yup";
import { AlertColor, Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import { FormInputText } from "../../components/form/FormInputText";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import ActionButtons from "../../components/MyModal/ActionButtons";
import { AuthContext } from "../../contexts/AuthContext";
import { IPath, IPathRow } from "../../interfaces/IPath";
import { CONFIG } from "../../services/config";

export default function Paths() {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [openModalAddPath, setOpenModalAddPath] = useState(false);
  const [openModalEditPath, setOpenModalEditPath] = useState(false);
  const [pathSelected, setPathSelected] = useState<IPath>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Success");
    setSeverityAlert("success");
    setOpenModalEditPath(false);
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddPath = () => {
    setOpenModalAddPath(true);
  };

  const addFormData = yup.object().shape({
    title: yup.string().required(t("paths.Required title")),
    name: yup.string().required(t("paths.Required name")),
  });

  const editFormData = yup.object().shape({
    title: yup.string().required(t("paths.Required title")),
    name: yup.string().required(t("paths.Required name")),
  });

  const { register, handleSubmit, formState, reset, clearErrors } =
    useForm<IPath>({
      resolver: yupResolver(addFormData),
    });

  const { errors } = formState;

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    formState: formStateEdit,
  } = useForm<IPath>({
    resolver: yupResolver(editFormData),
  });

  const { errors: errorsEdit } = formStateEdit;

  useEffect(() => {
    clearErrors();
    reset();
  }, [openModalAddPath]);

  const addPath: SubmitHandler<IPath> = async (values) => {
    const name = values?.name;
    const title = values?.title;

    try {
      await axios.post(
        `${CONFIG.API_URL}functionality`,
        {
          name,
          title,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setMessageQuickAlert(t("paths.Functionality added"));
    watchDataChange();
    reset();
    setOpenModalAddPath(false);
  };

  const editPath = (e: IPathRow) => {
    setOpenModalEditPath(true);
    resetEdit();
    setPathSelected(e.row);
  };

  const editPathChanges: SubmitHandler<IPath> = async (values) => {
    try {
      await axios.put(
        `${CONFIG.API_URL}functionality?id=${pathSelected?.functionalityId}`,
        {
          name: values.name,
          title: values.title,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setMessageQuickAlert(t("paths.Functionality edited"));
    watchDataChange();
  };

  const deletePath = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}functionality?id=${pathSelected?.functionalityId}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setMessageQuickAlert(t("paths.Functionality deleted"));
    watchDataChange();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("paths.Name"),
      width: 429,
      headerClassName: "header-style",
    },
    {
      field: "title",
      headerName: t("paths.Title"),
      width: 429,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.paths")}
      button
      titleButton={t("paths.Add functionality")}
      handleClick={handleClickModaAddPath}
      global
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <DataGridApi
        onRowClick={editPath}
        dataUpdated={dataUpdated}
        messageError={messageError}
        endPoint="functionality?"
        columnsData={columns}
      />

      {openModalAddPath && (
        <MyModal
          title={t("paths.Add functionality")}
          modalChange={setOpenModalAddPath}
        >
          <Box component="form" onSubmit={handleSubmit(addPath)}>
            <FormInputText
              label={t("paths.Name")}
              error={errors.name}
              {...register("name")}
            />
            <FormInputText
              label={t("paths.Title")}
              error={errors.title}
              {...register("title")}
            />

            <ActionButtons
              save={addPath}
              saveName={t("button.Add")}
              typeButton="submit"
            />
          </Box>
        </MyModal>
      )}

      {openModalEditPath && (
        <MyModal
          title={t("paths.Edit functionality")}
          modalChange={setOpenModalEditPath}
          component="form"
          onSubmit={handleSubmitEdit(editPathChanges)}
        >
          <FormInputText
            label={t("paths.Name")}
            id="editName"
            defaultValue={pathSelected?.name}
            error={errorsEdit.name}
            {...registerEdit("name")}
          />
          <FormInputText
            label={t("paths.Title")}
            id="editTitle"
            defaultValue={pathSelected?.title}
            error={errorsEdit.title}
            {...registerEdit("title")}
          />

          <ActionButtons
            typeButton="submit"
            saveName={t("button.Save")}
            confirmDelete={deletePath}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
