import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";

interface IimageSelected {
  name: string;
  extension: string;
  imageBase: string;
}

export default function AddMultipleImages({
  watchDataChange,
  messageError,
  updateData,
}: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [imageSelected, setImageSelected] = useState<any[]>([]);
  const [sameName, setSameName] = useState(0);
  const [imageNameError, setImageNameError] = useState<any[]>([]);
  const [info, setInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [updateFileInput, setUpdateFileInput] = useState(0);

  const convertImageToBase64 = (filelist: FileList | null) => {
    const files = Array.from(filelist || []);

    if (files.length > 500) {
      alert(`Selecione no máximo 500 imagens.`);
      setImageSelected([]);
      setUpdateFileInput((presentState) => presentState + 1);
      return;
    }

    const arrayImages: any = [];

    // LOOP
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      console.log("elementt", element);

      const lastIndex = files[i].name.lastIndexOf(".");

      const name = files[i].name.slice(0, lastIndex);
      const extension = files[i].name.slice(lastIndex + 1);

      let limitSize;

      if (extension === "mp4") {
        limitSize = 30000000;
      } else {
        limitSize = 2000000;
      }

      if (files[i].size > limitSize) {
        alert(`${t("alert.imageSize")}`);
        setImageSelected([]);
        const imageFile = document.querySelector(
          ".imageFile",
        ) as HTMLInputElement;
        imageFile.value = "";
        return false;
      }

      if (!files[i].name.match(/\.(jpg|jpeg|png|mp4)$/)) {
        alert(t("alert.validImage"));
        setImageSelected([]);
        const imageFile = document.querySelector(
          ".imageFile",
        ) as HTMLInputElement;
        imageFile.value = "";
        return false;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        let image: any = reader.result?.toString();
        image = image?.split(",");

        const imageBase = image[1];
        const imageInfo = {
          name,
          extension: `.${extension}`,
          imageBase,
        };

        arrayImages.push(imageInfo);
      };
      reader.readAsDataURL(files[i]);
    }
    setImageSelected(arrayImages);
  };

  const addImages = async () => {
    let numberName = 0;
    const nameError: any = [];
    setLoading(true);
    setIsButtonDisabled(true);
    if (!imageSelected.length) {
      setLoading(false);
      setIsButtonDisabled(false);
    }
    for (const element of imageSelected) {
      try {
        await axios.post(
          `${CONFIG.API_URL}manual`,
          {
            name: element.name,
            image: element.imageBase,
            ext: element.extension,
            active: true,
          },
          tokenHeaders,
        );
      } catch (error: any) {
        nameError.push(`${element.name}: ${error.response.data.message}`);
        numberName++;
      }
    }
    updateData();
    setSameName(numberName);
    setImageNameError(nameError);
    setInfo(true);
    setLoading(false);
    setIsButtonDisabled(false);
  };

  const refreshData = () => {
    setSameName(0);
    setImageSelected([]);
    setImageNameError([]);
    setInfo(false);
    updateData();
  };

  return (
    <>
      {!info && (
        <>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("manual.chooseImages")}</span>
            <input
              accept=".png, .jpg, .jpeg, .webp, .mp4, .webm"
              className="imageFile"
              type="file"
              id="fileInput"
              name="fileInput"
              key={updateFileInput}
              onChange={(event) => {
                convertImageToBase64(event.target.files);
              }}
              multiple
            />
          </Box>

          <Box
            sx={{
              marginBottom: 2,
            }}
          >
            <Button
              disabled={isButtonDisabled}
              variant="contained"
              onClick={addImages}
            >
              {t("manual.insertMedia")}
            </Button>
          </Box>
          {loading && <CircularProgress />}
        </>
      )}

      {info && (
        <Box
          sx={{
            marginBottom: 2,
            paddingY: 1,
            paddingX: 2,
            backgroundColor: "var(--gray-100)",
            borderRadius: 2,
            postion: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
              marginTop: 1,
            }}
          >
            <span>{t("manual.importReport")}</span>

            <CloseIcon
              onClick={refreshData}
              sx={{
                cursor: "pointer",
                // position: "absolute",
              }}
            />
          </Box>

          <Box sx={{ marginBottom: 1 }}>
            <span>
              {`${imageSelected!.length - sameName} ${t("manual.feedback1")}`}
            </span>
            <br />
            <span>{`${sameName} ${t("manual.feedback2")}`}</span>
          </Box>

          {imageNameError.length > 0 && (
            <Box sx={{ marginTop: 2 }}>
              <strong>{t("manual.sameFile")}:</strong>
              <br />
              <span style={{ fontSize: "12px" }}>
                Verifique se há imagens repetidas ou com mais de 200 caracteres
              </span>
              {imageNameError.map((item) => {
                return (
                  <li
                    key={item}
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
