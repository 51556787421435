const blobToBase64 = (blob: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export async function convertBlobToBase64(blob: any) {
  // blob data
  const result = await blobToBase64(blob);
  const [mime, base64] = String(result).split(",");
  return {
    mime,
    base64,
  };
}
