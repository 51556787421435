import TranslateIcon from "@mui/icons-material/Translate";
import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import useCookies from "../../services/CookieService";

export default function ButtonLanguage() {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:899px)");

  const { setCookie, findCookie, removeAll } = useCookies();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    language: { language, languageName },
    setLanguage,
  } = useContext(AuthContext);

  const { i18n } = useTranslation();

  useEffect(() => {
    const nameLang = findCookie("languageName")?.value;
    const lang = findCookie("language")?.value;

    setLanguage({ language: lang!, languageName: nameLang! });
  }, []);

  const changeLanguage = (e: any) => {
    const { myValue, value } = e.target.dataset;

    i18n.changeLanguage(myValue);
    setLanguage({ language: myValue, languageName: value });
    setAnchorEl(null);
  };

  const changeLanguageButton = (e: any) => {
    const { myValue } = e.target.dataset;

    i18n.changeLanguage(myValue);
    setLanguage({ language: myValue, languageName: e.target.textContent });
    setLanguage(e.target.textContent);
    setAnchorEl(null);
  };

  const {
    country: { countryCode },
  } = useContext(AuthContext);

  return (
    <>
      {matches && (
        <>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <TranslateIcon sx={{ color: "var(--gray-900)" }} />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            defaultValue="en"
          >
            <MenuItem
              key="en"
              onClick={changeLanguageButton}
              data-my-value="en"
              value="English"
            >
              English
            </MenuItem>
            <MenuItem
              key="ptbr"
              onClick={changeLanguageButton}
              data-my-value="ptbr"
              value="Portuguese"
            >
              Português
            </MenuItem>
            <MenuItem
              key="es"
              onClick={changeLanguageButton}
              data-my-value="es"
              value="Spanish"
            >
              Español
            </MenuItem>
          </Menu>
        </>
      )}

      {!matches && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="languageInput">{t("header.Language")}</InputLabel>
          <Select
            labelId="languageInput"
            id="lanaguageSelect"
            value={languageName ?? "English"}
            label="Language"
          >
            <MenuItem
              onClick={changeLanguage}
              data-my-value="en"
              value="English"
            >
              English
            </MenuItem>
            <MenuItem
              onClick={changeLanguage}
              data-my-value="ptbr"
              value="Portuguese"
            >
              Português
            </MenuItem>
            <MenuItem
              onClick={changeLanguage}
              data-my-value="es"
              value="Spanish"
            >
              Español
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </>
  );
}
