import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import lineBreak from "../../../utils/lineBreak";

export default function ImageResponse() {
  const { t } = useTranslation();
  const [jsonFinal, setJsonFinal] = useState<string>("");
  const [value, setValue] = useState<any>({
    id: "",
    title: "",
    description: "",
  });

  const generate = () => {
    const concat = lineBreak(value.title);
    const jsonGerado = {
      generic: [
        {
          response_type: "text",
          values: [
            {
              text_expression: {
                concat,
              },
            },
          ],
          selection_policy: "sequential",
        },
        {
          user_defined: {
            type: "image",
            value: {
              image: {
                id: value.id,
                description: value.description.replaceAll("\\n", "\n"),
              },
            },
          },
          response_type: "user_defined",
        },
      ],
    };
    setJsonFinal(JSON.stringify(jsonGerado, null, 1));
  };

  const clear = () => {
    setJsonFinal("");
    setValue({
      id: "",
      title: "",
      description: "",
    });
  };

  return (
    <Grid container>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <TextField
            multiline
            rows="4"
            fullWidth
            id="outlined-basic"
            label={t("transformJson.title")}
            variant="outlined"
            value={value.title}
            onChange={(e) => setValue({ ...value, title: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.id")}
            variant="outlined"
            value={value.id}
            onChange={(e) => setValue({ ...value, id: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.description")}
            variant="outlined"
            value={value.description}
            onChange={(e) =>
              setValue({ ...value, description: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item>
          <Button
            variant="contained"
            sx={{ marginTop: "8px" }}
            disabled={
              value.id === "" || value.title === "" || value.description === ""
                ? true
                : false
            }
            onClick={generate}
          >
            {t("button.Generate")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ marginTop: "8px" }} onClick={clear}>
            {t("button.Clean")}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <pre
            style={{
              wordWrap: "break-word",
              maxWidth: "37rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {jsonFinal}
          </pre>
        </Grid>
        <Grid item>
          {jsonFinal !== "" ? (
            <Button
              disabled={false}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(jsonFinal);
              }}
              startIcon={<ContentCopyIcon />}
            >
              {t("button.Copy")}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
