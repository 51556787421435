import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Drawer } from "@mui/material";
import * as React from "react";

import Menu from "./index";

export default function HamburguerMenu() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <MenuIcon
          fontSize="large"
          sx={{
            color: "var(--gray-900)",
          }}
        />
      </Button>
      <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={{ width: "17rem" }}>
          <Menu />
        </Box>
      </Drawer>
    </div>
  );
}
