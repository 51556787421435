// import { Button } from "@mui/material";
import { AlertColor } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import BoxContent from "../../../components/boxContent";
import DataGridApi from "../../../components/dataGridApi";
import QuickAlert from "../../../components/form/quickAlert";
import MyModal from "../../../components/MyModal";

import EditSystem from "../../../components/faq/channel/editSystem";

import AddNewSystem from "../../../components/faq/channel/addNewSystem";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Channel() {
  const { t } = useTranslation();
  const {
    country: { countryCode },
  } = useContext(AuthContext);
  const [openModalAddSystem, setOpenModalAddSystem] = useState(false);
  const [openModalEditContact, setOpenModalEditContact] = useState(false);

  const [contact, setContactSelected] = useState<any>();

  const editSystem = (e: any) => {
    setOpenModalEditContact(true);
    setContactSelected(e.row);
  };

  const handleClickModaAddContact = () => {
    setOpenModalAddSystem(true);
  };

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddSystem(false);
    setOpenModalEditContact(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("faq.system.Name"),
      width: 400,
      headerClassName: "header-style",
    },
    {
      field: "systemCode",
      headerName: t("faq.system.SystemCode"),
      width: 250,
      headerClassName: "header-style",
    },
    {
      field: "active",
      headerName: t("faq.system.Status"),
      width: 250,
      headerClassName: "header-style",
      renderCell: (params) => <>{t(`faq.status.${params.value}`)}</>,
    },
  ];

  return (
    <BoxContent
      title={t("menu.faqsistem")}
      button
      titleButton={t("faq.system.Add system button")}
      handleClick={handleClickModaAddContact}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      <p style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
        {t("faq.system.Descripition")}
      </p>
      <DataGridApi
        onRowClick={editSystem}
        dataUpdated={dataUpdated}
        messageError={messageError}
        endPoint={`faq/systems?countrycode=${countryCode}&`}
        columnsData={columns}
      />

      {openModalAddSystem && (
        <MyModal
          title={t("faq.system.Add system")}
          modalChange={setOpenModalAddSystem}
          width="900px"
        >
          <AddNewSystem
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditContact && (
        <MyModal
          title={t("faq.system.Edit system")}
          modalChange={setOpenModalEditContact}
          width="900px"
        >
          <EditSystem
            data={contact}
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
