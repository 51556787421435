import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Alert, Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DataGridWrapper from "../dataGridWrapper";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import { IRegisteredUsers } from "../../interfaces/IRegisteredUsers";
import { IUser } from "../../interfaces/IUser";

export default function RegisteredUsers({
  onRowClick,
  dataUpdated = 0,
}: IRegisteredUsers) {
  const { t } = useTranslation();
  const { tokenHeaders, isAuthenticated } = useContext(AuthContext);

  const [usersApi, setUsersApi] = useState();

  const [errorFetching, setErrorFetching] = useState(false);

  useEffect(() => {
    axios
      .get(`${CONFIG.API_URL}user/cmsUser/all`, tokenHeaders)
      .then((response) => {
        setUsersApi(response.data);
      })
      .catch(() => {
        setErrorFetching(true);
      });
  }, [dataUpdated]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("users.Name"),
      width: 286,
      headerClassName: "header-style",
    },
    {
      field: "profile",
      headerName: t("users.Profile"),
      width: 286,
      headerClassName: "header-style",
      renderCell: (params) =>
        params.value ? (
          <p title={params.value.profileName}>{params.value.profileName}</p>
        ) : (
          ""
        ),
    },
    {
      field: "active",
      headerName: t("users.Status"),
      width: 286,
      headerClassName: "header-style",
      renderCell: (params) => {
        return params.row.active ? t("users.Active") : t("users.Inactive");
      },
    },
  ];

  const rows: any = usersApi;

  if (!isAuthenticated) {
    return (
      <Box>
        <Alert severity="error">{t("userNotSigned.title")}</Alert>
        <Alert sx={{ marginTop: 2 }} severity="warning">
          {t("userNotSigned.warning")}
        </Alert>
      </Box>
    );
  }

  if (errorFetching) {
    return (
      <Alert severity="error">
        There was an error when fetching your request.
      </Alert>
    );
  }

  return (
    <Box>
      {usersApi ? (
        <Box
          sx={{
            width: "100%",
            "& .header-style": {
              backgroundColor: "var(--gray-100)",
            },
          }}
        >
          <DataGridWrapper
            getRowId={(row: IUser) => row.userId}
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onRowClick={onRowClick}
          />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
