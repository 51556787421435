import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IVehicle } from "../../interfaces/IVehicle";
import { CONFIG } from "../../services/config";
import QuickAlert from "../form/quickAlert";
import VehiclePills from "./vehiclePills";

export default function SearchVehiclePills() {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [vehicleSelected, setVehicleSelected] = useState<IVehicle[]>([]);

  const [vehicleId, setVehicleId] = useState("");
  const [vehicleVersionId, setVehicleVersionId] = useState("");
  const [vehicleVersionYear, setVehicleVersionYear] = useState<number>();

  const [dataPill, setDataPill] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const getVehicles = async () => {
    await axios
      .get(
        `${CONFIG.API_URL}vehicles/pageable?countryCode=${countryCode}&`,
        tokenHeaders,
      )
      .then((response) => {
        setVehicles(response.data.content);
      });
  };

  const getVehicleVersion = async (id: number) => {
    try {
      await axios
        .get(`${CONFIG.API_URL}vehicles/${id}`, tokenHeaders)
        .then((response) => {
          console.log(`response`, response.data.versions);

          setVehicleSelected(response.data.versions);
        });
    } catch (error) {
      setVehicleSelected([]);
      // htmlVehicleVersion.innerHTML = "All";
      // setVehicleVersionId("All");
    }
  };

  const handleVehicleSelected = (e: any) => {
    getVehicleVersion(e.target.value.id);
    setVehicleId(e.target.value.id);
    setDataPill([]);
  };

  const handleVehicleVersion = (e: any) => {
    // console.log("e", e.target);
    setVehicleVersionId(e.target.value.id);
    setVehicleVersionYear(e.target.value.year);
    setDataPill([]);
  };

  useEffect(() => {
    getVehicles();
    setVehicleSelected([]);
    setDataPill([]);
  }, [countryCode]);

  const handleSearch = async () => {
    console.log("vehicleId", vehicleId);
    console.log("vehicleVersionId", vehicleVersionId);
    console.log("vehicleVersionYear", vehicleVersionYear);
    setDataPill([]);
    if (!vehicleVersionYear) {
      return alert(t("pills.selectAVehicle"));
    }

    try {
      setLoading(true);

      await axios
        .get(
          `${CONFIG.API_URL}pills/ordering?id=${vehicleVersionId}`,
          tokenHeaders,
        )
        .then((response) => {
          console.log(`response`, response.data);
          setLoading(false);
          setDataPill(response.data);
        });
    } catch (error) {
      setVehicleSelected([]);
    }

    // setTimeout(() => {
    //   setDataPill(fakeData);
    //   setLoading(false);
    // }, 500);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  return (
    <Box>
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ marginBottom: 2 }}
      >
        <Grid item xs={4}>
          <Select
            displayEmpty
            labelId="colorSelect"
            id="handleVehicleSelected"
            placeholder="Select"
            key={countryCode}
            onChange={(e) => handleVehicleSelected(e)}
            defaultValue=""
            sx={{ height: 40, width: "100%" }}
          >
            <MenuItem disabled value="">
              <em>{t("pills.selectModel")}</em>
            </MenuItem>

            {vehicles.map((vehicle: any) => {
              return (
                <MenuItem key={vehicle.id} value={vehicle} id={vehicle.name}>
                  {vehicle.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            displayEmpty
            labelId="colorSelect"
            id="handleVehicleVersion"
            placeholder="Select"
            key={countryCode + vehicleId}
            // onChange={handleChange}
            onChange={(e) => handleVehicleVersion(e)}
            defaultValue=""
            sx={{ height: 40, width: "100%" }}
            disabled={vehicleSelected.length === 0 ? true : false}
          >
            <MenuItem disabled value="">
              <em>{t("pills.selectVersion")}</em>
            </MenuItem>
            {vehicleSelected.map((model: any) => {
              return (
                <MenuItem key={model.id} value={model} id={model.id}>
                  {model.name}/{model.year}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Button
            sx={{ marginBottom: 2 }}
            variant="contained"
            onClick={handleSearch}
          >
            {t("button.Search")}
          </Button>
        </Grid>
      </Grid>

      {/* <VehiclePills data={fakeData} /> */}
      {/* <FinalSpace /> */}
      {/* {dataPill.length !== 0 && <DragPill data={dataPill} />} */}
      {/* <DragGrid /> */}
      {dataPill.length !== 0 && (
        <VehiclePills
          data={dataPill}
          vehicleId={vehicleId}
          vehicleVersionId={vehicleVersionId}
          vehicleVersionYear={vehicleVersionYear}
          messageError={messageError}
          watchDataChange={watchDataChange}
        />
      )}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {/* <GridDrop data={fakeData} /> */}
      {/* <PillOrdering data={fakeData} /> */}
    </Box>
  );
}
