import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputText } from "../form/FormInputText";

export default function Module({ errors, data }: any) {
  const { t } = useTranslation();

  const { register } = useFormContext();

  return (
    <Box>
      <FormInputText
        label={t("modules.moduleName")}
        error={errors.moduleName}
        // defaultValue={data.}
        {...register("moduleName")}
        defaultValue={data ? data.modulesName : ""}
      />
      <FormInputText
        label={t("modules.moduleTitle")}
        error={errors.moduleTitle}
        {...register("moduleTitle")}
        defaultValue={data ? data.modulesTitle : ""}
      />
    </Box>
  );
}
