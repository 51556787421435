import { yupResolver } from "@hookform/resolvers/yup";
import {
  AlertColor,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import BoxContent from "../../components/boxContent";
import { FormInputText } from "../../components/form/FormInputText";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import ActionButtons from "../../components/MyModal/ActionButtons";
import RegisteredUsers from "../../components/users/RegisteredUsers";
import { AuthContext } from "../../contexts/AuthContext";
import { ICountry } from "../../interfaces/ICountry";
import { IRole } from "../../interfaces/IRole";
import { IUser } from "../../interfaces/IUser";
import { CONFIG } from "../../services/config";

export default function Users() {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const ALPHA_REGEX = /^[a-zA-Z-\s]+$/;

  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [openModalEditUser, setOpenModalEditUser] = useState(false);

  const [profile, setProfile] = useState<IRole[]>([]);

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [editCheckBox, setEditCheckBox] = useState<any[]>([]);
  const [addCheckBox, setAddCheckBox] = useState<string[]>([]);

  const [userSelected, setUserSelected] = useState<IUser>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setEditCheckBox([]);
    setTitleMessage("Success");
    setSeverityAlert("success");
    setOpenModalEditUser(false);
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setEditCheckBox([]);

    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const addFormData = yup.object().shape({
    name: yup.string().required(t("users.Required name")),
    email: yup
      .string()
      .required(t("users.Required email"))
      .email(t("users.This field has to be a valid email address")),
    profileId: yup.string().required(t("users.Required profile")),
    active: yup.boolean(),
  });

  const editFormData = yup.object().shape({
    name: yup.string().required(t("users.Required name")),
    email: yup
      .string()
      .required(t("users.Required email"))
      .email(t("users.This field has to be a valid email address")),
    profileId: yup.string().required(t("users.Required profile")),
    active: yup.boolean(),
  });

  const { register, handleSubmit, formState, reset, clearErrors } =
    useForm<IUser>({
      resolver: yupResolver(addFormData),
    });

  const { errors } = formState;

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    formState: formStateEdit,
  } = useForm<IUser>({
    resolver: yupResolver(editFormData),
  });

  const { errors: errorsEdit } = formStateEdit;

  useEffect(() => {
    clearErrors();
    reset();
  }, [openModalAddUser]);

  useEffect(() => {
    axios
      .get(`${CONFIG.API_URL}profile`, tokenHeaders)
      .then((response) => {
        setProfile(response.data);
      })
      .catch(() => {
        console.log("erro");
      });

    axios
      .get(`${CONFIG.API_URL}country/all`, tokenHeaders)
      .then((response) => {
        setCountries(response.data);
      })
      .catch(() => {
        console.log("erro");
      });
  }, []);

  const handleClickModaAddUser = () => {
    setOpenModalAddUser(true);
  };

  const handleAddCheckBox = (event: any) => {
    if (event.target.checked) {
      const country = event.target.value;
      setAddCheckBox((current) => [...current, ...[country]]);
    } else {
      const country = event.target.value;
      addCheckBox.map((name) => {
        if (name === country) {
          const array = addCheckBox;
          const index = array.indexOf(country);
          if (index !== -1) {
            array.splice(index, 1);
            setAddCheckBox(array);
          }
        }
        return addCheckBox;
      });
    }
  };

  const handleEditCheckBox = (event: any) => {
    if (event.target.checked) {
      const country = event.target.value;
      setEditCheckBox((current) => [...current, ...[country]]);
    } else {
      const country = event.target.value;
      editCheckBox.map((name) => {
        if (name === country) {
          const array = editCheckBox;
          const index = array.indexOf(country);
          if (index !== -1) {
            array.splice(index, 1);
            setEditCheckBox(array);
          }
        }
        return editCheckBox;
      });
    }
  };

  const editUser = (e: { row: IUser }) => {
    const arrayCountries: IUser[] = [];

    e.row.countries.map((item: any) => {
      return arrayCountries.push(item.countryCode);
    });

    setEditCheckBox(arrayCountries);

    setOpenModalEditUser(true);
    resetEdit();
    setUserSelected(e.row);
  };

  const addUser: SubmitHandler<IUser> = async (values) => {
    let updatedActive = 0;
    if (values.active) {
      updatedActive = 1;
    }
    const arrayCountries = addCheckBox;

    try {
      await axios.post(
        `${CONFIG.API_URL}user/cmsUser`,
        {
          name: values.name,
          email: values.email,
          preferedCountry: countryCode,
          roles: values.profileId,
          countries: arrayCountries,
          active: updatedActive,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setAddCheckBox([]);
    setMessageQuickAlert(t("users.User added"));
    watchDataChange();
    reset();
    setOpenModalAddUser(false);
  };

  const editUserChanges: SubmitHandler<IUser> = async (values) => {
    console.log("editar clicado");
    let updatedActive = 0;
    if (values.active) {
      updatedActive = 1;
    }
    const arrayCountries = editCheckBox;

    try {
      await axios.put(
        `${CONFIG.API_URL}user?id=${userSelected?.userId}`,
        {
          name: values.name,
          email: values.email,
          preferedCountry: countryCode,
          roles: values.profileId,
          countries: arrayCountries,
          active: updatedActive,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setEditCheckBox([]);
    setMessageQuickAlert(t("users.User edited"));
    watchDataChange();
  };

  const deleteUser = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}user?id=${userSelected?.userId}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setMessageQuickAlert(t("users.User deleted"));
    watchDataChange();
  };

  return (
    <BoxContent
      title={t("menu.users")}
      button
      titleButton={t("users.Add user")}
      handleClick={handleClickModaAddUser}
      global
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <RegisteredUsers onRowClick={editUser} dataUpdated={dataUpdated} />

      {openModalAddUser && (
        <MyModal title={t("users.Add user")} modalChange={setOpenModalAddUser}>
          <Box component="form" onSubmit={handleSubmit(addUser)}>
            <FormInputText
              onKeyDown={(event) => {
                if (!ALPHA_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
              label={t("users.Name")}
              error={errors.name}
              {...register("name")}
            />

            <FormInputText
              label={t("users.E-mail")}
              type="email"
              error={errors.email}
              {...register("email")}
            />

            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  overflow: "visible",
                  lineHeight: "0.4375rem",
                }}
              >
                {t("users.Profile")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t("users.Profile")}
                size="small"
                {...register("profileId")}
              >
                {profile.map((item) => {
                  return (
                    <MenuItem key={item.profileId} value={item.profileId}>
                      {item.profileName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box
              sx={{
                marginBottom: "1rem",
                fontSize: "14px",
                color: "var(--red-100)",
              }}
            >
              {errors.profileId?.message}
            </Box>

            <h3>{t("users.Country")}</h3>

            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ paddingLeft: "1.5rem" }}
              >
                {countries.map((country) => {
                  return (
                    <Grid item key={country.countryCode} xs={6} sm={4} md={3}>
                      <FormControlLabel
                        key={country.countryCode}
                        control={
                          <Checkbox
                          // defaultChecked={
                          //   localStorage.getItem("countryCode") ===
                          //   country.countryCode
                          // }
                          />
                        }
                        id={country.country}
                        value={country.countryCode}
                        onChange={(e) => handleAddCheckBox(e)}
                        label={country.country}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <h3>{t("users.Published")}</h3>
            <Switch {...label} defaultChecked {...register("active")} />

            <ActionButtons typeButton="submit" saveName={t("button.Add")} />
          </Box>
        </MyModal>
      )}

      {openModalEditUser && (
        <MyModal
          title={t("users.Edit user")}
          modalChange={setOpenModalEditUser}
          component="form"
          onSubmit={handleSubmitEdit(editUserChanges)}
        >
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            label={t("users.Name")}
            id="editName"
            defaultValue={userSelected?.name}
            error={errorsEdit.name}
            {...registerEdit("name")}
          />

          <FormInputText
            label={t("users.E-mail")}
            id="editEmail"
            type="email"
            defaultValue={userSelected?.email}
            error={errorsEdit.email}
            {...registerEdit("email")}
          />

          <FormControl fullWidth>
            <InputLabel
              id="editRoles"
              sx={{
                overflow: "visible",
                lineHeight: "0.4375rem",
              }}
            >
              {t("users.Profile")}
            </InputLabel>
            <Select
              labelId="editRoles-label"
              id="editRoles"
              label={t("users.Profile")}
              size="small"
              {...registerEdit("profileId")}
              defaultValue={userSelected?.profile.profileId}
            >
              {profile.map((item) => {
                return (
                  <MenuItem key={item.profileId} value={item.profileId}>
                    {item.profileName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box
            sx={{
              marginBottom: "1rem",
              fontSize: "14px",
              color: "var(--red-100)",
            }}
          >
            {errorsEdit.profileId?.message}
          </Box>

          <h3>{t("countries.Country")}</h3>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ paddingLeft: "1.5rem" }}
            >
              {countries.map((country) => {
                return (
                  <Grid item key={country.countryCode} xs={6} sm={4} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={editCheckBox.includes(
                            country.countryCode,
                          )}
                        />
                      }
                      key={country.countryCode}
                      id={country.country}
                      value={country.countryCode}
                      onChange={(e) => handleEditCheckBox(e)}
                      label={country.country}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <h3>{t("users.Published")}</h3>
          {userSelected?.active ? (
            <Switch
              {...label}
              id="editActive"
              {...registerEdit("active")}
              defaultChecked
            />
          ) : (
            <Switch {...label} id="editActive" {...registerEdit("active")} />
          )}
          <ActionButtons
            typeButton="submit"
            saveName={t("button.Save")}
            confirmDelete={deleteUser}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
