import { SubmitHandler, useForm } from "react-hook-form";

import { Box, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import * as yup from "yup";
import { setLocale } from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";
import { FormInputText } from "../../form/FormInputText";
import ActionButtons from "../../MyModal/ActionButtons";
import { LabelContainer, LabelForm } from "../Form/label";

type FormValues = {
  id?: number;
  name: string;
  systemCode: string;
  active: boolean;
};

export default function AddNewSystem({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  setLocale({
    mixed: {
      required: t(`yup.mixed.required`),
      notType: t(`yup.mixed.notType`),
    },
    string: {
      min: t(`yup.string.min`),
      max: t(`yup.string.max`),
      email: t(`yup.string.email`),
    },
    number: {
      min: t(`yup.number.min`),
      max: t(`yup.number.max`),
      positive: t(`yup.number.positive`),
      integer: t(`yup.number.integer`),
    },
    date: {
      min: t(`yup.date.min`),
      max: t(`yup.date.max`),
    },
    array: {
      min: t(`yup.array.min`),
      max: t(`yup.array.max`),
    },
  });

  const { tokenHeaders } = useContext(AuthContext);

  const addFormData = yup.object().shape({
    name: yup.string().max(20).required(t("faq.system.Required name")),
    systemCode: yup
      .string()
      .max(20)
      .required(t("faq.system.Required systemCode"))
      .label(t("faq.system.SystemCode")),
    active: yup.boolean().default(false),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(addFormData) });

  const addSystem: SubmitHandler<FormValues> = async (values) => {
    try {
      await axios.post(
        `${CONFIG.API_URL}faq/system`,
        {
          name: values.name,
          systemCode: values.systemCode,
          active: values.active,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return messageError(t("faq.system.channel_exists"));
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
    watchDataChange(t("faq.system.System added"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(addSystem)}>
      <LabelContainer>
        <LabelForm
          required
          label={t("faq.system.Name")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "20",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.system.Name")}
            error={errors.name}
            {...register("name")}
          />
        </LabelForm>
        <LabelForm
          required
          label={t("faq.system.SystemCode")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "20",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.system.SystemCode")}
            error={errors.systemCode}
            {...register("systemCode")}
          />
        </LabelForm>

        <LabelForm
          label={t("faq.system.Status")}
          requires={[
            {
              msg: t("faq.requires.active_inactive"),
            },
          ]}
        >
          <Grid item xs={6}>
            <FormGroup sx={{ marginBottom: "1rem" }}>
              <FormControlLabel
                control={
                  <Switch
                    id="addActive"
                    {...register("active")}
                    defaultChecked
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </LabelForm>
      </LabelContainer>

      <ActionButtons
        // save={addSystem}
        saveName={t("button.Add")}
        typeButton="submit"
      />
    </Box>
  );
}
