import { yupResolver } from "@hookform/resolvers/yup";
import {
  AlertColor,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import BoxContent from "../../components/boxContent";
import MyModal from "../../components/MyModal";

import DataGridApi from "../../components/dataGridApi";
import { FormInputText } from "../../components/form/FormInputText";
import QuickAlert from "../../components/form/quickAlert";
import ActionButtons from "../../components/MyModal/ActionButtons";
import { AuthContext } from "../../contexts/AuthContext";
import { IPath } from "../../interfaces/IPath";
import { IRole, IRoleRow } from "../../interfaces/IRole";
import { CONFIG } from "../../services/config";

export default function Profiles() {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const ALPHA_REGEX = /^[a-zA-Z-\s]+$/;

  const [openModalAddRoles, setOpenModalAddRoles] = useState(false);
  const [openModalEditRole, setOpenModalEditRole] = useState(false);
  const [roleSelected, setRoleSelected] = useState<IRole>();

  const [path, setPath] = useState<IPath[]>([]);
  const [addCheckBox, setAddCheckBox] = useState<string[]>([]);
  const [editCheckBox, setEditCheckBox] = useState<any[]>([]);
  const [checkedFunctionalities, setCheckedFunctionalities] = useState<any[]>(
    [],
  );

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setEditCheckBox([]);
    setTitleMessage("Success");
    setSeverityAlert("success");
    setOpenModalEditRole(false);
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddRole = () => {
    setOpenModalAddRoles(true);
  };

  const addFormData = yup.object().shape({
    profileName: yup.string().required(t("profiles.Required title")),
  });

  const editFormData = yup.object().shape({
    profileName: yup.string(),
  });

  const { register, handleSubmit, formState, reset, clearErrors } =
    useForm<IRole>({
      resolver: yupResolver(addFormData),
    });

  const { errors } = formState;

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    formState: formStateEdit,
  } = useForm<IRole>({
    resolver: yupResolver(editFormData),
  });

  const { errors: errorsEdit } = formStateEdit;

  useEffect(() => {
    clearErrors();
    reset();
  }, [openModalAddRoles]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${CONFIG.API_URL}functionality`, tokenHeaders)
        .then((response) => {
          setPath(response.data.content);
        })
        .catch(() => {
          console.log("erro");
        });
    }, 500);
  }, []);

  const handleAddCheckBox = (event: any) => {
    if (event.target.checked) {
      const functionality = event.target.value;
      setAddCheckBox((current) => [...current, ...[functionality]]);
    } else {
      const functionality = event.target.value;
      addCheckBox.map((name) => {
        if (name === functionality) {
          const array = addCheckBox;
          const index = array.indexOf(functionality);
          if (index !== -1) {
            array.splice(index, 1);
            setAddCheckBox(array);
          }
        }
        return addCheckBox;
      });
    }
  };

  const handleEditCheckBox = (event: any) => {
    if (event.target.checked) {
      const functionality = event.target.value;
      setEditCheckBox((current) => [...current, ...[functionality]]);
    } else {
      const functionality = event.target.value;
      editCheckBox.map((name) => {
        if (name === functionality) {
          const array = editCheckBox;
          const index = array.indexOf(functionality);
          if (index !== -1) {
            array.splice(index, 1);
            setEditCheckBox(array);
          }
        }
        return editCheckBox;
      });
    }
  };

  const addRole: SubmitHandler<IRole> = async (values) => {
    console.log("values add", values);
    const profileName = values?.profileName.toUpperCase();
    const arrayFunctionality = addCheckBox;

    try {
      await axios.post(
        `${CONFIG.API_URL}profile`,
        {
          profileName,
          functionalities: arrayFunctionality,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setAddCheckBox([]);
    setMessageQuickAlert(t("profiles.Profile added"));
    watchDataChange();
    reset();
    setOpenModalAddRoles(false);
  };

  const editRole = (e: IRoleRow) => {
    console.log("e.row", e.row);
    setOpenModalEditRole(true);
    const arrayFunctionality: IPath[] = [];
    const arrayTitles: IPath[] = [];

    e.row.functionalities.map((item: any) => {
      arrayFunctionality.push(item.functionalityId.toString());
      return arrayTitles.push(item.title);
    });

    setCheckedFunctionalities(arrayTitles);
    setEditCheckBox(arrayFunctionality);

    resetEdit();
    setRoleSelected(e.row);
  };

  const editRoleChanges: SubmitHandler<IRole> = async (values) => {
    console.log("values edit", values);

    const arrayFunctionality = editCheckBox;

    try {
      await axios.put(
        `${CONFIG.API_URL}profile?id=${roleSelected?.profileId}`,
        {
          profileName: roleSelected?.profileName,
          functionalities: arrayFunctionality,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setEditCheckBox([]);
    setCheckedFunctionalities([]);
    setMessageQuickAlert(t("profiles.Profile edited"));
    watchDataChange();
  };

  const deleteRole = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}profile?id=${roleSelected?.profileId}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setMessageQuickAlert(t("profiles.Profile deleted"));
    watchDataChange();
  };

  const columns: GridColDef[] = [
    {
      field: "profileName",
      headerName: t("profiles.Profile"),
      flex: 1,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.profiles")}
      button
      titleButton={t("profiles.Add profile")}
      handleClick={handleClickModaAddRole}
      global
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <DataGridApi
        onRowClick={editRole}
        dataUpdated={dataUpdated}
        messageError={messageError}
        endPoint="profile?"
        columnsData={columns}
      />

      {openModalAddRoles && (
        <MyModal
          title={t("profiles.Add profile")}
          modalChange={setOpenModalAddRoles}
        >
          <Box component="form" onSubmit={handleSubmit(addRole)}>
            <FormInputText
              onKeyDown={(event) => {
                if (!ALPHA_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
              label={t("profiles.Title")}
              error={errors.profileName}
              {...register("profileName")}
            />

            <Box sx={{ width: "100%" }}>
              <h3>{t("menu.paths")}</h3>
              <Grid
                container
                rowSpacing={1}
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ paddingLeft: "1.5rem" }}
              >
                {path.map((item) => {
                  return (
                    <Grid item key={item.functionalityId} xs={6} sm={4} md={3}>
                      <FormControlLabel
                        key={item.functionalityId}
                        control={<Checkbox />}
                        id={item.title}
                        name={item.title}
                        value={item.functionalityId}
                        onChange={(e) => handleAddCheckBox(e)}
                        label={item.title}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <ActionButtons
              save={addRole}
              saveName={t("button.Add")}
              typeButton="submit"
            />
          </Box>
        </MyModal>
      )}

      {openModalEditRole && (
        <MyModal
          title={t("profiles.Edit profile")}
          modalChange={setOpenModalEditRole}
          component="form"
          onSubmit={handleSubmitEdit(editRoleChanges)}
        >
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            label={t("profiles.Title")}
            id="editprofile"
            defaultValue={roleSelected?.profileName}
            error={errorsEdit.profileName}
            {...registerEdit("profileName")}
            disabled
          />

          <Box sx={{ width: "100%" }}>
            <h3>{t("menu.paths")}</h3>
            <Grid
              container
              rowSpacing={1}
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ paddingLeft: "1.5rem" }}
            >
              {path.map((item) => {
                return (
                  <Grid item key={item.functionalityId} xs={6} sm={4} md={3}>
                    <FormControlLabel
                      key={item.functionalityId}
                      control={
                        <Checkbox
                          defaultChecked={checkedFunctionalities.includes(
                            item.title,
                          )}
                        />
                      }
                      id={item.title}
                      name={item.title}
                      value={item.functionalityId}
                      onChange={(e) => handleEditCheckBox(e)}
                      label={item.title}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <ActionButtons
            typeButton="submit"
            saveName={t("button.Save")}
            confirmDelete={deleteRole}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
