import { Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "./modalConfirmation";

interface IActionButtons {
  save?: any;
  saveName: string;
  confirmDelete?: any | null;
  typeButton?: string | any;
  disableSave?: boolean;
  enableLine?: boolean;
  titleConfirm?: string;
}

export default function ActionButtons({
  save,
  saveName,
  confirmDelete,
  typeButton,
  disableSave = false,
  enableLine = true,
  titleConfirm,
  ...rest
}: IActionButtons) {
  const { t } = useTranslation();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <Box
      sx={[
        {
          display: "flex",
          placeItems: "center",
          gap: "1rem",
          marginY: "1rem",
        },
        enableLine && {
          borderTop: "1px solid var(--gray-100)",
        },
      ]}
    >
      <Box
        sx={[
          enableLine && {
            marginTop: "1rem",
          },
          {
            display: "flex",
            placeItems: "center",
            gap: "1rem",
          },
        ]}
      >
        <Button
          variant="contained"
          onClick={async () => {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
            }, 8000);
            if (save) {
              await save();
            }
            setLoading(false);
          }}
          type={typeButton}
          disabled={disableSave ? true : false}
          {...rest}
        >
          {saveName}
        </Button>
        {confirmDelete && (
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowConfirmDelete(!showConfirmDelete)}
          >
            {t("button.Delete")}
          </Button>
        )}

        {showConfirmDelete && (
          <ModalConfirmation
            openDelete={setShowConfirmDelete}
            confirmDelete={confirmDelete}
            title={titleConfirm}
          />
        )}
      </Box>
      {loading && <CircularProgress />}
    </Box>
  );
}
