import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, IconButton, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IPills } from "../../interfaces/IPills";
import { IVehicle } from "../../interfaces/IVehicle";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";

interface IVehiclePill {
  errors: any;
  editPill?: boolean;
  data?: IPills;
}

export default function VehiclePill({ errors, editPill, data }: IVehiclePill) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const htmlVehicleSelected = document.getElementById(
    "handleVehicleSelected",
  ) as HTMLSelectElement;
  const htmlVehicleVersion = document.getElementById(
    "handleVehicleVersion",
  ) as HTMLSelectElement;
  const htmlVehicleVersionEdition = document.getElementById(
    "vehicleVersionEdition",
  ) as HTMLInputElement;
  const htmlPackageVehicle = document.getElementById(
    "packageVehicle",
  ) as HTMLInputElement;

  const [vehiclePills, setVehiclePills] = useState<any[]>([]);
  const [newData, setNewData] = useState<IPills>();

  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [vehicleSelected, setVehicleSelected] = useState<IVehicle[]>([]);

  const [vehicleId, setVehicleId] = useState("");
  const [vehicleVersionId, setVehicleVersionId] = useState("none");
  const [vehicleVersionYear, setVehicleVersionYear] = useState("none");
  const [vehicleVersionEdition, setVehicleVersionEdition] = useState("");
  const [packageVehicle, setPackageVehicle] = useState("");
  const [disableVehicleOptions, setDisableVehicleOptions] = useState(false);
  const [disableVersionsOptions, setDisableVersionsOptions] = useState(false);

  const { register, control, reset } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pillsVehicles`,
  });

  const getVehicleVersion = async (id: number) => {
    try {
      await axios
        .get(`${CONFIG.API_URL}vehicles/${id}`, tokenHeaders)
        .then((response) => {
          setVehicleSelected(response.data.versions);
        });
    } catch (error) {
      setVehicleSelected([]);
    }
  };

  const getVehicles = async () => {
    await axios
      .get(
        `${CONFIG.API_URL}vehicles/pageable?countryCode=${countryCode}&`,
        tokenHeaders,
      )
      .then((response) => {
        setVehicles(response.data.content);
      });
  };

  useEffect(() => {
    setNewData(data);
    if (data) {
      setVehiclePills(data?.pillsVehicles!);
    }

    getVehicles();
  }, []);

  useEffect(() => {
    const disableOptions =
      !!vehiclePills.find(
        ({ vehicleId }) => vehicleId === "all" || vehicleId === null,
      ) && vehiclePills.length > 0;
    setDisableVehicleOptions(disableOptions);
  }, [vehiclePills, fields]);

  useEffect(() => {
    if (vehicleSelected.length) {
      const selectedVersions = fields.map(
        ({ vehicleVersionName }: any) => vehicleVersionName,
      );
      const versions = vehicleSelected
        .map(({ modelCode, name }: any) => `${name}/${modelCode}`)
        .filter((version) => !selectedVersions.includes(version));
      setDisableVersionsOptions(versions.length === 0);
    }
  }, [vehicleSelected]);

  useEffect(() => {
    if (disableVersionsOptions) {
      alert(t("pills.emptyVersions"));
    }
  }, [disableVersionsOptions]);

  const resetFields = () => {
    // reset();

    // htmlVehicleSelected.innerText = "";
    // htmlVehicleVersion.textContent = "";
    htmlPackageVehicle.value = "";
    htmlVehicleVersionEdition.value = "";

    setPackageVehicle("");
    setVehicleVersionEdition("");
    // setvehicleVersionName("");
    setVehicleVersionId("");
    // setvehicleName("");
    setVehicleId("");
    getVehicles();
    getVehicleVersion(0);
  };

  const handleVehicleSelected = (e: any) => {
    setVehicleVersionId("none");
    if (e.target.value === 1) {
      setVehicleVersionId("");
      return setVehicleId("all");
    }
    getVehicleVersion(e.target.value.id);
    setVehicleId(e.target.value.id);
  };

  const handleVehicleVersion = (e: any) => {
    if (e.target.value === 1) {
      return setVehicleVersionId("all");
    }
    setVehicleVersionId(e.target.value.id);
    setVehicleVersionYear(e.target.value.year);
  };

  const insertIntoArray = () => {
    let newVehicle: any;

    if (vehicleId === "all") {
      setVehiclePills([]);
      remove();
      setVehicleVersionId("all");
      newVehicle = {
        vehicleId,
        vehicleName: t("pills.allVehicles"),
        vehicleVersionId: "all",
        vehicleVersionName: "",
        vehicleVersionYear,
        vehicleVersionEdition,
        packageVehicle,
      };
    } else if (vehicleVersionId === "all") {
      newVehicle = {
        vehicleId,
        vehicleName: htmlVehicleSelected.textContent,
        vehicleVersionId,
        vehicleVersionName: t("pills.allVersion"),
        vehicleVersionYear,
        vehicleVersionEdition,
        packageVehicle,
      };
    } else {
      newVehicle = {
        vehicleId,
        vehicleName: htmlVehicleSelected.textContent,
        vehicleVersionId,
        vehicleVersionName: htmlVehicleVersion.textContent,
        vehicleVersionYear,
        vehicleVersionEdition,
        packageVehicle,
      };
    }

    setVehiclePills((current) => [...current, ...[newVehicle]]);
    setVehicleVersionId("");
    // htmlVehicleVersion.innerHTML = "";
  };

  const removeFromArray = (index: number) => {
    vehiclePills.splice(index, 1);
  };

  const filterVersions = ({ modelCode, name }: any) => {
    const selectedVersions = fields.map(
      (_, index) => vehiclePills[index]?.vehicleVersionName,
    );
    const presentVersion = `${name}/${modelCode}`;
    const showVersion = !selectedVersions.includes(presentVersion);
    return showVersion;
  };

  const filterVehicles = (vehicle: IVehicle): boolean => {
    const { name, versions } = vehicle;
    const pill = vehiclePills.find(({ vehicleName }) => vehicleName === name);
    const showVehicleConditions = [
      () =>
        !pill ||
        (!!pill?.vehicleVersionName &&
          pill?.vehicleVersionName !== "Todas as versões"),
    ];
    const result = showVehicleConditions.reduce(
      (acc, crr) => acc && crr(),
      true,
    );
    return result;
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4}>
          <Select
            displayEmpty
            labelId="colorSelect"
            id="handleVehicleSelected"
            placeholder="Select"
            // onChange={handleChange}
            onChange={(e) => handleVehicleSelected(e)}
            disabled={disableVehicleOptions}
            defaultValue=""
            sx={{ height: 40, width: "100%" }}
          >
            <MenuItem disabled value="">
              <em>{t("pills.selectModel")}</em>
            </MenuItem>
            <MenuItem value={1} id="all">
              {t("pills.all")}
            </MenuItem>
            {vehicles.filter(filterVehicles).map((vehicle: any) => {
              return (
                <MenuItem key={vehicle.id} value={vehicle} id={vehicle.name}>
                  {vehicle.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            displayEmpty
            labelId="colorSelect"
            id="handleVehicleVersion"
            placeholder="Select"
            // onChange={handleChange}
            onChange={(e) => handleVehicleVersion(e)}
            defaultValue=""
            sx={{ height: 40, width: "100%" }}
            // disabled={vehicleSelected.length === 0 ? true : false}
            disabled={
              (!vehicleId || vehicleId === "all" ? true : false) ||
              disableVersionsOptions
            }
          >
            <MenuItem disabled value="">
              <em>{t("pills.selectVersion")}</em>
            </MenuItem>
            <MenuItem value={1} id="all">
              {t("pills.all")}
            </MenuItem>
            {vehicleSelected.filter(filterVersions).map((model: any) => {
              return (
                <MenuItem key={model.id} value={model} id={model.id}>
                  {model.name} | {model.modelCode} | {model.year}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={2}>
          <FormInputText
            label={t("pills.vehicleVersionEdition")}
            type="text"
            id="vehicleVersionEdition"
            onChange={(e) => setVehicleVersionEdition(e.target.value)}
            disabled={!vehicleVersionId ? true : false}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>

        <Grid item xs={2}>
          <FormInputText
            label={t("pills.packageVehicle")}
            type="text"
            id="packageVehicle"
            onChange={(e) => setPackageVehicle(e.target.value)}
            disabled={!vehicleVersionId ? true : false}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
      </Grid>

      <Box>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            resetFields();
            if (editPill) {
              if (vehiclePills[0]?.vehicleId === "all") {
                return alert(t("pills.allVehiclesOnly"));
              }
            }

            insertIntoArray();

            append({
              vehicleId: vehicleId === "all" ? "" : vehicleId,
              vehicleName: htmlVehicleSelected.textContent,
              vehicleVersionId:
                vehicleVersionId === "all" ? "" : vehicleVersionId,
              vehicleVersionName: htmlVehicleVersion.textContent,
              vehicleVersionYear:
                vehicleVersionYear === "none" ? "" : vehicleVersionYear,
              vehicleVersionEdition,
              packageVehicle,
            });
          }}
          disabled={
            (vehicleVersionId === "none" || vehicleId === "" ? true : false) ||
            (vehicleId !== "all" && vehicleVersionId === "" ? true : false)
          }
        >
          {t("pills.addVehicle")}
        </Button>
      </Box>

      <p style={{ fontSize: 14, fontWeight: "bold" }}>Pílulas para:</p>

      <Box
        sx={{
          display: "flex",
          rowGap: 1,
          columnGap: 3,
          flexWrap: "wrap",
          marginTop: 2,
        }}
      >
        {fields.map((child, childIndex) => {
          return (
            <Box key={child.id}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  borderRadius: 5,
                  paddingX: 1,
                  paddingY: 0.5,
                  backgroundColor: "var(--blue-400)",
                  display: "flex",
                  placeItems: "center",
                }}
              >
                {vehiclePills.length !== 0 && (
                  <Box sx={{ fontSize: 10, fontWeight: 700, color: "white" }}>
                    {vehiclePills[childIndex].vehicleName === null
                      ? t("pills.allVehicles")
                      : vehiclePills[childIndex].vehicleName}{" "}
                    |{" "}
                    {vehiclePills[childIndex].vehicleVersionName !== "" &&
                    vehiclePills[childIndex].vehicleVersionName !== null ? (
                      <>
                        {vehiclePills[childIndex].vehicleVersionName} |{" "}
                        {vehiclePills[childIndex].vehicleVersionYear !== "" && (
                          <>
                            {vehiclePills[childIndex].vehicleVersionYear} |{" "}
                            {vehiclePills[childIndex].packageVehicle}
                          </>
                        )}
                      </>
                    ) : (
                      t("pills.allVersion")
                    )}
                  </Box>
                )}

                <Box sx={{ position: "absolute", right: 5, bottom: 1 }}>
                  <IconButton
                    onClick={() => {
                      removeFromArray(childIndex);
                      remove(childIndex);
                    }}
                    sx={{ width: 15, height: 15 }}
                  >
                    <CloseIcon sx={{ width: 15, color: "black" }} />
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  left: "-10000px",
                  top: "auto",
                  width: "1px",
                  height: "1px",
                  overflow: "hidden",
                }}
              >
                <FormInputText
                  label={t("pills.vehicleId")}
                  type="text"
                  {...register(`pillsVehicles[${childIndex}].vehicleId`)}
                  error={errors?.["pillsVehicles"]?.[childIndex]?.["vehicleId"]}
                />
                <FormInputText
                  label={t("pills.vehicleName")}
                  type="text"
                  {...register(`pillsVehicles[${childIndex}].vehicleName`)}
                  error={
                    errors?.["pillsVehicles"]?.[childIndex]?.["vehicleName"]
                  }
                />
                <FormInputText
                  label={t("pills.vehicleVersionId")}
                  type="text"
                  {...register(`pillsVehicles[${childIndex}].vehicleVersionId`)}
                  error={
                    errors?.["pillsVehicles"]?.[childIndex]?.[
                      "vehicleVersionId"
                    ]
                  }
                />
                <FormInputText
                  label={t("pills.vehicleVersionName")}
                  type="text"
                  {...register(
                    `pillsVehicles[${childIndex}].vehicleVersionName`,
                  )}
                  error={
                    errors?.["pillsVehicles"]?.[childIndex]?.[
                      "vehicleVersionName"
                    ]
                  }
                />

                <FormInputText
                  label={t("pills.vehicleVersionYear")}
                  type="text"
                  {...register(
                    `pillsVehicles[${childIndex}].vehicleVersionYear`,
                  )}
                  error={
                    errors?.["pillsVehicles"]?.[childIndex]?.[
                      "vehicleVersionYear"
                    ]
                  }
                />
                <FormInputText
                  label={t("pills.vehicleVersionEdition")}
                  type="text"
                  {...register(
                    `pillsVehicles[${childIndex}].vehicleVersionEdition`,
                  )}
                  error={
                    errors?.["pillsVehicles"]?.[childIndex]?.[
                      "vehicleVersionEdition"
                    ]
                  }
                  inputProps={{ maxLength: 60 }}
                />
                <FormInputText
                  label={t("pills.packageVehicle")}
                  type="text"
                  {...register(`pillsVehicles[${childIndex}].packageVehicle`)}
                  error={
                    errors?.["pillsVehicles"]?.[childIndex]?.["packageVehicle"]
                  }
                  inputProps={{ maxLength: 60 }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
