import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { CONFIG } from "../../services/config";

export default function Login() {
  function goToVwID() {
    window.location.href = `${CONFIG.VW_ID_BASEURL}v1/authorize?response_type=code%20id_token%20token&nonce=12345678901234567890123456789012&client_id=${CONFIG.VW_ID_CLIENT_ID}&scope=openid%20profile%20address%20cars%20phone&redirect_uri=${CONFIG.CALLBACK_URL}`;
  }

  useEffect(() => {
    goToVwID();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "2rem",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
