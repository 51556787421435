import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ILabelContainer {
  children: ReactNode;
}
export function LabelContainer({ children }: ILabelContainer) {
  const { t } = useTranslation();
  return (
    <Box>
      <Box>{children}</Box>
      <Box>
        <Typography fontSize="12px" lineHeight="16px">
          {t("faq.requires.required_fields")}
        </Typography>
      </Box>
    </Box>
  );
}
