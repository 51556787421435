interface Cookie {
  name: string;
  value: string;
  maxSeconds?: number;
}

export default function useCookies() {
  function cookies(): Cookie[] {
    const cookieList = document.cookie.split(";").map((cookie) => {
      const [name, value] = cookie.split("=");
      return { name: name?.trim(), value: value?.trim() };
    });
    return cookieList;
  }

  const setCookie = ({ name, value, maxSeconds = 0 }: Cookie) => {
    const mili = maxSeconds * 1000;
    const expires = new Date(Date.now() + mili);
    const tenYears = 1000 * 60 * 60 * 24 * 365 * 10;
    document.cookie = `${name?.trim()}=${value?.trim()};${
      maxSeconds
        ? `expires=${expires.toUTCString()};`
        : `expires=${new Date(Date.now() + tenYears).toUTCString()};`
    };Path=/;SameSite=Strict;Secure`;
  };

  const removeAll = (...except: string[]) => {
    cookies().forEach(({ name }) => {
      if (!except.includes(name)) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;SameSite=Strict;Secure`;
      }
    });
  };

  const findCookie = (name: string) => {
    return cookies().find(({ name: cookieName }) => cookieName === name);
  };

  return { cookies, setCookie, findCookie, removeAll };
}
