import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { IVehicle } from "../../interfaces/IVehicle";
import { FormInputText } from "../form/FormInputText";
import ModalConfirmation from "../MyModal/modalConfirmation";
import ModelImage from "./modelImage";
import { CONFIG } from "../../services/config";
import { ICopyEnvironment } from "../../interfaces/ICopyEnvironment";
import { AuthContext } from "../../contexts/AuthContext";

export default function ModelVehicle({
  errors,
  editVehicle,
  watchScroll,
  data,
}: any) {
  const { t } = useTranslation();
  const NUMERIC_REGEX = /^[0-9-\s]+$/;
  // console.log("data modelVehicle", data);
  // console.log("data version", data.versions);

  const addButton = useRef<any>();

  const [loading, setLoading] = useState(false);
  const [loadingCopyEnvironment, setLoadingCopyEnvironment] = useState(false);
  const [loadingAddButton, setLoadingAddButton] = useState(false);
  const [yearClass, setYearClass] = useState(true);
  const [noYearFound, setNoYearFound] = useState(false);
  // const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [childrenLength, setChildrenLength] = useState<number | undefined>(0);
  const [modelLength, setModelLength] = useState(0);
  const [allYears, setAllYears] = useState<string[]>(["All"]);
  const [newModel, setNewModel] = useState(false);
  const [newVersionModel, setNewVersionModel] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [selectedYear, setSelectedYear] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [removeVehicleColor, setRemoveVehicleColor] = useState<number>();
  const [newData, setNewData] = useState<IVehicle>(data);
  const [copy, setCopy] = useState<boolean>(false);
  const [environment, setEnvironment] = useState("");
  const [country, setCountry] = useState("");
  const [copyEnvironment, setCopyEnvironment] = useState<ICopyEnvironment>();
  const [versions, setVersions] = useState<number[]>([]);
  const {
    user,
    tokenHeaders,
    user: { countries },
    country: { countryCode },
  } = useContext(AuthContext);

  const handleEnvironment = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value as string);
  };

  const selectYear = (e: any) => {
    let style = "none";
    setSelectedYear(e.value);
    setTimeout(() => {
      if (e.value === "yearAll") {
        style = "block";
        setTimeout(() => {
          setPageSize(childrenLength!);
          setLoading(false);
          const boxYear = document.getElementsByClassName(`${e.value}`) as any;

          for (let index = 0; index < allYears.length; index++) {
            const year = allYears[index];
            const thisYear = document.getElementsByClassName(
              `year${year}`,
            ) as any;

            for (const element of thisYear) {
              element.style.display = style;
            }
          }

          for (const element of boxYear) {
            element.style.display = "block";
          }
        }, 500);
      } else {
        if (pageSize < childrenLength!) {
          setPageSize(childrenLength!);
        }

        setTimeout(() => {
          let boxYear = document.getElementsByClassName(`${e.value}`) as any;

          if (boxYear.length === 0 && e.value !== "yearAll") {
            boxYear = document.getElementsByClassName(`${e.value}`) as any;
            // setNoYearFound(true);
          } else {
            setNoYearFound(false);
          }
          for (let index = 0; index < allYears.length; index++) {
            const year = allYears[index];
            const thisYear = document.getElementsByClassName(
              `year${year}`,
            ) as any;

            for (const element of thisYear) {
              element.style.display = style;
            }
          }

          for (const element of boxYear) {
            element.style.display = "block";
          }
          setLoading(false);
        }, 500);
      }
    }, 500);
  };

  useEffect(() => {
    if (data) {
      setChildrenLength(data.versions.length);
      setModelLength(data.versions.length);

      const arrayYear: any[] = [];

      data.versions.map((item: any) => {
        arrayYear.push(item.year);
        return setAllYears((current) => [
          ...current,
          ...[item.year].filter((c) => !current.includes(c)),
        ]);
      });
    }
  }, [data]);

  const { register, control } = useFormContext();
  const { fields, append, prepend, remove, insert } = useFieldArray({
    control,
    name: `versions`,
  });

  const removeFromArray = (childIndex: number) => {
    if (newData) {
      newData.versions.splice(childIndex, 1);
    }
  };

  const addFromArray = () => {
    if (newData) {
      const tempArray = newData;
      tempArray.versions.unshift({
        id: 0,
        name: "",
        modelCode: "",
        year: 0,
        vwPlay: "",
        isCognitive: "",
        isCarNetEnabled: false,
        isElectric: false,
        version: [],
      });
      setNewData(tempArray);
    }
  };

  const messageErrorContactList = () => {
    const contactList = document.querySelector(
      "#requiredVersion",
    ) as HTMLDivElement;
    contactList.style.display = "block";
    setTimeout(() => {
      contactList.style.display = "none";
    }, 5000);
  };

  useEffect(() => {
    setPageSize((currentValue) => currentValue + 10);
  }, [watchScroll]);

  const confirmDelete = () => {
    if (newVersionModel > 0 && newVersionModel >= removeVehicleColor!) {
      setNewVersionModel((prevState) => {
        const updatedValue = prevState - 1;
        if (updatedValue === 0) {
          setYearClass(true);
          setNewModel(false);
        }
        return updatedValue;
      });
    } else if (removeVehicleColor !== undefined) {
      const yearRemove = newData.versions[removeVehicleColor].year;
      const years = allYears.filter(
        (year) => year.toString() !== yearRemove.toString(),
      );
      setAllYears(years);
      selectYear({ name: undefined, value: "yearAll" });
    }
    removeFromArray(removeVehicleColor as any);
    remove(removeVehicleColor);
    setChildrenLength(childrenLength! - 1);
    if (removeVehicleColor! + 1 <= modelLength!) {
      setModelLength(modelLength - 1);
    }
  };

  const sendCopyEnvironment = async () => {
    setLoadingCopyEnvironment(true);
    try {
      await axios.post(
        `${CONFIG.API_URL}vehicles/integration/modelVersion/send?email=${user.email}&countryCodeOrigin=${countryCode}&countryCodeDestination=${country}`,
        {
          environment,
          modelId: newData.id,
          versionsId: versions,
        },
        tokenHeaders,
      );
      setLoadingCopyEnvironment(false);
    } catch (error: any) {
      setLoadingCopyEnvironment(false);
    }
  };

  return (
    <Box>
      {editVehicle && (
        <Box>
          <Grid
            hidden={
              CONFIG.ENVIRONMENT !== "UAT" && CONFIG.ENVIRONMENT !== "STAGING"
                ? true
                : false
            }
          >
            <Divider />
            <Grid container sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
              <Grid item xs={4}>
                <FormGroup sx={{ marginBottom: "1rem" }}>
                  <FormControlLabel
                    control={
                      <Switch checked={copy} onChange={() => setCopy(!copy)} />
                    }
                    label={t("models.copyAnotherEnvironment")}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={4} hidden={!copy} sx={{ paddingRight: "16px" }}>
                {t("models.selectDestinationEnvironment")}:
              </Grid>
              <Grid item xs={2} hidden={!copy}>
                <Select
                  displayEmpty
                  placeholder="Select"
                  sx={{ height: 35, minWidth: "208px" }}
                  onChange={handleEnvironment}
                  value={environment || ""}
                >
                  <MenuItem value="">
                    <em>{t("models.selectEnvironment")}</em>
                  </MenuItem>
                  <MenuItem value="PRODUCTION">
                    {t("models.production")}
                  </MenuItem>
                  <MenuItem value="STAGING">
                    {t("models.preProduction")}
                  </MenuItem>
                  <MenuItem value="UAT">{t("models.uat")}</MenuItem>
                  <MenuItem value="DEVELOPMENT">
                    {t("models.development")}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4} hidden={!copy} sx={{ paddingBottom: "16px" }}>
                <Select
                  displayEmpty
                  placeholder="Select"
                  sx={{ height: 35, minWidth: "208px" }}
                  onChange={(event: any) => {
                    setCountry(event.target.value);
                  }}
                  value={country || ""}
                >
                  <MenuItem value="">
                    <em>{t("models.selectEnvironment")}</em>
                  </MenuItem>
                  {countries.map((aux: any) => {
                    return (
                      <MenuItem value={aux.countryCode}>{aux.country}</MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Button
                sx={{ display: copy === false ? "none" : "inline-flex" }}
                variant="contained"
                type="button"
                fullWidth
                onClick={sendCopyEnvironment}
                disabled={
                  versions.length !== 0 && environment !== "" && country !== ""
                    ? false
                    : true
                }
              >
                {t("models.makeCopy")}
              </Button>
              <Grid xs={12} sx={{ textAlign: "center" }}>
                {loadingCopyEnvironment && <CircularProgress />}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {loading && <CircularProgress />}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p>{t("models.selectModelYear")}:</p>
              <Select
                displayEmpty
                labelId="colorSelect"
                id="colorSelect"
                placeholder="Select"
                sx={{ height: 35, width: 180 }}
                onChange={(e) => {
                  setLoading(true);
                  selectYear(e.target);
                }}
                value={selectedYear || ""}
                disabled={yearClass ? false : true}
              >
                <MenuItem disabled value="">
                  <em>{t("models.selectYear")}</em>
                </MenuItem>
                {allYears
                  .sort()
                  .reverse()
                  .map((item) => {
                    return (
                      <MenuItem
                        key={`${item}?id=${Math.random()}`}
                        value={`year${item}`}
                      >
                        {item === "All" ? "Todos" : item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Box>
          </Box>
          {!yearClass && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginBottom: 2,
                color: "var(--gray-300)",
                fontSize: "14px",
              }}
            >
              {t("models.disabledSelectYear")}
            </Box>
          )}
        </Box>
      )}

      {noYearFound && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            color: "var(--red-100)",
            marginBottom: 2,
            fontSize: "14px",
          }}
        >
          {t("models.yearNotFound")}
        </Box>
      )}
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="button"
            fullWidth
            onClick={() => {
              setLoadingAddButton(true);
              setYearClass(false);
              setNewModel(true);
              setNewVersionModel(newVersionModel + 1);
              addFromArray();
              setTimeout(() => {
                if (pageSize < childrenLength!) {
                  setPageSize(childrenLength!);
                }
                setLoadingAddButton(false);
                prepend({
                  modelCode: "",
                  name: "",
                  year: "",
                  vwPlay: "",
                  isCognitive: "",
                });
                setPageSize((currentValue) => currentValue + 1);
                // document.querySelector("#loadingAddButton")!.scrollIntoView();
                // addButton.current!.scrollIntoView();
                /*
                setTimeout(() => {
                  addButton.current?.scrollIntoView({ behavior: "smooth" });
                }, 100);
                */
                setChildrenLength(childrenLength! + 1);
              }, 500);
              // setChildrenLength(childrenLength! + 1);
              // setModelLength(modelLength + 1);
            }}
          >
            {t("models.addVersion")}
          </Button>
        </Box>

        {loadingAddButton && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {fields
        .filter((_, index) => {
          return index < pageSize;
        })
        .map((child: any, childIndex) => {
          return (
            <Box
              key={child.id}
              sx={{ borderTop: "1px solid var(--gray-100)", paddingTop: 2 }}
              className={
                yearClass && editVehicle && newData
                  ? `year${newData?.versions[childIndex].year}`
                  : ""
              }
            >
              <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid hidden={!copy} sx={{ padding: "16px 0px 0px 16px" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              child.version.map((aux: any) => {
                                setVersions([...versions, aux.id]);
                                return versions;
                              });
                            } else {
                              let newArray: any[] = [];
                              child.version.map((aux: any) => {
                                newArray = versions.filter(
                                  (version: any) => version !== aux.id,
                                );
                                return child;
                              });
                              setVersions(newArray);
                            }
                          }}
                        />
                      }
                      label={t("models.copy")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <FormInputText
                    label={t("models.version")}
                    type="text"
                    {...register(`versions[${childIndex}].name`)}
                    error={errors?.["versions"]?.[childIndex]?.["name"]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputText
                    label={t("models.modelCode")}
                    type="text"
                    {...register(`versions[${childIndex}].modelCode`)}
                    error={errors?.["versions"]?.[childIndex]?.["modelCode"]}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInputText
                    label={t("models.year")}
                    type="text"
                    inputProps={{
                      maxLength: 4,
                      minLength: 4,
                    }}
                    onKeyDown={(event: any) => {
                      if (
                        event.key === "Backspace" ||
                        event.key === "Tab" ||
                        event.key === "ArrowRight" ||
                        event.key === "ArrowLeft" ||
                        event.key === "Delete"
                      ) {
                        return true;
                      }
                      if (!NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register(`versions[${childIndex}].year`)}
                    error={errors?.["versions"]?.[childIndex]?.["year"]}
                    disabled={
                      editVehicle && childIndex >= newVersionModel
                        ? true
                        : false
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormGroup sx={{ marginBottom: "1rem" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="vwPlay"
                          {...register(`versions[${childIndex}].vwPlay`)}
                          defaultChecked={
                            !newModel
                              ? newData.versions[childIndex].vwPlay
                                ? true
                                : false
                              : true
                          }
                        />
                      }
                      label={t("models.vwPlay")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={4}>
                  <FormGroup sx={{ marginBottom: "1rem" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="isCognitive"
                          {...register(`versions[${childIndex}].isCognitive`)}
                          defaultChecked={
                            !newModel
                              ? newData.versions[childIndex].isCognitive
                                ? true
                                : false
                              : true
                          }
                        />
                      }
                      label={t("models.isCognitive")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <FormGroup sx={{ marginBottom: "1rem" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="isCarNetEnabled"
                          {...register(
                            `versions[${childIndex}].isCarNetEnabled`,
                          )}
                          defaultChecked={
                            !newModel
                              ? newData.versions[childIndex].isCarNetEnabled
                              : false
                          }
                        />
                      }
                      label={t("models.isCarNetEnabled")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <FormGroup sx={{ marginBottom: "1rem" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="isConected"
                          {...register(`versions[${childIndex}].isElectric`)}
                          defaultChecked={
                            !newModel
                              ? newData.versions[childIndex].isElectric
                              : false
                          }
                        />
                      }
                      label={t("models.isElectric")}
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <ModelImage
                childIndex={childIndex}
                errors={errors}
                data={newData}
              />

              <Box
                sx={{
                  display: "flex",
                  placeItems: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  sx={{ marginY: 2 }}
                  onClick={() => {
                    if (childrenLength === 1) {
                      return messageErrorContactList();
                    }

                    setRemoveVehicleColor(childIndex);
                    setShowConfirmDelete(!showConfirmDelete);

                    // removeFromArray(childIndex);
                    // remove(childIndex);

                    // console.log("modelLength", modelLength);
                  }}
                  // onClick={() => setShowConfirmDelete(!showConfirmDelete)}
                >
                  {t("models.deleteVersion")}
                </Button>
                <Box
                  sx={{
                    color: "var(--red-100)",
                    display: "none",
                  }}
                  id="requiredVersion"
                >
                  <span>{t("models.requiredVersion")}</span>
                </Box>
                {/* {showConfirmDelete && (
                <Box
                  sx={{
                    display: "flex",
                    placeItems: "center",
                    gap: "1rem",
                  }}
                >
                  {t("button.Confirm delete")}:
                  <Button
                    variant="contained"
                    onClick={() => {
                      removeFromArray(childIndex);
                      remove(childIndex);
                      setShowConfirmDelete(!showConfirmDelete);
                    }}
                    color="error"
                  >
                    {t("button.Yes")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setShowConfirmDelete(!showConfirmDelete)}
                  >
                    {t("button.No")}
                  </Button>
                </Box>
              )} */}
              </Box>
            </Box>
          );
        })}
      {showConfirmDelete && (
        <ModalConfirmation
          openDelete={setShowConfirmDelete}
          confirmDelete={confirmDelete}
          title={`${t("button.Confirm delete")} ${t("models.versionRemove")} ${
            Array.isArray(newData.versions) &&
            newData.versions.length >
              (removeVehicleColor ? removeVehicleColor : 0) &&
            newData.versions[removeVehicleColor ? removeVehicleColor : 0] !==
              undefined &&
            newData.versions[removeVehicleColor ? removeVehicleColor : 0].name
              ? newData.versions[removeVehicleColor ? removeVehicleColor : 0]
                  .name
              : ""
          }`}
        />
      )}
      {loadingAddButton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box ref={addButton} />
    </Box>
  );
}
