import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { ITerms } from "../../interfaces/ITerms";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

export default function EditTerms({
  watchDataChange,
  messageError,
  data,
}: any) {
  const terms: ITerms = data;
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [archive, setArchive] = useState<any>();

  const addFormData = yup.object().shape({
    title: yup.string().required(t("terms.Required title")),
    active: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ITerms>({ resolver: yupResolver(addFormData) });

  const validatePDF = async (event: any) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(pdf)$/)) {
      alert(t("terms.validatePDF"));
      const pdfTemplate = document.querySelector(
        "#pdfTemplate",
      ) as HTMLInputElement;
      pdfTemplate.value = "";
      return false;
    }

    setArchive(file);
  };

  console.log("archive", archive);

  const editTerms: SubmitHandler<ITerms> = async (values) => {
    console.log("values", values);

    // if (!archive) {
    //   setMessageValidatePDF(true);
    //   return setTimeout(() => {
    //     setMessageValidatePDF(false);
    //   }, 5000);
    // }

    const { authorization, idToken, code, countryCode } = tokenHeaders.headers;
    const newHeader = {
      headers: {
        authorization,
        idToken,
        code,
        countryCode,
        title: values.title,
        active: values.active,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      await axios.put(
        `${CONFIG.API_URL}termofuse/vwapp/?id=${data.id}`,
        {
          file: archive,
        },
        newHeader,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("terms.Term edited"));
  };

  const deleteTerm = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}termofuse/vwapp/?id=${data?.id}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("terms.Term deleted"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editTerms)}>
      <FormInputText
        label={t("terms.title")}
        error={errors.title}
        defaultValue={terms.title}
        {...register("title")}
        inputProps={{
          maxLength: 40,
        }}
      />

      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={9}>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnArchive")}</span>
            <input
              accept=".pdf"
              type="file"
              name="fileInput"
              id="pdfTemplate"
              onChange={(event) => {
                validatePDF(event);
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <FormGroup sx={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Switch
                  id="addActive"
                  {...register("active")}
                  defaultChecked={terms.active ? true : false}
                />
              }
              label={t("countries.Active")}
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <p>{t("terms.downloadArchive")}:</p>
        <IconButton href={data.termPath} download={data.title}>
          <PictureAsPdfIcon
            sx={{
              // cursor: "pointer",
              color: "var(--gray-900)",
              width: 30,
              height: 30,
            }}
          />
        </IconButton>
      </Box>

      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteTerm}
      />
    </Box>
  );
}
