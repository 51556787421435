import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
// import GalleryBox from "./galleryBox";

export default function GalleryView({
  onClick,
  dataUpdated = 0,
  endPoint,
  messageError,
  Component,
  columnsGrid,
  params1,
  labelParams1,
  typeInput1,
  params2,
  labelParams2,
  typeInput2,
  params3,
  labelParams3,
  typeInput3,
}: any) {
  const { t } = useTranslation();

  const [errorFetching, setErrorFetching] = useState(false);
  const {
    tokenHeaders,
    isAuthenticated,
    country: { countryCode },
  } = useContext(AuthContext);
  const [searchParams1, setSearchParams1] = useState("");
  const [searchParams2, setSearchParams2] = useState("");
  const [searchParams3, setSearchParams3] = useState("");

  const [nameTeste, setNameTeste] = useState<string | undefined>();

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const searchData = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));

    !searchParams1 ? (params1 = "") : true;
    !searchParams2 ? (params2 = "") : true;
    !searchParams3 ? (params3 = "") : true;

    axios
      .get(
        `${CONFIG.API_URL}${endPoint}page=${pageState.page - 1}&size=${
          pageState.pageSize
        }${params1}${searchParams1}${params2}${searchParams2}${params3}${searchParams3}`,
        tokenHeaders,
      )
      .then((response) => {
        console.log("response gallery", response.data);
        setErrorFetching(false);

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.content,
          total: response.data.totalElements,
          page: response.data.number + 1,
        }));
      })
      .catch((error: any) => {
        setErrorFetching(true);
        if (error.response.data.message) {
          return messageError(error.response.data.message);
        }
      });
  };

  const clearInputs = () => {
    const input1 = document.querySelector("#label1") as HTMLInputElement;
    const input2 = document.querySelector("#label2") as HTMLInputElement;
    const input3 = document.querySelector("#label3") as HTMLInputElement;
    input1 ? (input1.value = "") : true;
    input2 ? (input2.value = "") : true;
    input3 ? (input3.value = "") : true;
    params1 ? (params1 = "") : true;
    params2 ? (params2 = "") : true;
    params3 ? (params3 = "") : true;

    setSearchParams1("");
    setSearchParams2("");
    setSearchParams3("");

    searchData();
  };

  useEffect(() => {
    searchData();
  }, [dataUpdated, pageState.page, pageState.pageSize, countryCode]);

  if (!isAuthenticated) {
    return (
      <Box>
        <Alert severity="error">{t("userNotSigned.title")}</Alert>
        <Alert sx={{ marginTop: 2 }} severity="warning">
          {t("userNotSigned.warning")}
        </Alert>
      </Box>
    );
  }

  const changePage = (pageIndex: number) => {
    setPageState((presentPageState) => ({
      ...presentPageState,
      page: pageIndex,
    }));
  };

  const handleChangePageSize = (pageSize: number) => {
    setPageState((presentPageState) => ({
      ...presentPageState,
      pageSize,
      page: 1,
    }));
  };

  return (
    <>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={!params2 ? 12 : 4}>
          {params1 && labelParams1 && (
            <FormInputText
              id="label1"
              type={typeInput1 ? typeInput1 : "text"}
              label={labelParams1}
              onChange={(event) => setSearchParams1(event.target.value)}
              onKeyDown={(event: any) => {
                console.log("event key", event.key);
                if (event.key === "Enter") {
                  searchData();
                }
              }}
            />
          )}
        </Grid>
        {params2 && labelParams2 && (
          <Grid item xs={4}>
            <FormInputText
              type={typeInput2 ? typeInput2 : "text"}
              id="label2"
              label={labelParams2}
              onChange={(event) => setSearchParams2(event.target.value)}
              onKeyDown={(event: any) => {
                console.log("event key", event.key);
                if (event.key === "Enter") {
                  searchData();
                }
              }}
            />
          </Grid>
        )}
        {params3 && labelParams3 && (
          <Grid item xs={4}>
            <FormInputText
              type={typeInput3 ? typeInput3 : "text"}
              id="label3"
              label={labelParams3}
              onChange={(event) => setSearchParams3(event.target.value)}
              onKeyDown={(event: any) => {
                console.log("event key", event.key);
                if (event.key === "Enter") {
                  searchData();
                }
              }}
            />
          </Grid>
        )}
      </Grid>

      {params1 && labelParams1 && (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            marginBottom: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              searchData();
            }}
          >
            {t("button.Search")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              clearInputs();
            }}
          >
            {t("button.Reset")}
          </Button>
        </Box>
      )}

      {errorFetching && (
        <Box sx={{ marginBottom: 2 }}>
          <span style={{ color: "var(--red-100)" }}>
            {searchParams1 ? (
              `${t("alert.notResult")}: ${searchParams1}`
            ) : (
              <>
                <Alert severity="error">{t("alert.errorFetch")}</Alert>
                <Alert severity="warning" sx={{ marginY: "1rem" }}>
                  {t("alert.tryAgain")}
                </Alert>
              </>
            )}
          </span>
        </Box>
      )}

      {pageState.isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!errorFetching && (
        <Box>
          <Grid
            sx={{ display: "flex" }}
            container
            spacing={2}
            columns={{ columnsGrid }}
          >
            {pageState.data.map((item: any) => {
              return <Component key={item.id} data={item} onClick={onClick} />;
            })}
          </Grid>

          <hr style={{ marginTop: 20 }} />
          <Grid
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              marginTop: 2,
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                paddingBottom: 2,
                fontSize: 14,
              }}
            >
              <span>
                {t("gallery.totalItems")}: {pageState.total}
              </span>
            </Grid>

            <Grid sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  placeItems: "center",
                  fontSize: 14,
                }}
              >
                <p>{t("gallery.lines")}:</p>
                <Select
                  id="pageSizeSelect"
                  value={pageState.pageSize}
                  defaultValue={25}
                  label="Teste"
                  sx={{
                    height: 35,
                  }}
                  onChange={($event) =>
                    handleChangePageSize(Number($event.target.value))
                  }
                >
                  <MenuItem key="25" data-my-value="25" value={25}>
                    25
                  </MenuItem>
                  <MenuItem key="50" data-my-value="50" value={50}>
                    50
                  </MenuItem>
                  <MenuItem key="75" data-my-value="75" value={75}>
                    75
                  </MenuItem>
                  <MenuItem key="100" data-my-value="100" value={100}>
                    100
                  </MenuItem>
                </Select>
              </Box>

              <Pagination
                count={Math.round(pageState.total / pageState.pageSize) || 1}
                page={pageState.page}
                color="primary"
                onChange={(_, index) => changePage(index)}
                hideNextButton
                hidePrevButton
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
