import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { IModule } from "../../interfaces/IModule";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";
import Module from "./module";

export default function EditModule({
  watchDataChange,
  messageError,
  addFormData,
  data,
}: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IModule | any>({
    mode: "onBlur",
    resolver: yupResolver(addFormData),
    defaultValues: data,
  });

  const editModule: SubmitHandler<IModule> = async (values) => {
    // Add interface IModule
    console.log("values", values);

    try {
      await axios.put(
        `${CONFIG.API_URL}countryModules/${data.id}`,
        {
          moduleName: values.moduleName,
          moduleTitle: values.moduleTitle,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("modules.moduleEdited"));
  };

  const deleteModule = async () => {
    console.log(`data`, data.id);
    try {
      await axios.delete(
        `${CONFIG.API_URL}countryModules?moduleId=${data.id}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("modules.moduleDeleted"));
  };

  const methods = useFormContext();

  return (
    <FormProvider
      {...methods}
      reset={reset}
      register={register}
      control={control}
    >
      <Box component="form" onSubmit={handleSubmit(editModule)}>
        {data && <Module errors={errors} data={data} />}

        <ActionButtons
          saveName={t("button.Save")}
          typeButton="submit"
          confirmDelete={deleteModule}
        />
      </Box>
    </FormProvider>
  );
}
