/* eslint no-eval: 0 */
import { useTranslation } from "react-i18next";
import { DataGrid, ptBR, enUS as en, esES as es } from "@mui/x-data-grid";
import { Localization } from "@mui/x-data-grid/utils/getGridLocalization";

const languages: { [language: string]: Localization | string } = {
  ptbr: ptBR,
  en,
  es,
};

const DataGridWrapper: typeof DataGrid = (props) => {
  const { i18n } = useTranslation();

  return (
    <DataGrid
      {...props}
      localeText={
        eval(languages[i18n.language] as string).components.MuiDataGrid
          .defaultProps.localeText
      }
      sx={{
        cursor: "pointer",
      }}
      autoHeight
    />
  );
};

export default DataGridWrapper;
