import { t } from "i18next";
import { ReactNode } from "react";
import MyModal from "../../../components/MyModal";
import { ISpreadsheet } from "../../../interfaces/ISpreadsheet";
import AmarokComLimiteKm from "./amarokComLimiteKm";
import { ChassisComExcecao } from "./chassisComExcecao";
import GarantiaEstentida from "./garantiaEstendida";
import GarantiaPadrao from "./garantiaPadrao";
import ListaDeExcecoes from "./listaDeExcecoes";
import Revalidacao from "./revalidacao";
import Revisao from "./revisao";
import RevisaoExcecao from "./revisaoExcecao";
import RevisoesPlanejadas from "./revisoesPlanejadas";
import SemGarantia from "./semGarantia";
import Id4WithoutDSP from "./id4SemDSP";

interface Props {
  modalType: string;
  onClose: () => void;
  spreadsheet?: ISpreadsheet;
}

export default function Modals({ modalType, onClose, spreadsheet }: Props) {
  const d = "digitalServicePlan";
  const modals: {
    [type: string]: { modal: ReactNode; width: string; title: string };
  } = {
    "Sem Garantia": {
      modal: <SemGarantia spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsVehiclesWithoutWarranty`)}`,
    },
    "Garantia estendida": {
      modal: <GarantiaEstentida spreadsheet={spreadsheet} />,
      width: "80%",
      title: `${t(`${d}.optionsExtendedWarranty`)}`,
    },
    Revalida: {
      modal: <Revalidacao spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsRevalidation`)}`,
    },
    "Revisão planejada": {
      modal: <RevisoesPlanejadas spreadsheet={spreadsheet} />,
      width: "80%",
      title: `${t(`${d}.optionsPlannedRevision`)}`,
    },
    "Revisão de série": {
      modal: <Revisao spreadsheet={spreadsheet} />,
      width: "80%",
      title: `${t(`${d}.optionsSeriesRevision`)}`,
    },
    "Garantia padrão": {
      modal: <GarantiaPadrao spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsWarrantySealStandard`)}`,
    },
    "Garantia lista de exceções": {
      modal: <ListaDeExcecoes spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsWarrantySealExceptionList`)}`,
    },
    "Garantia chassis com exceções": {
      modal: <ChassisComExcecao spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsWarrantySealChassisWithException`)}`,
    },
    "Garantia amarok exceções": {
      modal: <AmarokComLimiteKm spreadsheet={spreadsheet} />,
      width: "80%",
      title: `${t(`${d}.optionsWarrantySealKmLimit`)}`,
    },
    "Revisão de série exceção": {
      modal: <RevisaoExcecao spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsSeriesRevisionExceptions`)}`,
    },
    "Sem Garantia - Elétrico": {
      modal: <Id4WithoutDSP spreadsheet={spreadsheet} />,
      width: "50%",
      title: `${t(`${d}.optionsIdFourWithoutDSP`)}`,
    },
  };

  const { modal, width, title } = modals[modalType];
  return (
    <MyModal width={width} title={title} modalChange={onClose}>
      {modal}
    </MyModal>
  );
}
