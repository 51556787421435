import { AlertColor, Box, Button, Grid, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import BoxContent from "../boxContent";
import DataGridApi from "../dataGridApi";
import ModalConfirmation from "../MyModal/modalConfirmation";
import "./dataGrid.css";

export default function VehicleStatus() {
  const { t } = useTranslation();
  const {
    user,
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [openUserModal, setOpenUserModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmVersion, setShowConfirmVersion] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState<any>();
  const [decline, setDecline] = useState<number>();
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [visibleAlertModel, setVisibleAlertModel] = useState<boolean>(false);
  const [visibleAlertVersion, setVisibleAlertVersion] =
    useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [messageQuickAlertModel, setMessageQuickAlertModel] = useState("");
  const [messageQuickAlertVersion, setMessageQuickAlertVersion] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [severityAlertModel, setSeverityAlertModel] =
    useState<AlertColor>("success");
  const [severityAlertVersion, setSeverityAlertVersion] =
    useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [titleMessageModel, setTitleMessageModel] = useState("");
  const [titleMessageVersion, setTitleMessageVersion] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);
  const [dataUpdatedModel, setDataUpdatedModel] = useState(0);
  const [dataUpdatedVersion, setDataUpdatedVersion] = useState(0);

  const watchDataChange = (succesMessage?: string, type?: number) => {
    const dataChange = dataUpdated + 1;
    if (type === 0) {
      setDataUpdated(dataChange);
    } else if (type === 1) {
      setDataUpdatedModel(dataChange);
    } else if (type === 2) {
      setDataUpdatedVersion(dataChange);
    }

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const messageErrorModel = (errorMessage?: string) => {
    setVisibleAlertModel(true);
    setTimeout(() => {
      setVisibleAlertModel(false);
    }, 3000);

    setTitleMessageModel("Error");
    setMessageQuickAlertModel(
      errorMessage ? errorMessage : "There was an error",
    );
    setSeverityAlertModel("error");
  };

  const messageErrorVersion = (errorMessage?: string) => {
    setVisibleAlertVersion(true);
    setTimeout(() => {
      setVisibleAlertVersion(false);
    }, 3000);

    setTitleMessageVersion("Error");
    setMessageQuickAlertVersion(
      errorMessage ? errorMessage : "There was an error",
    );
    setSeverityAlertVersion("error");
  };

  const changeStatusVehicle = async (newStatus: string, vehicleId: string) => {
    try {
      await axios.put(
        `${CONFIG.API_URL}vehicles/status?countryCode=${countryCode}&id=${vehicleId}&status=${newStatus}`,
        {},
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("terms.Term edited"), 0);
  };

  const confirmDecline = async () => {
    if (vehicleInfo.status === "WAITING_FOR_APPROVAL_REMOVE") {
      changeStatusVehicle("APPROVED", vehicleInfo.id);
    } else {
      try {
        await axios.delete(
          `${CONFIG.API_URL}vehicles/${vehicleInfo.id}`,
          tokenHeaders,
        );
        await axios.put(
          `${CONFIG.API_URL}vehicles/status?countryCode=${countryCode}&id=${vehicleInfo.id}&status=DISAPPROVED`,
          {},
          tokenHeaders,
        );
      } catch (error: any) {
        return messageError(error.response.data.message);
      }
      watchDataChange(t("terms.Term edited"), 0);
    }
  };

  const deleteVehicle = async (id: string) => {
    try {
      await axios.delete(`${CONFIG.API_URL}vehicles/${id}`, tokenHeaders);
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("models.modelDeleted"), 0);
  };

  const handleVehicleStatus = async (params: any, statusId: number) => {
    console.log("params", params);
    console.log("statusId", statusId);

    if (
      params &&
      params.status &&
      params.status === "WAITING_FOR_APPROVAL_REMOVE"
    ) {
      // remove vehicle
      const vehicle = params;
      vehicle.status = "DISAPPROVED";
      setShowConfirmDelete(true);
      setVehicleInfo(vehicle);
      setTitleMessage(t("home.confirmVehicleRemove"));
      setDecline(3);
    } else {
      let newStatus;

      if (statusId === 2) {
        newStatus = "APPROVED";
      } else {
        newStatus = "DISAPPROVED";
      }

      console.log(`tokenHeaders`, tokenHeaders);
      changeStatusVehicle("APPROVED", params.id);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("home.vehicle"),
      flex: 1,
      cellClassName: (params) => {
        const status = params.row.status;
        // Use condicionais para atribuir a classe apropriada com base no status
        if (status === "WAITING_FOR_APPROVAL_REMOVE") {
          return "cell-aproval-remove";
        }
        return ""; // Retorna uma string vazia para outras situações
      },
    },
    {
      field: "id",
      headerName: "",
      width: 410,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              id={params.value}
              onClick={() => {
                handleVehicleStatus(params.row, 2);
              }}
              disabled={
                user.profile.profileName === "ADMINISTRATOR" ? false : true
              }
              style={{ width: "189px" }}
            >
              {params.row.status === "WAITING_FOR_APPROVAL_REMOVE"
                ? t("home.approveDeletion")
                : t("home.approve")}
            </Button>
            <Button
              color="error"
              variant="contained"
              id={params.value}
              onClick={() => {
                setShowConfirmDelete(true);
                console.log("vehicleinfo", params.row, 3);
                setTitleMessage(t("home.declineVehicle"));
                setVehicleInfo(params.row);
                setDecline(3);
                // handleVehicleStatus(params.row, 3);
              }}
              disabled={
                user.profile.profileName === "ADMINISTRATOR" ? false : true
              }
              style={{ width: "189px" }}
            >
              {params.row.status === "WAITING_FOR_APPROVAL_REMOVE"
                ? t("home.declineDeletion")
                : t("home.decline")}
            </Button>
          </Box>
        ) : (
          ""
        ),
      cellClassName: (params) => {
        const status = params.row.status;
        // Use condicionais para atribuir a classe apropriada com base no status
        if (status === "WAITING_FOR_APPROVAL_REMOVE") {
          return "cell-aproval-remove";
        }
        return ""; // Retorna uma string vazia para outras situações
      },
    },
  ];

  const disapproveVehicleAndVersion = async (body: any, type: number) => {
    try {
      await axios.post(
        `${CONFIG.API_URL}vehicles/integration/modelVersion/disapprove`,
        body,
        tokenHeaders,
      );
    } catch (error: any) {
      if (type === 1) {
        return messageErrorModel(error.response.data.message);
      }
      return messageErrorVersion(error.response.data.message);
    }
    watchDataChange(t("models.disapprove"), type);
  };

  const approveVehicleAndVersion = async (body: any, type: number) => {
    try {
      await axios.post(
        `${CONFIG.API_URL}vehicles/integration/modelVersion/approve?email=${user.email}&countryCode=${countryCode}`,
        body,
        tokenHeaders,
      );
    } catch (error: any) {
      if (type === 1) {
        return messageErrorModel(error.response.data.message);
      }
      return messageErrorVersion(error.response.data.message);
    }
    watchDataChange(t("models.approve"), type);
  };

  const columnsVehicle: GridColDef[] = [
    {
      field: "modelName",
      headerName: t("home.vehicle"),
      flex: 1,
    },
    {
      field: "dateTimeRequest",
      headerName: t("home.dateCreate"),
      flex: 1,
    },
    {
      field: "id",
      headerName: t("home.action"),
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <Grid container>
            <Grid item xs={6}>
              <IconButton
                color="primary"
                onClick={(event) => {
                  approveVehicleAndVersion(
                    {
                      id: params.row.id,
                    },
                    1,
                  );
                }}
              >
                <CheckIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                color="error"
                onClick={(event) => {
                  disapproveVehicleAndVersion(
                    {
                      id: params.row.id,
                    },
                    1,
                  );
                }}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          ""
        ),
    },
  ];

  const columnsVersions: GridColDef[] = [
    {
      field: "vehicleVersionName",
      headerName: t("home.vehicleVersion"),
      flex: 1,
    },
    {
      field: "modelCode",
      headerName: t("home.modelCode"),
      flex: 1,
    },
    {
      field: "modelYear",
      headerName: t("home.year"),
      flex: 1,
    },
    {
      field: "dateTimeRequest",
      headerName: t("home.dateCreate"),
      flex: 1,
      type: "date",
    },
    {
      field: "id",
      headerName: t("home.action"),
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <Grid container>
            <Grid item xs={6}>
              <IconButton
                color="primary"
                onClick={(event) => {
                  approveVehicleAndVersion(
                    {
                      id: params.row.id,
                      modelCode: params.row.modelCode,
                    },
                    2,
                  );
                }}
              >
                <CheckIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                color="error"
                onClick={(event) => {
                  disapproveVehicleAndVersion(
                    {
                      id: params.row.id,
                      modelCode: params.row.modelCode,
                    },
                    2,
                  );
                }}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          ""
        ),
    },
  ];

  return (
    <Grid>
      <BoxContent title={t("home.waitingVehicles")}>
        <p style={{ fontSize: 12 }}>{t("home.approvalDisclaimer")}</p>
        <DataGridApi
          endPoint={`vehicles/status?countryCode=${countryCode}&status=0&`}
          dataUpdated={dataUpdated}
          messageError={messageError}
          columnsData={columns}
          onEmptyMessage={t("home.emptyVehicles")}
        />
        {showConfirmDelete && (
          <ModalConfirmation
            openDelete={setShowConfirmDelete}
            confirmDelete={confirmDecline}
            title={titleMessage}
          />
        )}
      </BoxContent>
      <BoxContent title={t("home.waitingImportVehicles")}>
        <p style={{ fontSize: 12 }}>{t("home.approvalDisclaimer")}</p>
        <DataGridApi
          endPoint={`vehicles/integration/model/list?countryCode=${countryCode}&`}
          dataUpdated={dataUpdatedModel}
          messageError={messageErrorModel}
          columnsData={columnsVehicle}
          onEmptyMessage={t("home.emptyVehicles")}
        />
        <p style={{ fontSize: 12 }}>{t("home.approvalDisclaimerVersion")}</p>
        <DataGridApi
          endPoint={`vehicles/integration/version/list?countryCode=${countryCode}&`}
          dataUpdated={dataUpdatedVersion}
          messageError={messageErrorVersion}
          columnsData={columnsVersions}
          onEmptyMessage={t("home.emptyVersion")}
        />
        {showConfirmVersion && (
          <ModalConfirmation
            openDelete={setShowConfirmVersion}
            confirmDelete={confirmDecline}
            title={titleMessage}
          />
        )}
      </BoxContent>
    </Grid>
  );
}
