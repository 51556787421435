import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import ActionButtons from "../MyModal/ActionButtons";
import Vehicle from "./vehicle";

import { AuthContext } from "../../contexts/AuthContext";
import { IVehicle } from "../../interfaces/IVehicle";
import { CONFIG } from "../../services/config";
// import { IModel } from "../../interfaces/IModel";

export default function AddModel({
  watchDataChange,
  messageError,
  data,
  addFormData,
}: any) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [disableActionButton, setDisableActionButton] = useState(false);

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<IVehicle | any>({
    mode: "onBlur",
    resolver: yupResolver(addFormData),
    defaultValues: data,
  });

  // simulate a load from the server

  const addVersion: SubmitHandler<IVehicle> = async (values) => {
    // Add interface IModel
    console.log("values", values);
    // console.log("values", values.versions.length);

    if (values.teaserMediaId === "") {
      return alert("Adicione um teaser");
    }

    for (let i = 0; i < values.versions.length; i++) {
      const element = values.versions[i];
      console.log("element", element);
      for (let index = 0; index < element.version.length; index++) {
        const versionImage = element.version[index];
        console.log("versionImage", versionImage);
        if (versionImage.name === "") {
          return alert(t("models.noImageVersion"));
        }
      }
    }

    for (let index = 0; index < values.versions.length; index++) {
      const element = values.versions[index];

      if (element.version.length > 1 && element.isCognitive === "false") {
        return alert(t("models.onlyOneImage"));
      }
      if (element.version.length <= 0) {
        return alert(t("models.noImageVersion"));
      }
    }

    setDisableActionButton(true);

    try {
      await axios.post(
        `${CONFIG.API_URL}vehicles`,
        {
          countryCode,
          extension: values.extension,
          teaser: values.teaserMediaId,
          vehicleName: values.name,
          backgroundColor: values.backgroundColor,
          versions: values.versions,
        },
        tokenHeaders,
      );
      setDisableActionButton(false);
    } catch (error: any) {
      setDisableActionButton(false);
      return messageError(error.response.data.message);
    }
    watchDataChange(t("models.modelAdded"));
  };

  const methods = useFormContext();

  return (
    <FormProvider
      {...methods}
      reset={reset}
      register={register}
      control={control}
    >
      <Box component="form" onSubmit={handleSubmit(addVersion)}>
        <Vehicle errors={errors} data={data} />

        <ActionButtons
          saveName={t("button.Add")}
          typeButton="submit"
          disableSave={disableActionButton ? true : false}
        />
      </Box>
    </FormProvider>
  );
}
