import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { t } from "i18next";
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { ICheckGroupOption } from "../../interfaces/IChekGroupOption";

interface Props {
  options: ICheckGroupOption[];
  filter: {
    filterLabel?: string;
    filterOptions?: { value: any; label: string }[];
    filterFunction?: (value: any, option: ICheckGroupOption) => boolean;
  };
  setStateOptions: Dispatch<SetStateAction<ICheckGroupOption[]>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

export default function CheckGroup({
  options,
  filter,
  setStateOptions,
  error,
  setError,
}: Props) {
  const { filterFunction = () => true, filterLabel, filterOptions } = filter;
  const [allChecked, setAllChecked] = useState<boolean>(false);

  useEffect(() => {
    setAllChecked(
      options.length === options.filter(({ checked }) => checked).length,
    );
  }, [options]);

  const toggleAll = ({ target }: SyntheticEvent<Element, Event>) => {
    const { checked } = target as HTMLInputElement;
    const newOptions = [...options];
    newOptions.forEach(({ visible }, index) => {
      if (visible) {
        newOptions[index].checked = checked;
      }
    });
    setAllChecked(checked);
    setStateOptions(newOptions);
  };

  const toggle = (
    { target }: SyntheticEvent<Element, Event>,
    label: string,
  ) => {
    const { checked } = target as HTMLInputElement;
    const optionIndex = options.findIndex(
      ({ label: currentLabel }) => label === currentLabel,
    );
    const newOptions = [...options];
    newOptions[optionIndex].checked = checked;
    setStateOptions(newOptions);
    if (newOptions.filter(({ checked }) => checked).length) {
      setError("");
    } else {
      setError(t("ownersManual.versionRequiredError"));
    }
  };

  const applyFilter = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const newOptions = options.map((option) => ({
      ...option,
      visible: filterFunction(value, option) || value === "todos",
    }));
    setStateOptions(newOptions);
    const { length: visibleLength } = newOptions.filter(
      ({ visible }) => visible,
    );
    const { length: checkedLength }: any = newOptions.filter(
      ({ checked, visible }) => checked && visible,
    );
    setAllChecked(visibleLength === checkedLength);
  };

  console.log(`optionsoptions`, options);

  return (
    <FormGroup>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          checked={allChecked}
          label="Todos"
          onChange={toggleAll}
        />
        {filterLabel && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ marginRight: "1em" }}>{filterLabel}</p>
            <FormControl>
              <Select
                id="slcFilter"
                defaultValue="todos"
                name="slcFilter"
                aria-label="Selecione um filtro"
                sx={{ height: 35, width: 100 }}
                onChange={applyFilter}
              >
                <MenuItem value="todos" aria-label="todos">
                  Todos
                </MenuItem>
                {filterOptions?.map(({ value, label }) => (
                  <MenuItem value={value} aria-label={label} key={label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          maxHeight: 510,
          width: 450,
          overflow: "auto",
        }}
      >
        {options
          .filter(({ visible }) => visible)
          .map(({ label, checked, value }) => {
            return (
              <FormControlLabel
                key={value}
                control={
                  <Checkbox
                    id={`chk${label}`}
                    checked={checked}
                    onChange={(event) => toggle(event, label)}
                  />
                }
                label={label}
              />
            );
          })}
      </div>
      {error && (
        <FormHelperText
          error
          style={{
            marginTop: ".25rem",
            marginLeft: 0,
            fontSize: 14,
            color: "#ff0000",
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormGroup>
  );
}
