import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FormHelperText } from "@mui/material";
import { t } from "i18next";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

interface Props {
  error?: string;
  setError?: Dispatch<SetStateAction<string>>;
  file: File;
  setFile: Dispatch<SetStateAction<File>>;
  changeFileManualBase64: (param: Blob) => void;
}

export default function UploadPdf({
  error,
  file,
  setFile,
  changeFileManualBase64,
  setError = () => {},
}: Props) {
  const [fileName, setFileName] = useState(
    t("ownersManual.fileTypeField").toString(),
  );

  const [opacity, setOpacity] = useState(1);

  const changePdf = (filelist: FileList | []) => {
    const files = Array.from(filelist);
    if (files?.length) {
      const [file] = files;
      if (file?.type !== "application/pdf") {
        alert(t("ownersManual.fileTypeField"));
      } else {
        setFileName(file.name);
        setFile(file);
        changeFileManualBase64(file);
      }
    }
  };

  useLayoutEffect(() => {
    if (file) {
      const fileInput = document.querySelector("#inputPdf") as HTMLInputElement;
      const dataTransfer = new DataTransfer();
      const presentFile = new File([file.slice(0, file.size)], file.name, {
        type: "application/pdf",
      });
      dataTransfer.items.add(presentFile);
      fileInput.files = dataTransfer.files;
      setFileName(file.name ? file.name : t("ownersManual.fileTypeField"));
    }
  }, [file]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: 100,
          height: 100,
          border: "1px solid #e5e5e5",
          borderRadius: "4px",
          padding: ".5rem",
          opacity,
        }}
        htmlFor="inputPdf"
      >
        <PictureAsPdfIcon sx={{ marginBottom: ".5rem" }} />
        <span
          style={{
            fontSize: "11px",
            textAlign: "center",
            wordBreak: "break-all",
          }}
        >
          {fileName}
        </span>
        <input
          type="file"
          id="inputPdf"
          accept="application/pdf"
          onChange={($event) => changePdf($event.target.files || [])}
          style={{
            display: "block",
            width: "100%",
            height: "100%",
            opacity: 0,
            position: "absolute",
          }}
          onDragOver={() => setOpacity(0.5)}
          onDragLeave={() => setOpacity(1)}
          onDrop={($event) => {
            $event.preventDefault();
            setOpacity(1);
            changePdf($event.dataTransfer.files);
          }}
        />
      </label>
      <FormHelperText
        style={{
          marginTop: ".25rem",
          marginLeft: 0,
          fontSize: 14,
          color: "#ff0000",
        }}
        error={!!error}
      >
        {error}
      </FormHelperText>
    </div>
  );
}
