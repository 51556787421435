import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Box, Switch, FormControlLabel, Button, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";
import { FormInputText } from "../../form/FormInputText";
import ActionButtons from "../../MyModal/ActionButtons";
import { ICategory } from "../../../interfaces/ICategory";
import ModalConfirmation from "../../MyModal";
import { LabelForm, LabelContainer } from "../Form/label";

type FormValues = {
  id: number;
  name: string;
  description: string;
  active: boolean;
  image: string;
  createdBy: string;
};

export default function EditCategory({
  data,
  watchDataChange,
  messageError,
}: ICategory) {
  const { t } = useTranslation();
  yup.setLocale({
    mixed: {
      required: t(`yup.mixed.required`),
      notType: t(`yup.mixed.notType`),
    },
    string: {
      min: t(`yup.string.min`),
      max: t(`yup.string.max`),
      email: t(`yup.string.email`),
    },
    number: {
      min: t(`yup.number.min`),
      max: t(`yup.number.max`),
      positive: t(`yup.number.positive`),
      integer: t(`yup.number.integer`),
    },
    date: {
      min: t(`yup.date.min`),
      max: t(`yup.date.max`),
    },
    array: {
      min: t(`yup.array.min`),
      max: t(`yup.array.max`),
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [openModalExisting, setOpenModalExisting] = useState(false);
  const [confirmeInactive, setConfirmInactive] = useState(false);
  const {
    tokenHeaders,
    country: { countryCode },
    user: { email },
  } = useContext(AuthContext);

  const [imageBase64, setImageBase64] = useState<string | null>(null);

  const addFormData = yup.object().shape({
    name: yup
      .string()
      .max(45)
      .required(t("faq.category.Required name"))
      .label(t("faq.category.Name")),
    description: yup
      .string()
      .max(45)
      .required(t("faq.category.Required description"))
      .label(t("faq.category.Description")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(addFormData),
  });

  const convertImageToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      event.target.value = "";
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result as string;
      setImageBase64(base64String.split(",")[1]);
    };

    reader.readAsDataURL(file);
  };

  const editCategory: SubmitHandler<FormValues> = async (values) => {
    try {
      const image = imageBase64
        ? {
            image: imageBase64,
          }
        : {};
      await axios.put(
        `${CONFIG.API_URL}faq/category/${data?.id}`,
        {
          ...image,
          name: values.name,
          description: values.description,
          active: values.active,

          updatedBy: email,
        },
        tokenHeaders,
      );

      watchDataChange(t("faq.category.Category edited"));
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return messageError(t("faq.category.category_exists"));
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
  };

  const handleConfirmInactivation = () => {
    setConfirmInactive(true);
    setOpenModal(false);
  };
  const handleInactiveStatus = (event: any) => {
    if (!confirmeInactive && data?.active && !event.target.checked) {
      setOpenModal(true);
    }
  };
  const handleCancelInactivation = () => {
    setValue("active", true);
    setOpenModal(false);
    setConfirmInactive(false);
  };
  const deleteCategory = async () => {
    try {
      await axios.delete(`${CONFIG.API_URL}faq/category/${data?.id}`, {
        headers: tokenHeaders.headers,
        data: null,
      });
    } catch (error: any) {
      setOpenModalExisting(true);
      return;
      // return messageError(error.response.data.message);
    }
    watchDataChange(t("faq.category.Category deleted"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editCategory)}>
      <LabelContainer>
        <LabelForm
          required
          label={t("faq.category.Name")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "45",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.category.Name")}
            error={errors.name}
            {...register("name")}
            defaultValue={data?.name}
          />
        </LabelForm>

        <LabelForm
          required
          label={t("faq.category.Description")}
          requires={[
            {
              msg: t("faq.category.labels.description"),
            },
            {
              msg: t("faq.requires.max_characters"),
              value: "45",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.category.Description")}
            error={errors.description}
            {...register("description")}
            defaultValue={data?.description}
          />
        </LabelForm>

        <LabelForm
          required
          label="Ícone"
          requires={[
            {
              msg: t("faq.requires.recommended_dimension"),
              value: "32 x 32",
            },
            {
              msg: t("faq.requires.image_type"),
              value: "JPEG",
            },
            {
              msg: t("faq.requires.maximum_size"),
              value: "5 MB",
            },
          ]}
        >
          <Grid container spacing={2} columns={{ xs: 6, sm: 8, md: 12 }}>
            <Grid item xs={6}>
              {/* <span>{t("faq.category.UploadImage")}</span> */}

              <input
                accept=".png, .jpg, .jpeg"
                type="file"
                {...register("image")}
                onChange={convertImageToBase64}
              />
            </Grid>
            <Grid item xs={6} bgcolor="#fff">
              <Box display="flex" alignItems="center">
                <p>Imagem atual:</p>
                <Box
                  display="flex"
                  alignItems="center"
                  ml="16px"
                  bgcolor="#fff"
                >
                  {imageBase64 ? (
                    <img
                      src={`data:image/png;base64,${imageBase64}`}
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                      alt="icon"
                    />
                  ) : data?.image ? (
                    <img
                      src={`${data.image}`}
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                      alt="icon"
                    />
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
          {errors.image && (
            <Box
              sx={{
                color: "var(--red-100)",
                marginBottom: 3,
              }}
            >
              <span>{t("alert.imageRequired")}</span>
            </Box>
          )}
        </LabelForm>

        <LabelForm label={t("faq.category.Active")}>
          <FormControlLabel
            control={
              <Controller
                name="active"
                control={control}
                defaultValue={data?.active}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInactiveStatus(e);
                    }}
                  />
                )}
              />
            }
            label=""
          />
        </LabelForm>
      </LabelContainer>

      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteCategory}
        titleConfirm={t("faq.category.DeleteCategory")}
      />

      {openModal && (
        <ModalConfirmation
          title={t("faq.category.modal.edit.title")}
          modalChange={setOpenModal}
        >
          <Box style={{ padding: "0 32px" }}>
            <p style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
              {t("faq.category.modal.edit.description")}
            </p>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Button variant="contained" onClick={handleConfirmInactivation}>
                {t("faq.category.modal.edit.buttonTitle")}
              </Button>
              <Button
                color="primary"
                style={{
                  color: "#2196f3",
                }}
                variant="outlined"
                onClick={handleCancelInactivation}
              >
                {t("faq.category.modal.edit.buttonTitleCancel")}
              </Button>
            </Box>
          </Box>
        </ModalConfirmation>
      )}
      {openModalExisting && (
        <ModalConfirmation
          title={t("faq.category.modal.confirmation.title")}
          modalChange={setOpenModalExisting}
        >
          <Box style={{ padding: "0 32px" }}>
            <p style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
              {t("faq.category.modal.confirmation.description")}
            </p>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => setOpenModalExisting(false)}
              >
                {t("faq.category.modal.confirmation.ok")}
              </Button>
            </Box>
          </Box>
        </ModalConfirmation>
      )}
    </Box>
  );
}
