import { Box, Grid, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import useCookies from "../../services/CookieService";
import { updateUserToken } from "../../utils/vwidService";

import Header from "../header";
import Menu from "../menu";
import { Unauthorized } from "../unauthorized";
import { LoadingSpinning } from "../loadingSpinning";

export default function Layout() {
  const { signIn, tokenHeaders, user, setTokenHeaders, loading } =
    useContext(AuthContext);
  const { findCookie } = useCookies();
  const navigate = useNavigate();
  const params = useParams();

  const url = window.location.href;
  const urlParse = url.replace("#", "?&");

  const accessToken = new URLSearchParams(urlParse).get("access_token") || "";
  const idToken = new URLSearchParams(urlParse).get("id_token") || "";
  const code = new URLSearchParams(urlParse).get("code") || "";
  console.log("🚀 ~ file: index.tsx:25 ~ Layout ~ code", code);
  const expiresIn = new URLSearchParams(urlParse).get("expires_in") || "";

  const userLogged = findCookie("isLogged");

  useEffect(() => {
    const verifyLogin = () => {
      console.log(`Verificando login ${new Date().toLocaleString("pt-BR")}`);
      const token = findCookie("accessToken");
      const loginStrategy = [
        {
          condition: !accessToken && !token && userLogged?.value !== "logged",
          action() {
            navigate("welcome");
          },
        },
        {
          condition: !userLogged,
          action() {
            signIn({ accessToken, idToken, code, expiresIn });
          },
        },
        {
          condition: !token && userLogged,
          action() {
            updateUserToken(setTokenHeaders);
          },
        },
      ];
      loginStrategy.find(({ condition }) => condition)?.action();
    };
    verifyLogin();
    const time = 5 * 60 * 1000;
    const interval = setInterval(() => {
      verifyLogin();
    }, time);

    return () => clearInterval(interval);
  });

  const matches = useMediaQuery("(min-width:900px)");

  if (window.location.pathname === "/") {
    navigate("home");
  }

  if (matches) {
    return (
      <>
        <Header />

        <Container sx={{ maxWidth: "1250px !important" }}>
          {loading ? (
            <LoadingSpinning />
          ) : user ? (
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Menu />
              </Grid>
              <Grid item xs={9} sm>
                <Box>
                  <Outlet />
                </Box>
              </Grid>
            </Grid>
          ) : (
            !user && <Unauthorized />
          )}
        </Container>
      </>
    );
  }

  return (
    <>
      <Header />

      <Container>
        {user ? (
          <Box>
            {user && (
              <Box>
                <Outlet />
              </Box>
            )}
            {/* <Outlet /> */}
          </Box>
        ) : (
          <p>Usuario sem permissao</p>
        )}
      </Container>
    </>
  );
}
