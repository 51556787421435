import { Box, CircularProgress } from "@mui/material";
import { keyframes } from "@mui/system";

export function LoadingSpinning() {
  return (
    <Box
      sx={{
        marginTop: "100px",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={50} />
      </Box>
    </Box>
  );
}
