import { Box, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { ITerms } from "../../interfaces/ITerms";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

export default function AddTerms({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [archive, setArchive] = useState<any>();
  const [messageValidatePDF, setMessageValidatePDF] = useState(false);

  const addFormData = yup.object().shape({
    title: yup.string().required(t("terms.Required title")),
    active: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ITerms>({ resolver: yupResolver(addFormData) });

  const validatePDF = async (event: any) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(pdf)$/)) {
      alert(t("terms.validatePDF"));
      const pdfTemplate = document.querySelector(
        "#pdfTemplate",
      ) as HTMLInputElement;
      pdfTemplate.value = "";
      return false;
    }

    setArchive(file);
  };

  const addTerms: SubmitHandler<ITerms> = async (values) => {
    console.log("values", values);

    if (!archive) {
      setMessageValidatePDF(true);
      return setTimeout(() => {
        setMessageValidatePDF(false);
      }, 5000);
    }

    const { authorization, idToken, code, countryCode } = tokenHeaders.headers;
    const newHeader = {
      headers: {
        authorization,
        idToken,
        code,
        countryCode,
        title: values.title,
        active: values.active,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await axios.post(
        `${CONFIG.API_URL}termofuse/vwapp`,
        {
          file: archive,
        },
        newHeader,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("terms.Term added"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(addTerms)}>
      <FormInputText
        label={t("terms.title")}
        error={errors.title}
        {...register("title")}
        inputProps={{
          maxLength: 40,
        }}
      />
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={9}>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnArchive")}</span>
            <input
              accept=".pdf"
              type="file"
              name="fileInput"
              id="pdfTemplate"
              onChange={(event) => {
                validatePDF(event);
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <FormGroup sx={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Switch id="addActive" {...register("active")} defaultChecked />
              }
              label={t("countries.Active")}
            />
          </FormGroup>
        </Grid>
      </Grid>

      {messageValidatePDF && (
        <p style={{ color: "var(--red-100)" }}>{t("terms.validatePDF")}</p>
      )}

      <ActionButtons saveName={t("button.Add")} typeButton="submit" />
    </Box>
  );
}
