import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { AuthContext } from "../../contexts/AuthContext";
import { ICountry } from "../../interfaces/ICountry";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";
import { IModule } from "../../interfaces/IModule";

export default function AddCountry({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [addCheckBox, setAddCheckBox] = useState<string[]>([]);

  const [moduleArray, setModuleArray] = useState<IModule[]>([]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${CONFIG.API_URL}countryModules`, tokenHeaders)
        .then((response) => {
          setModuleArray(response.data.content);
        })
        .catch(() => {
          console.log("erro");
        });
    }, 500);
  }, []);

  const ALPHA_REGEX = /^[a-zA-Z-\s]+$/;

  const addFormData = yup.object().shape({
    country: yup.string().required(t("countries.Required country")),
    countryCode: yup.string().required(t("countries.Required country code")),
    prefix: yup.string().required(t("countries.Required prefix")),
    countryGroup: yup.string().required(t("countries.Required country group")),
    active: yup.boolean(),
  });

  const { register, handleSubmit, reset, formState, clearErrors } =
    useForm<ICountry>({
      resolver: yupResolver(addFormData),
    });

  const { errors } = formState;

  const [imageBase64, setImageBase64] = useState<any>(null);

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];
    const preview = document.getElementById("imageChosen") as HTMLImageElement;

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      setImageBase64(image[1]);
    };

    reader.readAsDataURL(file);
  };

  const addCountry: SubmitHandler<ICountry | FieldValues> = async (values) => {
    console.log("values", values);

    console.log("addCheckBox", addCheckBox);
    let updatedActive = 0;
    if (values.active) {
      updatedActive = 1;
    }

    try {
      await axios.post(
        `${CONFIG.API_URL}country`,
        {
          name: values.country,
          code: values.countryCode,
          prefix: values.prefix,
          cgroup: values.countryGroup,
          active: updatedActive,
          documentImg: imageBase64,
          modules: addCheckBox,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    watchDataChange(t("countries.Country added"));
  };

  useEffect(() => {
    clearErrors();
    reset();
    setImageBase64("");
  }, []);

  const handleAddCheckBox = (event: any) => {
    if (event.target.checked) {
      const module = event.target.value;
      setAddCheckBox((current) => [...current, ...[module]]);
    } else {
      const module = event.target.value;
      addCheckBox.map((name) => {
        if (name === module) {
          const array = addCheckBox;
          const index = array.indexOf(module);
          if (index !== -1) {
            array.splice(index, 1);
            setAddCheckBox(array);
          }
        }
        return addCheckBox;
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(addCountry)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            label={t("countries.Country name")}
            error={errors.country}
            {...register("country")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
            }}
            label={t("countries.Country code")}
            error={errors.countryCode}
            {...register("countryCode")}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInputText
            type="number"
            inputProps={{
              max: 999,
            }}
            label={t("countries.Prefix")}
            error={errors.prefix}
            {...register("prefix")}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 3,
            }}
            label={t("countries.Country group")}
            error={errors.countryGroup}
            {...register("countryGroup")}
          />
        </Grid>

        <Grid item xs={6}>
          <input
            accept=".png, .jpg, .jpeg"
            className="imageFile"
            type="file"
            name="fileInput"
            onChange={(event) => {
              convertImageToBase64(event);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormGroup sx={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Switch id="addActive" {...register("active")} defaultChecked />
              }
              label={t("countries.Active")}
            />
          </FormGroup>
        </Grid>

        {moduleArray.length > 0 ? (
          <Box sx={{ width: "100%", marginX: 2 }}>
            <h3>{t("countries.modules")}</h3>
            <Grid
              container
              rowSpacing={1}
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ paddingLeft: "1.5rem" }}
            >
              {moduleArray.map((item) => {
                return (
                  <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <FormControlLabel
                      control={<Checkbox />}
                      id={item.modulesTitle}
                      name={item.modulesTitle}
                      value={item.id}
                      onChange={(e) => handleAddCheckBox(e)}
                      label={item.modulesTitle}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <CircularProgress />
        )}

        <Box>
          {/* <Button variant="contained" type="submit" sx={{ marginLeft: "1rem" }}>
            {t("button.Add")}
          </Button> */}
        </Box>
      </Grid>
      <ActionButtons saveName={t("button.Add")} typeButton="submit" />
    </Box>
  );
}
