// import { Button } from "@mui/material";
import { AlertColor, Box, Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import BoxContent from "../../../components/boxContent";
import DataGridApi from "../../../components/dataGridApi";
import QuickAlert from "../../../components/form/quickAlert";
import MyModal from "../../../components/MyModal";

import EditQuestion from "../../../components/faq/question/editQuestion";

import AddNewQuestion from "../../../components/faq/question/addNewQuestion";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Question() {
  const { t } = useTranslation();
  const {
    country: { countryCode },
  } = useContext(AuthContext);
  const [openModalAddQuestion, setOpenModalAddQuestion] = useState(false);
  const [openModalEditQuestion, setOpenModalEditQuestion] = useState(false);

  const [question, setQuestionSelected] = useState<any>();

  const editQuestion = (e: any) => {
    setOpenModalEditQuestion(true);
    setQuestionSelected(e.row);
  };

  const handleClickModaAddQuestion = () => {
    setOpenModalAddQuestion(true);
  };

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddQuestion(false);
    setOpenModalEditQuestion(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const columns: GridColDef[] = [
    {
      field: "question",
      headerName: t("faq.question.colums.Question"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "answer",
      headerName: t("faq.question.colums.Response"),
      width: 200,
      headerClassName: "header-style",
      renderCell: (params: any) => {
        const textWithLineBreaks = params.value
          .replace(/<br\s*\/?>/gi, "\n") // Substitui <br> por \n
          .replace(/<\/?[^>]+>/g, "") // Remove outras tags HTML
          .trim();

        return <div>{textWithLineBreaks}</div>;
      },
    },
    {
      field: "category",
      headerName: t("faq.question.colums.Category"),
      width: 200,
      headerClassName: "header-style",
      renderCell: (params: any) => {
        return <span>{params.value.name}</span>;
      },
    },
    {
      field: "faqSystems",
      headerName: t("faq.question.colums.Channels"),
      width: 200,
      headerClassName: "header-style",
      renderCell: (params) => {
        const systemNames = params.value.map((item: any) => item.system);

        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {systemNames.map((item: any) => (
              <Chip
                key={item.id}
                label={item.name}
                style={{ color: "#fff", background: "var(--blue-400)" }}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "active",
      headerName: t("faq.question.colums.Status"),
      width: 100,
      headerClassName: "header-style",
      renderCell: (params) => <>{t(`faq.status.${params.value}`)}</>,
    },
  ];

  return (
    <BoxContent
      title={t("menu.faqquestion")}
      button
      titleButton={t("faq.question.Add")}
      handleClick={handleClickModaAddQuestion}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <Box
        sx={{
          marginBottom: 1,
        }}
      >
        <span style={{ fontSize: "16px" }}>
          {t("faq.question.manage_content")}
        </span>
      </Box>

      <DataGridApi
        onRowClick={editQuestion}
        dataUpdated={dataUpdated}
        messageError={messageError}
        endPoint="faq/faqs?"
        columnsData={columns}

        // params1="&question="
        // labelParams1={t("faq.question.colums.Question")}
        // params2="&answer="
        // labelParams2={t("faq.question.colums.Response")}
        // params3="&category="
        // labelParams3={t("faq.question.colums.Category")}
        // params4="&system="
        // labelParams4={t("faq.question.colums.Channels")}
      />

      {openModalAddQuestion && (
        <MyModal
          title={t("faq.question.Add")}
          modalChange={setOpenModalAddQuestion}
          width="900px"
        >
          <AddNewQuestion
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditQuestion && (
        <MyModal
          title={t("faq.question.Edit")}
          modalChange={setOpenModalEditQuestion}
          width="900px"
        >
          <EditQuestion
            data={question}
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
