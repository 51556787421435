import { Box, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IAutonomy } from "../../interfaces/IAutonomy";
import { IEditCalculator } from "../../interfaces/IEditCalculator";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

export default function EditCalculator({
  data,
  watchDataChange,
  messageError,
}: IEditCalculator) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const NUMERIC_REGEX = /^[0-9-\s]+$/;

  const addFormData = yup.object().shape({
    isFlexFuel: yup.string(),
    modelCode: yup.string(),
    modelYear: yup.string(),
    modelEdition: yup.string(),
    autonomyEthCity: yup
      .string()
      .required(t("calculator.Required autonomyEthCity")),
    autonomyEthRoad: yup
      .string()
      .required(t("calculator.Required autonomyEthRoad")),
    autonomyEthComb: yup
      .string()
      .required(t("calculator.Required autonomyEthComb")),
    autonomyGasCity: yup
      .string()
      .required(t("calculator.Required autonomyGasCity")),
    autonomyGasRoad: yup
      .string()
      .required(t("calculator.Required autonomyGasRoad")),
    autonomyGasComb: yup
      .string()
      .required(t("calculator.Required autonomyGasComb")),
    co2EthCity: yup.string().required(t("calculator.Required co2EthCity")),
    co2EthRoad: yup.string().required(t("calculator.Required co2EthRoad")),
    co2EthComb: yup.string().required(t("calculator.Required co2EthComb")),
    co2GasCity: yup.string().required(t("calculator.Required co2GasCity")),
    co2GasRoad: yup.string().required(t("calculator.Required co2GasRoad")),
    co2GasComb: yup.string().required(t("calculator.Required co2GasComb")),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAutonomy>({ resolver: yupResolver(addFormData) });

  const editAutonomy: SubmitHandler<IAutonomy> = async (values) => {
    console.log("values modelYear", values.modelYear);
    try {
      await axios.put(
        `${CONFIG.API_URL}calculator?id=${data?.id}`,
        {
          modelCode: data.modelCode,
          modelYear: data.modelYear,
          modelEdition: data.modelEdition,
          isFlexFuel: values.isFlexFuel,
          autonomyEthCity: values.autonomyEthCity,
          autonomyEthRoad: values.autonomyEthRoad,
          autonomyEthComb: values.autonomyEthComb,
          autonomyGasCity: values.autonomyGasCity,
          autonomyGasRoad: values.autonomyGasRoad,
          autonomyGasComb: values.autonomyGasComb,
          co2EthCity: values.co2EthCity,
          co2EthRoad: values.co2EthRoad,
          co2EthComb: values.co2EthComb,
          co2GasCity: values.co2GasCity,
          co2GasRoad: values.co2GasRoad,
          co2GasComb: values.co2GasComb,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("calculator.Autonomy edited"));
  };

  const deleteAutonomy = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}calculator?id=${data?.id}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("calculator.Autonomy deleted"));
  };

  const regexDecimal = (event: any) => {
    console.log("event", event);
    if (
      event.key === "Backspace" ||
      event.key === "Tab" ||
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "Delete" ||
      event.key === "."
    ) {
      return true;
    }
    if (!NUMERIC_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editAutonomy)}>
      <FormGroup sx={{ marginBottom: "1rem" }}>
        <FormControlLabel
          control={
            <Switch
              id="isFlexFuel"
              {...register("isFlexFuel")}
              defaultChecked={data.isFlexFuel ? true : false}
            />
          }
          label={t("calculator.isFlexFuel")}
        />
      </FormGroup>

      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.modelCode")}
            error={errors.modelCode}
            {...register("modelCode")}
            // inputProps={{
            //   maxLength: 6,
            //   minLength: 6,
            // }}
            defaultValue={data.modelCode}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.modelYear")}
            error={errors.modelYear}
            // inputProps={{
            //   maxLength: 4,
            //   minLength: 4,
            // }}
            // onKeyDown={(event: any) => {
            //   if (
            //     event.key === "Backspace" ||
            //     event.key === "Tab" ||
            //     event.key === "ArrowRight" ||
            //     event.key === "ArrowLeft" ||
            //     event.key === "Delete"
            //   ) {
            //     return true;
            //   }
            //   if (!NUMERIC_REGEX.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            {...register("modelYear")}
            defaultValue={data.modelYear}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.modelEdition")}
            error={errors.modelEdition}
            // inputProps={{
            //   maxLength: 1,
            // }}
            // onKeyDown={(event: any) => {
            //   if (
            //     event.key === "Backspace" ||
            //     event.key === "Tab" ||
            //     event.key === "ArrowRight" ||
            //     event.key === "ArrowLeft" ||
            //     event.key === "Delete"
            //   ) {
            //     return true;
            //   }
            //   if (!NUMERIC_REGEX.test(event.key)) {
            //     event.preventDefault();
            //   }
            // }}
            {...register("modelEdition")}
            defaultValue={data.modelEdition}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.autonomyEthCity")}
            error={errors.autonomyEthCity}
            onKeyDown={regexDecimal}
            {...register("autonomyEthCity")}
            defaultValue={data.autonomyEthCity}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.autonomyEthRoad")}
            error={errors.autonomyEthRoad}
            onKeyDown={regexDecimal}
            {...register("autonomyEthRoad")}
            defaultValue={data.autonomyEthRoad}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.autonomyEthComb")}
            error={errors.autonomyEthComb}
            onKeyDown={regexDecimal}
            {...register("autonomyEthComb")}
            defaultValue={data.autonomyEthComb}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.autonomyGasCity")}
            error={errors.autonomyGasCity}
            onKeyDown={regexDecimal}
            {...register("autonomyGasCity")}
            defaultValue={data.autonomyGasCity}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.autonomyGasRoad")}
            error={errors.autonomyGasRoad}
            onKeyDown={regexDecimal}
            {...register("autonomyGasRoad")}
            defaultValue={data.autonomyGasRoad}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.autonomyGasComb")}
            error={errors.autonomyGasComb}
            onKeyDown={regexDecimal}
            {...register("autonomyGasComb")}
            defaultValue={data.autonomyGasComb}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.co2EthCity")}
            error={errors.co2EthCity}
            onKeyDown={regexDecimal}
            {...register("co2EthCity")}
            defaultValue={data.co2EthCity}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.co2EthRoad")}
            error={errors.co2EthRoad}
            onKeyDown={regexDecimal}
            {...register("co2EthRoad")}
            defaultValue={data.co2EthRoad}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.co2EthComb")}
            error={errors.co2EthComb}
            onKeyDown={regexDecimal}
            {...register("co2EthComb")}
            defaultValue={data.co2EthComb}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.co2GasCity")}
            error={errors.co2GasCity}
            onKeyDown={regexDecimal}
            {...register("co2GasCity")}
            defaultValue={data.co2GasCity}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.co2GasRoad")}
            error={errors.co2GasRoad}
            onKeyDown={regexDecimal}
            {...register("co2GasRoad")}
            defaultValue={data.co2GasRoad}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputText
            label={t("calculator.co2GasComb")}
            error={errors.co2GasComb}
            onKeyDown={regexDecimal}
            {...register("co2GasComb")}
            defaultValue={data.co2GasComb}
          />
        </Grid>
      </Grid>

      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteAutonomy}
      />
    </Box>
  );
}
