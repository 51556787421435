function lineBreak(value: any) {
  const concat: any[] = [];
  if (value.length < 400) {
    concat.push({ scalar: value.replaceAll("\\n", "\n") });
  } else {
    const aux = value.match(/.{1,400}/g);
    let i = 0;
    for (i = 0; i < aux.length; i++) {
      concat.push({ scalar: aux[i].replaceAll("\\n", "\n") });
    }
  }
  return concat;
}

export default lineBreak;
