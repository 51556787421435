import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import lineBreak from "../../../utils/lineBreak";

export default function AppActionLinkResponse() {
  const { t } = useTranslation();
  const [jsonFinal, setJsonFinal] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [values, setValues] = useState<any>({
    action: "",
    negative_button_text: "",
    affirmative_button_text: "",
  });

  const generate = () => {
    const concat = lineBreak(description);
    if (values.action) {
      values.action = values.action.replaceAll("\\n", "\n");
    }
    if (values.negative_button_text) {
      values.negative_button_text = values.negative_button_text.replaceAll(
        "\\n",
        "\n",
      );
    }
    if (values.affirmative_button_text) {
      values.affirmative_button_text =
        values.affirmative_button_text.replaceAll("\\n", "\n");
    }

    const jsonGerado = {
      generic: [
        {
          response_type: "text",
          values: [
            {
              text_expression: {
                concat,
              },
            },
          ],
          selection_policy: "sequential",
        },
        {
          user_defined: {
            type: "app_action_link",
            value: {
              app_action_link: {
                values,
              },
            },
          },
          response_type: "user_defined",
        },
      ],
    };
    setJsonFinal(JSON.stringify(jsonGerado, null, 2));
  };

  const clear = () => {
    setDescription("");
    setValues({
      action: "",
      negative_button_text: "",
      affirmative_button_text: "",
    });
    setJsonFinal("");
  };

  return (
    <Grid container>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.description")}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.active")}
            variant="outlined"
            value={values.action}
            onChange={(e) => setValues({ ...values, action: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.negativeButtonText")}
            variant="outlined"
            value={values.negative_button_text}
            onChange={(e) =>
              setValues({ ...values, negative_button_text: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.affirmativeButtonText")}
            variant="outlined"
            value={values.affirmative_button_text}
            onChange={(e) =>
              setValues({ ...values, affirmative_button_text: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item>
          <Button
            variant="contained"
            disabled={
              (description === "") === false &&
              (values.action === "") === false &&
              (values.negative_button_text === "") === false &&
              (values.affirmative_button_text === "") === false
                ? false
                : true
            }
            onClick={generate}
          >
            {t("button.Generate")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={clear}>
            {t("button.Clean")}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <pre
            style={{
              wordWrap: "break-word",
              maxWidth: "37rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {jsonFinal}
          </pre>
        </Grid>
        <Grid item>
          {jsonFinal !== "" ? (
            <Button
              disabled={false}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(jsonFinal);
              }}
              startIcon={<ContentCopyIcon />}
            >
              {t("button.Copy")}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
