import { Box, Grid } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

interface IimageSelected {
  name: string;
  extension: string;
  imageBase: string;
}

export default function AddManual({
  data,
  watchDataChange,
  messageError,
}: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [imageSelected, setImageSelected] = useState<IimageSelected>();

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];
    const preview = document.getElementById("imageChosen") as HTMLImageElement;

    const lastIndex = file.name.lastIndexOf(".");

    const name = file.name.slice(0, lastIndex);
    const extension = file.name.slice(lastIndex + 1);

    let limitSize;

    if (extension === "mp4") {
      limitSize = 30000000;
    } else {
      limitSize = 2000000;
    }

    if (file.size > limitSize) {
      alert(`${t("alert.imageSize")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png|mp4)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      const imageBase = image[1];
      const imageInfo = {
        name,
        extension: `.${extension}`,
        imageBase,
      };

      setImageSelected(imageInfo);
    };

    reader.readAsDataURL(file);
  };

  const messageImageRequired = () => {
    const contactList = document.querySelector(
      "#imageRequired",
    ) as HTMLDivElement;
    contactList.style.display = "block";
    setTimeout(() => {
      contactList.style.display = "none";
    }, 5000);
  };

  const addManual = async () => {
    if (!imageSelected) {
      return messageImageRequired();
    }

    try {
      await axios.post(
        `${CONFIG.API_URL}manual`,
        {
          name: imageSelected?.name,
          image: imageSelected?.imageBase,
          ext: imageSelected?.extension,
          active: true,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("manual.Image added"));
  };

  return (
    <Box>
      <FormInputText
        label={t("manual.Identifier")}
        value={imageSelected ? imageSelected.name : ""}
        disabled
      />
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnImage")}</span>
            <input
              accept=".png, .jpg, .jpeg, .mp4"
              className="imageFile"
              type="file"
              id="fileInput"
              name="fileInput"
              onChange={(event) => {
                convertImageToBase64(event);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <span>{t("manual.selectedImage")}:</span>
            <Box>
              <img alt={imageSelected?.name} width="130px" id="imageChosen" />
              <br />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          color: "var(--red-100)",
          display: "none",
        }}
        id="imageRequired"
      >
        <span>{t("manual.requiredImage")}</span>
      </Box>
      <ActionButtons saveName={t("button.Add")} save={addManual} />
    </Box>
  );
}
