import { GridColDef, GridTableRowsIcon } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import WindowIcon from "@mui/icons-material/Window";
import { Box, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";

import GalleryView from "../../components/galleryView";
import ModelGallery from "../../components/models/modelGallery";
import { AuthContext } from "../../contexts/AuthContext";

export default function ManualInstructions() {
  const { t } = useTranslation();

  const [galleryView, setGalleryView] = useState(false);

  const editTerms = (e: any) => {
    alert("clicado");

    console.log("e.row", e.row);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("terms.title"),
      flex: 1,
      headerClassName: "header-style",
    },
  ];

  const callback = async (Model: any) => {
    alert("clicado");
  };

  const {
    country: { countryCode },
  } = useContext(AuthContext);

  return (
    <BoxContent
      title={t("menu.manualInstructions")}
      // button
      // titleButton={t("terms.Add terms")}
      // handleClick={handleClickModaAddTerms}
      noGlobal
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          placeItems: "center",
          color: "var(--gray-700)",
        }}
      >
        <span>{t("gallery.changeView")}:</span>
        <IconButton onClick={() => setGalleryView(false)}>
          <GridTableRowsIcon
            sx={{
              color: !galleryView ? "var(--blue-100)" : "var(--gray-700)",
            }}
          />
        </IconButton>
        <IconButton onClick={() => setGalleryView(true)}>
          <WindowIcon
            sx={{
              color: galleryView ? "var(--blue-100)" : "var(--gray-700)",
            }}
          />
        </IconButton>
      </Box>

      {!galleryView && (
        <DataGridApi
          onRowClick={editTerms}
          // dataUpdated={dataUpdated}
          endPoint={`vehicles/pageable?countryCode=${countryCode}&`}
          columnsData={columns}
          // messageError={messageError}
        />
      )}

      {galleryView && (
        <GalleryView
          onClick={callback}
          // dataUpdated={dataUpdated}
          endPoint={`vehicles/pageable?countryCode=${countryCode}&`}
          // messageError={messageError}
          Component={ModelGallery}
          columnsGrid={{ xs: 4, sm: 8, md: 8, lg: 12 }}
        />
      )}
    </BoxContent>
  );
}
