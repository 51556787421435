import { AlertColor, Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import AddCalculator from "../../components/calculator/addCalculator";
import EditCalculator from "../../components/calculator/editCalculator";
import ImportCalculator from "../../components/calculator/importCalculator";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";

export default function Calculator() {
  const { t } = useTranslation();

  const [openModalAddCalculator, setOpenModalAddCalculator] = useState(false);

  const [openModalEditCalculator, setOpenModalEditCalculator] = useState(false);
  const [calculatorSelected, setCalculatorSelected] = useState<any>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const updateData = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddCalculator(false);
    setOpenModalEditCalculator(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddCalculator = () => {
    setOpenModalAddCalculator(true);
  };

  const editCalculator = (e: any) => {
    setOpenModalEditCalculator(true);
    setCalculatorSelected(e.row);
  };

  const columns = [
    {
      field: "modelCode",
      headerName: t("calculator.modelCode"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "modelYear",
      headerName: t("calculator.modelYear"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "modelEdition",
      headerName: t("calculator.modelEdition"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "isFlexFuel",
      headerName: t("calculator.isFlexFuel"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "autonomyEthCity",
      headerName: t("calculator.autonomyEthCity"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "autonomyEthRoad",
      headerName: t("calculator.autonomyEthRoad"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "autonomyEthComb",
      headerName: t("calculator.autonomyEthComb"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "autonomyGasCity",
      headerName: t("calculator.autonomyGasCity"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "autonomyGasRoad",
      headerName: t("calculator.autonomyGasRoad"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "autonomyGasComb",
      headerName: t("calculator.autonomyGasComb"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "co2EthCity",
      headerName: t("calculator.co2EthCity"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "co2EthRoad",
      headerName: t("calculator.co2EthRoad"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "co2EthComb",
      headerName: t("calculator.co2EthComb"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "co2GasCity",
      headerName: t("calculator.co2GasCity"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "co2GasRoad",
      headerName: t("calculator.co2GasRoad"),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "co2GasComb",
      headerName: t("calculator.co2GasComb"),
      width: 200,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.calculator")}
      button
      titleButton={t("calculator.Add autonomy")}
      handleClick={handleClickModaAddCalculator}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <ImportCalculator
        updateData={updateData}
        watchDataChange={watchDataChange}
        messageError={messageError}
      />

      <Box
        sx={{
          marginBottom: 1,
        }}
      >
        <span style={{ fontSize: 12 }}>
          {t("calculator.searchInstructions")}
        </span>
      </Box>

      <DataGridApi
        onRowClick={editCalculator}
        dataUpdated={dataUpdated}
        endPoint="calculator?"
        columnsData={columns}
        messageError={messageError}
        params1="&modelCode="
        labelParams1={t("calculator.modelCode")}
        params2="&modelYear="
        labelParams2={t("calculator.modelYear")}
        typeInput2="number"
        params3="&modelEdition="
        labelParams3={t("calculator.modelEdition")}
        typeInput3="number"
      />

      {openModalAddCalculator && (
        <MyModal
          title={t("calculator.Add autonomy")}
          modalChange={setOpenModalAddCalculator}
        >
          <AddCalculator
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditCalculator && (
        <MyModal
          title={t("calculator.Edit autonomy")}
          modalChange={setOpenModalEditCalculator}
        >
          <EditCalculator
            watchDataChange={watchDataChange}
            messageError={messageError}
            data={calculatorSelected}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
