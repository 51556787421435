// import { Button } from "@mui/material";
import { AlertColor } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import BoxContent from "../../../components/boxContent";
import DataGridApi from "../../../components/dataGridApi";
import QuickAlert from "../../../components/form/quickAlert";
import MyModal from "../../../components/MyModal";

import EditCategory from "../../../components/faq/category/editCategory";

import AddNewCategory from "../../../components/faq/category/addNewCategory";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Page() {
  const { t } = useTranslation();
  const {
    country: { countryCode },
  } = useContext(AuthContext);
  const [openModalAddCategory, setOpenModalAddCategory] = useState(false);
  const [openModalEditCategory, setOpenModalEditCategory] = useState(false);

  const [category, setCategorySelected] = useState<any>();

  const editCategory = (e: any) => {
    setOpenModalEditCategory(true);
    setCategorySelected(e.row);
  };

  const handleClickModaAddCategory = () => {
    setOpenModalAddCategory(true);
  };

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddCategory(false);
    setOpenModalEditCategory(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage(t("faq.system.modal.confirmation.title"));
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: t("faq.category.Image"),
      width: 100,
      headerClassName: "header-style",
      renderCell: (params) => (
        <img
          src={params.value}
          alt="main"
          style={{ width: 50, height: 50, objectFit: "cover" }}
        />
      ),
    },
    {
      field: "name",
      headerName: t("faq.category.Name"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "description",
      headerName: t("faq.category.Description"),
      width: 429,
      headerClassName: "header-style",
    },
    {
      field: "active",
      headerName: t("faq.category.Active"),
      width: 200,
      headerClassName: "header-style",
      renderCell: (params) => <>{t(`faq.status.${params.value}`)}</>,
    },
  ];

  return (
    <BoxContent
      title={t("menu.faqcategory")}
      button
      titleButton={t("faq.category.Add button")}
      handleClick={handleClickModaAddCategory}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      <p style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
        {t("faq.category.Descripition")}
      </p>
      <DataGridApi
        onRowClick={editCategory}
        dataUpdated={dataUpdated}
        messageError={messageError}
        endPoint={`faq/categories?countrycode=${countryCode}&`}
        columnsData={columns}
      />

      {openModalAddCategory && (
        <MyModal
          title={t("faq.category.Add")}
          modalChange={setOpenModalAddCategory}
          width="990px"
        >
          <AddNewCategory
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditCategory && (
        <MyModal
          title={t("faq.category.Edit")}
          modalChange={setOpenModalEditCategory}
          width="990px"
        >
          <EditCategory
            data={category}
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
