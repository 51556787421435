import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, MenuItem } from "@mui/material";
import axios from "axios";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { FormInputText } from "../../../components/form/FormInputText";
import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";

interface Form {
  filelist: FileList;
  type: string;
}

interface Props {
  updateFiles: () => void;
}

export default function UploadSpreadsheet({ updateFiles }: Props) {
  const { user } = useContext(AuthContext);

  const d = "digitalServicePlan";
  const acceptedFormat =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const uploadForm = yup.object().shape({
    filelist: yup.mixed().test({
      name: "file",
      test(file) {
        return !!file?.length;
      },
      message: "Selecione um arquivo",
    }),
    type: yup.string().required("Selecione um tipo de planilha").default(""),
  });

  const {
    formState: {
      errors,
      isSubmitSuccessful,
      dirtyFields: { filelist: dirtyFileList },
    },
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    resetField,
  } = useForm<Form>({ resolver: yupResolver(uploadForm) });

  const { tokenHeaders } = useContext(AuthContext);

  const [isResetFile, setResetFile] = useState(false);

  const spreadsheetTypes = [
    `${t(`${d}.optionsVehiclesWithoutWarranty`)}`,
    `${t(`${d}.optionsExtendedWarranty`)}`,
    `${t(`${d}.optionsRevalidation`)}`,
    `${t(`${d}.optionsPlannedRevision`)}`,
    `${t(`${d}.optionsSeriesRevision`)}`,
    `${t(`${d}.optionsSeriesRevisionExceptions`)}`,
    `${t(`${d}.optionsWarrantySealStandard`)}`,
    `${t(`${d}.optionsWarrantySealExceptionList`)}`,
    `${t(`${d}.optionsWarrantySealChassisWithException`)}`,
    `${t(`${d}.optionsWarrantySealKmLimit`)}`,
    `${t(`${d}.optionsIdFourWithoutDSP`)}`,
  ];

  useEffect(() => {
    reset({ filelist: [] as unknown as FileList, type: "" });
    setValue("type", "");
  }, [isSubmitSuccessful, reset]);

  const sendFile = ({ filelist, type }: Form) => {
    const [file] = Array.from(filelist);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("email", user.email);

    const cases: { [type: string]: string } = {
      [`${t(`${d}.optionsVehiclesWithoutWarranty`)}`]: "warranty/no-warranty",
      [`${t(`${d}.optionsExtendedWarranty`)}`]: "warranty/extended-warranty",
      [`${t(`${d}.optionsRevalidation`)}`]: "revalidate",
      [`${t(`${d}.optionsPlannedRevision`)}`]: "revisions/planned",
      [`${t(`${d}.optionsSeriesRevision`)}`]: "revisions/series",
      [`${t(`${d}.optionsSeriesRevisionExceptions`)}`]:
        "revisions/series/exceptions",
      [`${t(`${d}.optionsWarrantySealStandard`)}`]: "warranty/standart",
      [`${t(`${d}.optionsWarrantySealExceptionList`)}`]:
        "warranty/exception-list",
      [`${t(`${d}.optionsWarrantySealChassisWithException`)}`]:
        "warranty/exception-vin",
      [`${t(`${d}.optionsWarrantySealKmLimit`)}`]: "warranty/exception-amarok",
      [`${t(`${d}.optionsIdFourWithoutDSP`)}`]: "warranty/no-warranty-electric",
    };

    axios
      .post(`${CONFIG.SOB_API}excel/${cases[type]}`, formData, tokenHeaders)
      .then(() => {
        alert("Upload realizado com sucesso!");
        updateFiles();
      })
      .catch((error) => {
        alert(error.response.data.errorMessage);
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <label
          htmlFor="inputSpreadSheet"
          style={{ display: "flex", gap: "1rem", alignItems: "center" }}
        >
          <span>{`${t("digitalServicePlan.file")}`}</span>
          <div
            style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
          >
            <Controller
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <input
                  type="file"
                  id="inputSpreadSheet"
                  accept={acceptedFormat}
                  {...field}
                  onChange={($event) => {
                    const {
                      target: { files },
                    } = $event;
                    if (!files || files.item(0)?.type !== acceptedFormat) {
                      alert("Selecione um arquivo .xlsx");
                      const fileInput = document.getElementById(
                        "inputSpreadSheet",
                      ) as HTMLInputElement;
                      fileInput.value = "";
                    } else {
                      onChange($event.target.files as FileList);
                    }
                  }}
                />
              )}
              name="filelist"
            />

            {!!errors.filelist && (
              <Box
                sx={{
                  marginTop: "-.5rem",
                  fontSize: "14px",
                  color: "var(--red-100)",
                }}
              >
                {errors.filelist?.message}
              </Box>
            )}
          </div>
        </label>
      </div>
      <form
        onSubmit={handleSubmit(sendFile)}
        style={{
          margin: "1rem 0",
          display: "flex",
          gap: "2rem",
        }}
      >
        <div style={{ flex: 1, marginTop: ".25rem" }}>
          <FormInputText
            select
            label="Tipo"
            id="slcType"
            defaultValue=""
            {...register("type")}
            key={`slcType${isSubmitSuccessful}`}
            error={errors.type}
          >
            {spreadsheetTypes.map((type) => (
              <MenuItem value={type} key={type} id={`opt${type}`}>
                {type}
              </MenuItem>
            ))}
          </FormInputText>
        </div>
        <Button
          size="small"
          variant="contained"
          type="submit"
          style={{
            whiteSpace: "nowrap",
            padding: "0 1.5rem",
            height: "3rem",
          }}
        >
          {`${t(`${d}.importFile`)}`}
        </Button>
      </form>
    </div>
  );
}
