import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import axios from "axios";
import AddManual from "../add-manual";
import MyModal from "../../../../components/MyModal";
import { IOwnersManual } from "../../../../interfaces/IOwnersManual";
import { IVehicleVersion } from "../../../../interfaces/IVehicleVersion";
import { CONFIG } from "../../../../services/config";
import { AuthContext } from "../../../../contexts/AuthContext";
import { IQuickAlert } from "../../../../interfaces/IQuickAlert";
import DataGridWrapper from "../../../../components/dataGridWrapper";

interface Props {
  vehicleVersion: IVehicleVersion;
  index: number;
  allVehicleVersions: IVehicleVersion[];
  updateVehicles: Function;
  setAlertConfig: Dispatch<SetStateAction<IQuickAlert>>;
  name?: string;
}

export default function Version({
  vehicleVersion: { content, vehicleVersion, vehicleVersionYear },
  index,
  allVehicleVersions,
  updateVehicles,
  setAlertConfig,
  name,
}: Props) {
  console.log("🚀 ~ file: index.tsx:31 ~ vehicleVersion", vehicleVersion);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [selectedManual, setSelectedManual] = useState<IOwnersManual>();

  const { tokenHeaders } = useContext(AuthContext);

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: `${vehicleVersionYear} ${vehicleVersion}`,
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "type",
      headerName: t("ownersManual.category"),
      flex: 1,
      headerClassName: "header-style",
      renderCell({ row }: any) {
        const { type } = row;
        const translate: { [type: string]: string } = {
          MAIN_MANUAL: t("ownersManual.mainManual"),
          RADIO_MANUAL: t("ownersManual.radioManual"),
          ADD_ONS: t("ownersManual.addOns"),
        };
        return <span>{translate[type]}</span>;
      },
    },
  ];

  const rows: any = [...content].sort(({ type: a }, { type: b }) => {
    const typeOrder = {
      MAIN_MANUAL: -1,
      RADIO_MANUAL: 0,
      ADD_ONS: 1,
    };

    return typeOrder[a].valueOf() - typeOrder[b].valueOf();
  });

  const saveOwnersManual = (data: IOwnersManual) => {
    axios
      .put(`${CONFIG.API_URL}versionContent?id=${data.id}`, data, tokenHeaders)
      .then(({ data }) => {
        setSelectedManual(data);
        setAlertConfig({
          visibility: true,
          description: t("ownersManual.updateSuccessMessage"),
          severity: "success",
          title: t("ownersManual.successTitleMessage"),
        });
        setTimeout(() => {
          setAlertConfig({
            visibility: false,
          });
        }, 3000);
        updateVehicles();
        setIsAddModalOpen(false);
      })
      .catch((error) => {
        setAlertConfig({
          visibility: true,
          description: t(error.response.data.message),
          title: t("ownersManual.errorTitleMessage"),
          severity: "error",
        });
      });
  };

  const removeOwnersManual = (id: number) => {
    axios
      .delete(`${CONFIG.API_URL}versionContent?id=${id}`, tokenHeaders)
      .then(() => {
        setAlertConfig({
          visibility: true,
          description: t("ownersManual.removeSuccessMessage"),
          severity: "success",
          title: t("ownersManual.successTitleMessage"),
        });
        setTimeout(() => {
          setAlertConfig({
            visibility: false,
          });
        }, 3000);
        updateVehicles();
        setIsAddModalOpen(false);
      })
      .catch((error) => {
        setAlertConfig({
          visibility: true,
          description: error.response.data.message,
          severity: "success",
          title: t("ownersManual.errorTitleMessage"),
        });
        setTimeout(() => {
          setAlertConfig({
            visibility: false,
          });
        }, 3000);
      });
  };

  const onRowClick = (data: IOwnersManual) => {
    setSelectedManual(data);
    setIsAddModalOpen(true);
  };

  const closeManual = () => {
    setIsAddModalOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          "& .header-style": {
            backgroundColor: "var(--gray-100)",
          },
        }}
      >
        <DataGridWrapper
          getRowId={(row: any) => (row.id ? row.id : Math.random())}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onRowClick={({ row }) => onRowClick(row)}
        />

        {isAddModalOpen && (
          <MyModal
            title={`${t("ownersManual.editManual")}`}
            modalChange={closeManual}
          >
            <AddManual
              ownersManual={selectedManual}
              allVehicleVersions={allVehicleVersions}
              saveOwnersManual={saveOwnersManual}
              submitButtonTitle={t("ownersManual.saveButton")}
              removeOwenersManual={removeOwnersManual}
              vehicleVersion={vehicleVersion}
              vehicleVersionYear={vehicleVersionYear}
              name={name}
            />
          </MyModal>
        )}
      </Box>
    </Box>
  );
}
