import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, AlertColor, Box, Button } from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import BoxContent from "../../components/boxContent";
import { FormInputText } from "../../components/form/FormInputText";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import ActionButtons from "../../components/MyModal/ActionButtons";
import RegisteredOnboard from "../../components/onboard/registeredOnboard";
import { AuthContext } from "../../contexts/AuthContext";
import { IOnboard, IOnboardRow } from "../../interfaces/IOnboard";
import { CONFIG } from "../../services/config";

export default function Onboard() {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const addFormData = yup.object().shape({
    title: yup.string(),
    featuredTitle: yup.string(),
    description: yup.string().required(t("onboard.Required description")),
    notACustomer: yup.string(),
  });

  const editFormData = yup.object().shape({
    title: yup.string(),
    featuredTitle: yup.string(),
    description: yup.string().required(t("onboard.Required description")),
    notACustomer: yup.string(),
  });

  const [onboardSelected, setOnboardSelected] = useState<IOnboardRow | any>();
  const [openModalAddOnboard, setOpenModalAddOnboard] = useState(false);
  const [openModalEditOnboard, setOpenModalEditOnboard] = useState(false);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Success");
    setSeverityAlert("success");
    setOpenModalEditOnboard(false);
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
    // setOpenModalEditOnboard(false);
  };

  const handleClickModaAddOnboard = () => {
    setOpenModalAddOnboard(true);
  };

  const { register, handleSubmit, reset, formState, clearErrors } =
    useForm<IOnboard>({
      resolver: yupResolver(addFormData),
    });

  const { errors } = formState;

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    formState: formStateEdit,
  } = useForm<IOnboard>({
    resolver: yupResolver(editFormData),
  });

  const { errors: errorsEdit } = formStateEdit;

  useEffect(() => {
    clearErrors();
    reset();
  }, [openModalAddOnboard]);

  const addOnboardScreen: SubmitHandler<IOnboard | FieldValues> = async (
    values,
  ) => {
    try {
      if (values.title || values.featuredTitle) {
        await axios.post(
          `${CONFIG.API_URL}onboard`,
          {
            countryCode,
            title: values.title,
            featuredTitle: values.featuredTitle,
            description: values.description,
            notACustomer: values.notACustomer,
          },
          tokenHeaders,
        );
        setMessageQuickAlert(t("onboard.Onboard screen added"));
        watchDataChange();
        reset();
        setOpenModalAddOnboard(false);
      } else {
        alert(t("onboard.Required title or featured title"));
      }
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
  };

  const editOnboardRow = (e: IOnboardRow) => {
    setOpenModalEditOnboard(true);
    resetEdit();
    setOnboardSelected(e.row);
  };

  const updateOnboardRow: SubmitHandler<IOnboard | FieldValues> = async (
    values,
  ) => {
    try {
      if (values.title || values.featuredTitle) {
        await axios.put(
          `${CONFIG.API_URL}onboard?id=${onboardSelected.onboardId}`,
          {
            countryCode,
            title: values.title,
            featuredTitle: values.featuredTitle,
            description: values.description,
            notACustomer: values.notACustomer,
          },
          tokenHeaders,
        );
        setMessageQuickAlert(t("onboard.Onboard screen edited"));
        watchDataChange();
      } else {
        alert(t("onboard.Required title or featured title"));
      }
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
  };

  const deleteOnboardScreen = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}onboard?id=${onboardSelected.onboardId}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setMessageQuickAlert(t("onboard.Onboard screen deleted"));
    watchDataChange();
  };

  if (!countryCode) {
    return (
      <Alert severity="warning" sx={{ marginTop: "1rem" }}>
        {t("onboard.Warn_country")}
      </Alert>
    );
  }

  const setDefatulURL = () => {
    const notACustomer = document.querySelector(
      "#notACustomer",
    ) as HTMLInputElement | null;
    const editUrl = document.querySelector(
      "#editUrl",
    ) as HTMLInputElement | null;

    notACustomer!.value = "https://";
    editUrl!.value = "https://";
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t("onboard.Title"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "featuredTitle",
      headerName: t("onboard.Featured title"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "description",
      headerName: t("onboard.Description"),
      width: 300,
      headerClassName: "header-style",
    },
    {
      field: "notACustomer",
      headerName: t("onboard.URL I'm not a customer"),
      width: 300,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.onboard")}
      button
      titleButton={t("onboard.Add Onboard")}
      handleClick={handleClickModaAddOnboard}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      {openModalAddOnboard && (
        <MyModal
          title={t("onboard.Add Onboard")}
          modalChange={setOpenModalAddOnboard}
        >
          <Box component="form" onSubmit={handleSubmit(addOnboardScreen)}>
            <FormInputText
              label={t("onboard.Title")}
              error={errors.title}
              {...register("title")}
              inputProps={{
                maxLength: 100,
              }}
            />

            <FormInputText
              label={t("onboard.Featured title")}
              error={errors.featuredTitle}
              {...register("featuredTitle")}
              inputProps={{
                maxLength: 100,
              }}
            />

            <FormInputText
              label={t("onboard.Description")}
              error={errors.description}
              {...register("description")}
              inputProps={{
                maxLength: 200,
              }}
            />

            <FormInputText
              label={t("onboard.URL I'm not a customer")}
              type="url"
              id="notACustomer"
              onFocus={() => setDefatulURL()}
              {...register("notACustomer")}
            />

            <Alert
              severity="warning"
              sx={{ fontSize: "14px", marginBottom: 2 }}
            >
              {t("onboard.urlNotAplied")}
            </Alert>

            <Button variant="contained" type="submit">
              {t("button.Add")}
            </Button>
          </Box>
        </MyModal>
      )}

      <RegisteredOnboard
        onRowClick={editOnboardRow}
        dataUpdated={dataUpdated}
      />

      {openModalEditOnboard && (
        <MyModal
          title={t("onboard.Edit Onboard")}
          modalChange={setOpenModalEditOnboard}
          component="form"
          onSubmit={handleSubmitEdit(updateOnboardRow)}
        >
          <FormInputText
            label={t("onboard.Title")}
            id="editTitle"
            defaultValue={onboardSelected.title}
            error={errorsEdit.title}
            {...registerEdit("title")}
          />
          <FormInputText
            label={t("onboard.Featured title")}
            id="editFeaturedTitle"
            defaultValue={onboardSelected.featuredTitle}
            error={errorsEdit.featuredTitle}
            {...registerEdit("featuredTitle")}
          />
          <FormInputText
            label={t("onboard.Description")}
            id="editDescription"
            defaultValue={onboardSelected.description}
            error={errorsEdit.description}
            {...registerEdit("description")}
          />
          <FormInputText
            label={t("onboard.URL I'm not a customer")}
            type="url"
            id="editUrl"
            onFocus={() => setDefatulURL()}
            defaultValue={onboardSelected.notACustomer}
            error={errorsEdit.notACustomer}
            {...registerEdit("notACustomer")}
          />

          <Alert severity="warning" sx={{ fontSize: "14px", marginBottom: 2 }}>
            {t("onboard.urlNotAplied")}
          </Alert>

          <ActionButtons
            typeButton="submit"
            saveName={t("button.Save")}
            confirmDelete={deleteOnboardScreen}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
