import { Box, Button, FormControlLabel, Checkbox } from "@mui/material";
import moment from "moment";
import { useState } from "react";

interface Props {
  onClose: (accept: boolean) => void;
}

export default function Permissions({ onClose }: Props) {
  const [rememberOption, setRememberOption] = useState(false);
  const close = (accept: boolean) => {
    if (rememberOption && accept) {
      const expirateDate = `expires=${moment(Date.now())
        .add(100, "years")
        .toDate()
        .toUTCString()}`;
      const cookie = `mk_permission=${rememberOption}`;
      document.cookie = `${cookie};${expirateDate};SameSite=Strict;Secure`;
    }
    onClose(accept);
  };
  return (
    <Box>
      Este relatório contém dados pessoas dos clientes Volkswagen e devem ser
      manuseados e tratados considerando as obrigações da Lei Geral de Proteção
      de Dados.
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <FormControlLabel
            label="Não lembrar novamente."
            control={
              <Checkbox
                color="primary"
                onChange={($event) => setRememberOption($event.target.checked)}
              />
            }
          />
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            color="error"
            variant="contained"
            onClick={() => close(false)}
          >
            Cancelar
          </Button>
          <Button variant="contained" onClick={() => close(true)}>
            Ok, Prosseguir
          </Button>
        </div>
      </div>
    </Box>
  );
}
