import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider } from "@mui/material";
import axios from "axios";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import ActionButtons from "../MyModal/ActionButtons";

import Vehicle from "./vehicle";

import { AuthContext } from "../../contexts/AuthContext";
import { IVehicle } from "../../interfaces/IVehicle";
import { CONFIG } from "../../services/config";

import mockModel from "./mock.model.json";

// import { IModel } from "../../interfaces/IModel";

interface IEditModal {
  data: IVehicle;
  watchDataChange: any;
  messageError: any;
  addFormData: any;
  vehicleId: any;
}

export default function EditModel({
  data,
  watchDataChange,
  messageError,
  addFormData,
  vehicleId,
}: IEditModal) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [disableActionButton, setDisableActionButton] = useState(false);
  const [watchScroll, setWatchScroll] = useState(1);

  const [vehicleData, setVehicleData] = useState<any>();

  // console.log("data", data);

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<IVehicle | any>({
    mode: "onBlur",
    resolver: yupResolver(addFormData),
    defaultValues: data,
  });

  const editVersion: SubmitHandler<IVehicle> = async (values) => {
    console.log("values", values);

    for (let i = 0; i < values.versions.length; i++) {
      const element = values.versions[i];
      console.log("element", element);
      for (let index = 0; index < element.version.length; index++) {
        const versionImage = element.version[index];
        console.log("versionImage", versionImage);
        if (versionImage.name === "") {
          return alert(t("models.noImageVersion"));
        }
      }
    }

    for (let index = 0; index < values.versions.length; index++) {
      const element = values.versions[index];

      if (element.version.length > 1 && element.isCognitive === "false") {
        return alert(t("models.onlyOneImage"));
      }
      if (element.version.length <= 0) {
        return alert(t("models.noImageVersion"));
      }
    }

    try {
      await axios.put(
        `${CONFIG.API_URL}vehicles/${data.id}`,
        {
          countryCode,
          extension: values.extension,
          teaser: values.teaserMediaId,
          vehicleName: values.name,
          backgroundColor: values.backgroundColor,
          versions: values.versions,
        },
        tokenHeaders,
      );
      setDisableActionButton(false);
    } catch (error: any) {
      setDisableActionButton(false);

      return messageError(error.response.data.message);
    }
    watchDataChange(t("models.modelEdited"));
  };

  const deleteVehicle = async () => {
    try {
      await axios.put(
        `${CONFIG.API_URL}vehicles/status?countryCode=${countryCode}&id=${data.id}&status=WAITING_FOR_APPROVAL_REMOVE`,
        {},
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("models.modelDeleted"));
  };

  const methods = useFormContext();

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        console.log("aqui");
        if (watchScroll < data.versions.length) {
          setWatchScroll((currentValue) => currentValue + 1);
        }
      }
    });
    intersectionObserver.observe(
      document.querySelector("#watcher") as HTMLDivElement,
    );
    return () => intersectionObserver.disconnect();
  }, []);

  return (
    <FormProvider
      {...methods}
      reset={reset}
      register={register}
      control={control}
    >
      <Box component="form" onSubmit={handleSubmit(editVersion)}>
        <Box
          sx={{
            position: "relative",
            bottom: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFE0",
              padding: "10px",
              borderRadius: "8px",
              marginBottom: "10px",
            }}
          >
            <p style={{ color: "orange", margin: 0 }}>
              {t("models.versionsWarningMessage")}
            </p>
          </div>
          <ActionButtons
            saveName={t("button.Save")}
            typeButton="submit"
            confirmDelete={deleteVehicle}
            disableSave={disableActionButton ? true : false}
            enableLine={false}
            titleConfirm={`${t("button.Confirm delete")} 
              ${t("models.modelRemove")} 
              ${data.name}?  
              ${t("models.modelAttention")}`}
          />
          <Divider />
        </Box>
        {data && (
          <Vehicle
            errors={errors}
            data={data}
            watchScroll={watchScroll}
            editVehicle
          />
        )}

        {/* Box to watch when the user scroll to the bottom of the modal */}
        <Box id="watcher" />
      </Box>
    </FormProvider>
  );
}
