import { Box, Button, Grid, IconButton, MenuItem, Select } from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";

import { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IColor } from "../../interfaces/IColor";
import { IVehicle } from "../../interfaces/IVehicle";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ModalConfirmation from "../MyModal/modalConfirmation";

interface IModelImage {
  data: IVehicle;
  childIndex: number;
  errors: any;
}

export default function ModelImage({ childIndex, errors, data }: IModelImage) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [newImage, setNewImage] = useState(false);
  const [allColors, setAllColors] = useState<IColor[]>([]);
  const [activeColors, setActiveColors] = useState<IColor[]>([]);
  const [versionLength, setVersionLength] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [removeVehicleColor, setRemoveVehicleColor] = useState<number>();

  const { register, control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `versions[${childIndex}].version`,
  });

  const [newData, setNewData] = useState<IVehicle>(data);

  useEffect(() => {
    axios
      .get(
        `${CONFIG.API_URL}vehiclecolors/simple/select?countryCode=${countryCode}`,
        tokenHeaders,
      )
      .then((response) => {
        setAllColors(response.data);
      })
      .catch(() => {
        console.log("erro");
      });

    axios
      .get(
        `${CONFIG.API_URL}vehiclecolors?countryCode=${countryCode}`,
        tokenHeaders,
      )
      .then((response) => {
        setActiveColors(response.data);
      })
      .catch(() => {
        console.log("erro");
      });

    // setNewImage(true);
    if (data) {
      setNewData(data);
      setVersionLength(data.versions.length);
    }
  }, [countryCode]);

  const fillInputs = ({
    name,
    extension,
    imageBase,
    vehicleImageIndex,
  }: any) => {
    const nameInput = document.querySelector(
      `#name${childIndex}${vehicleImageIndex}`,
    ) as HTMLInputElement;
    const extensionInput = document.querySelector(
      `#extension${childIndex}${vehicleImageIndex}`,
    ) as HTMLInputElement;
    const imageInput = document.querySelector(
      `#image${childIndex}${vehicleImageIndex}`,
    ) as HTMLInputElement;
    const previewInput = document.querySelector(
      `#preview${childIndex}${vehicleImageIndex}`,
    ) as HTMLImageElement;

    nameInput.value = name;
    previewInput.alt = name;
    nameInput.focus();

    extensionInput.value = extension;
    extensionInput.focus();

    imageInput.value = imageBase;
    imageInput.focus();
  };

  const convertImageToBase64 = (event: any, { vehicleImageIndex }: any) => {
    // console.log("vehicleImageIndex", vehicleImageIndex);
    const file = event.target.files![0];

    const preview = document.getElementById(
      `preview${childIndex}${vehicleImageIndex}`,
    ) as HTMLImageElement;

    const lastIndex = file.name.lastIndexOf(".");

    const name = file.name.slice(0, lastIndex);
    const extension = file.name.slice(lastIndex + 1);

    let limitSize;

    if (extension === "mp4") {
      limitSize = 30000000;
    } else {
      limitSize = 2000000;
    }

    if (file.size > limitSize) {
      alert(`${t("alert.imageSize")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImageFile"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      const imageBase = image[1];

      fillInputs({ name, extension, imageBase, vehicleImageIndex });
    };

    reader.readAsDataURL(file);
  };

  const removeFromArray = (vehicleImageIndex: number) => {
    // console.log("îndex", vehicleImageIndex);

    if (newData) {
      newData.versions[childIndex]?.version.splice(vehicleImageIndex, 1);
    }
  };

  const confirmDelete = () => {
    removeFromArray(removeVehicleColor as any);
    remove(removeVehicleColor);
  };

  return (
    <Box>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        {fields.map((vehicleImage, vehicleImageIndex) => {
          return (
            <Grid item xs={3} key={vehicleImage.id}>
              <Box
                sx={{
                  position: "absolute",
                  left: "-10000px",
                  top: "auto",
                  width: "1px",
                  height: "1px",
                  overflow: "hidden",
                }}
              >
                <FormInputText
                  id={`name${childIndex}${vehicleImageIndex}`}
                  label="name"
                  type="text"
                  {...register(
                    `versions[${childIndex}].version[${vehicleImageIndex}].name`,
                  )}
                  error={
                    errors?.["versions"]?.[childIndex]?.["version"]?.[
                      vehicleImageIndex
                    ]?.["name"]
                  }
                />
                <FormInputText
                  id={`extension${childIndex}${vehicleImageIndex}`}
                  label="extension"
                  type="text"
                  {...register(
                    `versions[${childIndex}].version[${vehicleImageIndex}].extension`,
                  )}
                />
                <FormInputText
                  id={`image${childIndex}${vehicleImageIndex}`}
                  label="image"
                  type="text"
                  {...register(
                    `versions[${childIndex}].version[${vehicleImageIndex}].imageBase`,
                  )}
                />
                <FormInputText
                  id={`image${childIndex}${vehicleImageIndex}`}
                  label="image"
                  type="text"
                  {...register(
                    `versions[${childIndex}].version[${vehicleImageIndex}].originalColorId`,
                  )}
                  defaultValue={
                    !newImage &&
                    data?.versions[childIndex]?.version[vehicleImageIndex]
                      ? data.versions[childIndex]?.version[vehicleImageIndex]
                          .originalColorId
                      : ""
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Button
                  variant="text"
                  component="label"
                  sx={{
                    width: 180,
                    height: 130,
                    cursor: "pointer",
                    display: "flex",
                    placeItems: "center",
                    justifyContent: "center",
                    border: "1px solid  var(--gray-200)",
                    color: "var(--gray-900)",
                    position: "absolute",
                  }}
                >
                  <input
                    hidden
                    accept=".png, .jpg, .jpeg, .mp4"
                    className="imageFile"
                    type="file"
                    id="fileInput"
                    name="fileInput"
                    // value={image64}
                    onChange={(event) => {
                      convertImageToBase64(event, { vehicleImageIndex });
                    }}
                  />
                </Button>
                <Box
                  sx={{
                    overflow: "hidden",
                    width: 180,
                    height: 130,
                    display: "flex",
                    placeItems: "center",
                  }}
                >
                  {data?.versions.length >= childIndex + 1 ? (
                    <img
                      alt={
                        data?.versions[childIndex]?.version[vehicleImageIndex]
                          ? data?.versions[childIndex]?.version[
                              vehicleImageIndex
                            ].name
                          : t("models.chooseImage")
                      }
                      id={`preview${childIndex}${vehicleImageIndex}`}
                      style={{
                        width: 189,
                        // height: 64,
                      }}
                      src={
                        data?.versions[childIndex]?.version[vehicleImageIndex]
                          ? `${data?.versions[childIndex]?.version[vehicleImageIndex].imageBase}`
                          : ""
                      }
                      loading="lazy"
                    />
                  ) : (
                    <img
                      alt={t("models.chooseImage")}
                      id={`preview${childIndex}${vehicleImageIndex}`}
                      style={{
                        width: 189,
                        // height: 64,
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Select
                    displayEmpty
                    labelId="colorSelect"
                    id="colorSelect"
                    placeholder="Select"
                    sx={{ height: 35, maxWidth: 150, minWidth: 150 }}
                    {...register(
                      `versions[${childIndex}].version[${vehicleImageIndex}].colorId`,
                    )}
                    defaultValue={
                      !newImage
                        ? data?.versions[childIndex]?.version[vehicleImageIndex]
                          ? data?.versions[childIndex]?.version[
                              vehicleImageIndex
                            ].colorId
                          : ""
                        : ""
                    }
                    required
                  >
                    <MenuItem disabled value="">
                      <em>{t("models.selectColor")}</em>
                    </MenuItem>

                    {/* {childIndex <= versionLength - 1 &&
                    !newImage &&
                    allColors.map((color: IColor) => {
                      const dataId =
                        data?.versions[childIndex].version[vehicleImageIndex]
                          .colorId;

                      if (!color.active && color.id !== Number(dataId)) {
                        return false;
                      }
                      return (
                        <MenuItem key={color.id} value={color.id}>
                          {color.name} aaa
                        </MenuItem>
                      );
                    })}

                  {newImage &&
                    activeColors.map((color: IColor) => {
                      return (
                        <MenuItem key={color.id} value={color.id}>
                          {color.name} bbb
                        </MenuItem>
                      );
                    })} */}

                    {allColors.map((color: IColor) => {
                      let dataId;
                      if (childIndex <= versionLength - 1) {
                        if (
                          data?.versions[childIndex]?.version[
                            vehicleImageIndex
                          ] === undefined
                        ) {
                          console.log("undefined aqui");
                        } else {
                          if (
                            data?.versions[childIndex]?.version === undefined
                          ) {
                            return false;
                          }
                          dataId =
                            data?.versions[childIndex]?.version[
                              vehicleImageIndex
                            ].colorId;
                        }
                      }

                      if (!color.active && color.id !== Number(dataId)) {
                        return false;
                      }
                      return (
                        <MenuItem key={color.id} value={color.id}>
                          {color.name}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <IconButton
                    onClick={() => {
                      setRemoveVehicleColor(vehicleImageIndex);
                      setShowConfirmDelete(!showConfirmDelete);
                    }}

                    // onClick={() => {
                    //   removeFromArray(vehicleImageIndex);
                    //   remove(vehicleImageIndex);
                    // }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>

                {showConfirmDelete && (
                  <ModalConfirmation
                    openDelete={setShowConfirmDelete}
                    confirmDelete={confirmDelete}
                  />
                )}

                {/* <Button
                  variant="text"
                  sx={{ color: "var(--red-100)" }}
                  onClick={() => {
                    removeFromArray(vehicleImageIndex);
                    remove(vehicleImageIndex);
                  }}
                >
                  {t("models.delete")}
                </Button> */}
              </Box>
            </Grid>
          );
        })}

        <Box
          sx={{
            marginY: 2.5,
            gap: 2,
            marginLeft: 2,
          }}
        >
          <Button
            sx={{
              marginTop: -0.5,
              width: 180,
              height: 130,
              color: "var(--gray-900)",
            }}
            id="addImage"
            variant="outlined"
            type="button"
            onClick={() => {
              setNewImage(true);
              append({
                name: "",
                extension: "",
                imageBase: "",
                // color: "",
              });
            }}
          >
            {t("models.addImage")}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}
