import TableRowsIcon from "@mui/icons-material/TableRows";
import WindowIcon from "@mui/icons-material/Window";
import { AlertColor, Box, IconButton } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import GalleryView from "../../components/galleryView";
import AddManual from "../../components/manual/addManual";
import AddMultipleImages from "../../components/manual/addMultipleImages";
import EditManual from "../../components/manual/editManual";
import ManualGallery from "../../components/manual/manualGallery";
import MyModal from "../../components/MyModal";
import { IManualData } from "../../interfaces/IManual";
import convertToPtBrDate from "../../utils/convertToPtBrDate";

export default function Manual() {
  const { t } = useTranslation();

  const [openModalAddManual, setOpenModalAddManual] = useState(false);

  const [openModalEditManual, setOpenModalEditManual] = useState(false);
  const [manualSelected, setManualSelected] = useState<any>();
  const [galleryView, setGalleryView] = useState(false);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const updateData = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddManual(false);
    setOpenModalEditManual(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddManual = () => {
    setOpenModalAddManual(true);
  };

  const editManual = (e: any) => {
    setOpenModalEditManual(true);
    // resetEdit();
    setManualSelected(e.row);
  };

  const callback = (manual: IManualData) => {
    setOpenModalEditManual(true);
    setManualSelected(manual);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("manual.Identifier"),
      renderCell: (params) =>
        params.value ? <p title={params.value}>{params.value}</p> : "",
      width: 628,
      headerClassName: "header-style",
    },
    {
      field: "updatedAt",
      headerName: t("manual.Date"),
      renderCell: (params) =>
        params.value ? (
          <p title={params.value}>{convertToPtBrDate(params.value)}</p>
        ) : (
          ""
        ),
      width: 100,
      headerClassName: "header-style",
    },
    {
      field: "imagePath",
      headerName: t("manual.Media"),
      renderCell: (params) => {
        if (!params.value) {
          return "";
        }

        const ext = params.value.split(".").pop();
        const isVideo = ["mp4", "webm"].includes(ext);

        return !isVideo ? (
          <img
            src={`${params.value}?nocache=${Date.now()}`}
            alt="No document"
            width="80px"
          />
        ) : (
          <div style={{ width: "80px", textAlign: "center" }}>
            <PlayCircleIcon width={80} />
          </div>
        );
      },
      width: 130,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.manual")}
      button
      titleButton={t("manual.Add media")}
      handleClick={handleClickModaAddManual}
      global
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      <AddMultipleImages
        updateData={updateData}
        watchDataChange={watchDataChange}
        messageError={messageError}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          placeItems: "center",
          color: "var(--gray-700)",
        }}
      >
        <span>{t("gallery.changeView")}:</span>
        <IconButton onClick={() => setGalleryView(false)}>
          <TableRowsIcon
            sx={{
              color: !galleryView ? "var(--blue-100)" : "var(--gray-700)",
            }}
          />
        </IconButton>
        <IconButton onClick={() => setGalleryView(true)}>
          <WindowIcon
            sx={{
              color: galleryView ? "var(--blue-100)" : "var(--gray-700)",
            }}
          />
        </IconButton>
      </Box>

      {!galleryView && (
        <DataGridApi
          onRowClick={editManual}
          dataUpdated={dataUpdated}
          endPoint="manual?active=true&"
          columnsData={columns}
          messageError={messageError}
          params1="&name="
          labelParams1={t("manual.search")}
          rowHeight={100}
        />
      )}

      {openModalAddManual && (
        <MyModal
          title={t("manual.Add media")}
          modalChange={setOpenModalAddManual}
        >
          <AddManual
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditManual && (
        <MyModal
          title={t("manual.Edit media")}
          modalChange={setOpenModalEditManual}
        >
          <EditManual
            data={manualSelected}
            watchDataChange={watchDataChange}
            updateData={updateData}
            messageError={messageError}
          />
        </MyModal>
      )}
      {galleryView && (
        <GalleryView
          onClick={callback}
          dataUpdated={dataUpdated}
          endPoint="manual?active=true&"
          messageError={messageError}
          params1="&name="
          labelParams1={t("manual.search")}
          Component={ManualGallery}
          columnsGrid={{ xs: 4, sm: 8, md: 8, lg: 12 }}
        />
      )}
    </BoxContent>
  );
}
