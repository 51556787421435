export const CONFIG = {
  CALLBACK_URL: process.env.REACT_APP_CALLBACK_URL,
  MOCK_API: process.env.REACT_APP_MOCK_API,
  API_URL: process.env.REACT_APP_API,
  SOB_API: process.env.REACT_APP_SOB_API,
  VW_ID_BASEURL: process.env.REACT_APP_VW_ID_BASEURL,
  VW_ID_CLIENT_ID: process.env.REACT_APP_VW_ID_CLIENT_ID,
  VW_ID_CLIENT_SECRET: process.env.REACT_APP_VW_ID_CLIENT_SECRET,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
};
