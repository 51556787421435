import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { t } from "i18next";
import { useLocation } from "react-router-dom";

import CheckGroup from "../../../../components/checkGroup";
import UploadPdf from "../../../../components/uploadPdf";
import { ICheckGroupOption } from "../../../../interfaces/IChekGroupOption";
import ModalConfirmation from "../../../../components/MyModal/modalConfirmation";
import { IVehicleVersion } from "../../../../interfaces/IVehicleVersion";
import { IOwnersManual } from "../../../../interfaces/IOwnersManual";
import ConvertFileBase64 from "../../../../services/ConvertToBase64";
import { FormInputText } from "../../../../components/form/FormInputText";
import { convertBlobToBase64 } from "../../../../utils/convertBlobToBase64";

interface Props {
  ownersManual?: IOwnersManual;
  allVehicleVersions: IVehicleVersion[];
  saveOwnersManual: (data: IOwnersManual) => void;
  removeOwenersManual?: (id: number) => void;
  submitButtonTitle: string;
  name?: string;
  vehicleVersion?: string;
  vehicleVersionYear?: number;
}

interface OwnersManualForm {
  partNumber: string;
  title: string;
  body: string;
  type: string;
}

export default function AddManual({
  ownersManual: {
    id,
    active,
    body,
    title,
    type,
    mediaId,
    thumbId,
    partNumber,
  } = {
    partNumber: "",
    active: 0,
    body: "",
    eligibleVersions: [],
    isManual: 0,
    title: "",
    type: "",
    mediaId: "",
    thumbId: "",
  },
  allVehicleVersions,
  saveOwnersManual,
  removeOwenersManual = () => {},
  submitButtonTitle,
  name,
  vehicleVersion,
  vehicleVersionYear,
}: Props) {
  console.log("----ownersManual", {
    id,
    active,
    body,
    title,
    type,
    mediaId,
    thumbId,
    partNumber,
  });

  const [versions, setVersions] = useState<ICheckGroupOption[]>([]);
  const [radioValue, setRadioValue] = useState<number>();

  const [filterOptions, setFilterOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [isActive, setIsActive] = useState(!!active);
  const [file, setFile] = useState<File>(new File([], ""));
  const [fileError, setFileError] = useState("");
  const [checkboxError, setCheckboxError] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [fileManualBase64, setFileManualBase64] = useState<string>("");
  const [fileThumbBase64, setFileThumbBase64] = useState<string>("");

  const addManualFormData = yup.object().shape({
    partNumber: yup
      .string()
      .required(t("ownersManual.partnumberRequiredError")),
    title: yup.string().required(t("ownersManual.titleRequiredError")),
    body: yup.string().required(t("ownersManual.bodyRequiredError")),
    type: yup.string().required(t("ownersManual.categoryRequiredError")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<OwnersManualForm>({ resolver: yupResolver(addManualFormData) });

  const fillOptions = () => {
    console.log("allVehicleVersions", allVehicleVersions);
    const versionsOptions: ICheckGroupOption[] =
      allVehicleVersions.map<ICheckGroupOption>(
        ({ vehicleVersion, versionId, vehicleVersionYear, content }) => ({
          checked: !!content.find(
            ({ partNumber: current }) => current === partNumber,
          ),
          value: versionId,
          label: `${vehicleVersionYear} ${vehicleVersion}`,
          visible: true,
          filterValue: vehicleVersionYear,
        }),
      );
    setVersions(versionsOptions);
  };

  const fillFilters = () => {
    const filterOptions = Array.from(
      new Set(
        allVehicleVersions.map(({ vehicleVersionYear }) => vehicleVersionYear),
      ),
    ).sort((a, b) => (a > b ? 1 : -1));
    const filters = filterOptions.map((vehicleVersionYear) => ({
      label: vehicleVersionYear.toString(),
      value: vehicleVersionYear,
    }));
    setFilterOptions(filters);
  };
  const changeFileManualBase64 = async (blob: Blob) => {
    const { base64 } = await convertBlobToBase64(blob);
    setFileManualBase64(base64);
  };
  const changeThumbFileBase64 = async (blob: Blob) => {
    const { base64 } = await convertBlobToBase64(blob);
    setFileThumbBase64(base64);
  };
  const fillFiles = async () => {
    if (mediaId) {
      const result = await axios.get(mediaId, { responseType: "blob" });
      changeFileManualBase64(result.data);
      setFile(new File([mediaId], title));
    }
    if (thumbId) {
      const result = await axios.get(thumbId, { responseType: "blob" });
      changeThumbFileBase64(result.data);
    }
  };

  const convertImageToBase64 = (file: File) => {
    const preview = document.getElementById("imageChosen") as HTMLImageElement;

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      if (!fileThumbBase64 && !thumbId) {
        alert(t("alert.validImageFile"));
      }
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");
    };

    reader.readAsDataURL(file);
    changeThumbFileBase64(file);
  };

  useLayoutEffect(() => {
    if (thumbId) {
      axios.get(thumbId, { responseType: "blob" }).then(({ data: file }) => {
        const [fileName, fileType] = thumbId.split("/").reverse()[0].split(".");
        const fileInput = document.querySelector(
          "#imageInput",
        ) as HTMLInputElement;
        const dataTransfer = new DataTransfer();
        const presentFile = new File(
          [file.slice(0, file.size)],
          `${fileName}.${fileType}`,
          {
            type: `image/${fileType}`,
          },
        );
        dataTransfer.items.add(presentFile);
        fileInput.files = dataTransfer.files;
        convertImageToBase64(presentFile);
      });
    }
  }, []);

  useLayoutEffect(() => {
    fillOptions();
    fillFilters();
    fillFiles();
    setValue("body", body);
    setValue("title", title);
    setValue("type", type);
    setValue("partNumber", partNumber);
  }, []);

  const filterFunction = (value: any, { filterValue }: ICheckGroupOption) => {
    return value === filterValue;
  };

  const save = async (values: OwnersManualForm) => {
    // if (radioValue === 2 && imageBase64 === "") {
    //   return alert(t("ownersManual.addImage"));
    // } v1
    const eligibleVersions = versions
      .filter(({ checked }) => checked)
      .map(({ value }) => value);
    console.log(
      "🚀 ~ file: index.tsx:209 ~ save ~ eligibleVersions",
      eligibleVersions,
    );

    const validations = [
      {
        validation: !eligibleVersions.length,
        errorAction: () => {
          setCheckboxError(t("ownersManual.versionRequiredError"));
        },
      },
      {
        validation: !fileManualBase64,
        errorAction: () => {
          setFileError(t("ownersManual.fileTypeField"));
        },
      },
    ];

    const error = validations.filter(({ validation }) => validation);
    console.log(error);
    if (error.length) {
      error.forEach(({ errorAction }) => errorAction());
    } else {
      const newOwnersManual: IOwnersManual = {
        id,
        isActive: isActive ? 1 : 0,
        isManual: 1,
        eligibleVersions,
        manualBase64: fileManualBase64,
        thumbBase64: fileThumbBase64,
        ...values,
      };

      saveOwnersManual(newOwnersManual);
    }
  };

  const remove = () => {
    if (id) {
      removeOwenersManual(id);
    }
  };

  const handleDownloadButton = () => {
    window.location.assign(mediaId!);
  };

  useEffect(() => {
    if (radioValue !== 2) {
      setRadioValue(0);
    }
  }, [radioValue]);

  useEffect(() => {
    if (type === "RADIO_MANUAL") {
      setRadioValue(2);
    }
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(save)}
      sx={{ display: "flex", flexDirection: "column", gap: 3 }}
    >
      <Box>
        <h1 style={{ fontSize: 14 }}>
          {name} {vehicleVersion} {vehicleVersionYear}
        </h1>
      </Box>
      <FormControl sx={{ display: "flex", flexDirection: "row" }} fullWidth>
        <p style={{ marginRight: "1rem", marginTop: 0 }}>
          {t("ownersManual.fileField").toString()}
        </p>
        <UploadPdf
          file={file}
          setFile={setFile}
          error={fileError}
          setError={setFileError}
          changeFileManualBase64={changeFileManualBase64}
        />
      </FormControl>
      {mediaId && (
        <Button
          variant="contained"
          type="button"
          onClick={handleDownloadButton}
        >
          {t("ownersManual.downloadButton").toString()}
        </Button>
      )}
      <FormControl fullWidth>
        <FormInputText
          fullWidth
          error={errors.partNumber}
          label={t("ownersManual.partnumber")}
          {...register("partNumber")}
          inputProps={{ maxLength: 60 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormInputText
          fullWidth
          error={errors.title}
          label={t("ownersManual.titleField")}
          {...register("title")}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormInputText
          multiline
          minRows={10}
          maxRows={10}
          fullWidth
          label={t("ownersManual.bodyField")}
          inputProps={{ maxLength: 1000 }}
          error={errors.body}
          {...register("body")}
        />
      </FormControl>
      <FormControl fullWidth>
        <p style={{ fontWeight: "bold", fontSize: 14 }}>
          {t("ownersManual.versionsField").toString()}
        </p>
        <CheckGroup
          options={versions}
          filter={{
            filterLabel: t("ownersManual.yearFilterField"),
            filterOptions,
            filterFunction,
          }}
          setStateOptions={setVersions}
          error={checkboxError}
          setError={setCheckboxError}
        />
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <FormInputText
          label={t("ownersManual.fileCategoryField")}
          select
          defaultValue={type}
          id="slcCategoria"
          sx={{ height: 35 }}
          error={!!errors.type}
          {...register("type")}
        >
          <MenuItem value="MAIN_MANUAL" onClick={() => setRadioValue(1)}>
            {`${t("ownersManual.mainManual")}`}
          </MenuItem>
          <MenuItem value="RADIO_MANUAL" onClick={() => setRadioValue(2)}>
            {`${t("ownersManual.radioManual")}`}
          </MenuItem>
          <MenuItem value="ADD_ONS" onClick={() => setRadioValue(3)}>
            {`${t("ownersManual.addOns")}`}
          </MenuItem>
        </FormInputText>
        <FormHelperText
          sx={{
            marginBottom: "1rem",
            fontSize: 14,
            color: "#ff0000 !important",
            marginLeft: 0,
          }}
          error={!!errors.type}
        >
          {errors.type?.message}
        </FormHelperText>
      </FormControl>

      {radioValue === 2 && (
        <Box
          sx={{
            marginTop: -3,
          }}
        >
          <p>{`${t("ownersManual.addImage")}`}</p>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <input
              accept=".png, .jpg, .jpeg"
              className="imageFile"
              type="file"
              name="fileInput"
              id="imageInput"
              onChange={(event) => {
                if (event.target.files?.length) {
                  convertImageToBase64(event.target.files[0]);
                }
              }}
            />

            <img
              src={thumbId ? thumbId : ""}
              alt="No document"
              width="200px"
              id="imageChosen"
            />
          </Box>
        </Box>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Button variant="contained" type="submit">
          {submitButtonTitle}
        </Button>
        {id && (
          <Button
            color="error"
            variant="contained"
            style={{ marginLeft: "1rem" }}
            onClick={() => {
              setConfirmationModal(true);
            }}
          >{`${t("ownersManual.deleteButton")}`}</Button>
        )}
      </div>
      {confirmationModal && (
        <ModalConfirmation
          openDelete={setConfirmationModal}
          confirmDelete={remove}
          title="Confirmar exclusão"
        />
      )}
    </Box>
  );
}
