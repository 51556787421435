import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import DataGridApi from "../../../../components/dataGridApi";
import { ISpreadsheet } from "../../../../interfaces/ISpreadsheet";
import { CONFIG } from "../../../../services/config";

interface Props {
  spreadsheet?: ISpreadsheet;
}

export default function Revisao({ spreadsheet }: Props) {
  const columns: GridColDef[] = [
    {
      field: "modelYear",
      flex: 1,
      headerName: t("digitalServicePlan.modelYear"),
      headerClassName: "header-style",
    },
    {
      field: "modelCode",
      flex: 1,
      headerName: t("digitalServicePlan.modelCode"),
      headerClassName: "header-style",
    },
    {
      field: "details",
      flex: 1,
      headerName: t("digitalServicePlan.details"),
      headerClassName: "header-style",
    },
    {
      field: "hasException",
      flex: 1,
      headerName: t("digitalServicePlan.hasException"),
      headerClassName: "header-style",
    },
    {
      field: "revisions",
      flex: 1,
      headerName: t("digitalServicePlan.freeRevisions"),
      headerClassName: "header-style",
    },
  ];
  return (
    <DataGridApi
      params1="&modelYear="
      labelParams1="Ano do modelo"
      params2="&modelCode="
      labelParams2="Código do modelo"
      host={CONFIG.SOB_API}
      endPoint={`excel/revisions/${spreadsheet?.id}?`}
      columnsData={columns}
    />
  );
}
