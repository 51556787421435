import { Box, Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import VehicleStatus from "../../components/home/vehicleStatus";
import { AuthContext } from "../../contexts/AuthContext";

export default function Home() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  return (
    <>
      <BoxContent title={t("menu.home")}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={6}>
            {user ? (
              <Box>
                <p>{`${t("home.greeting")} ${user ? user.name : ""}!`}</p>
                <p>{`${t("home.welcome")} :)`}</p>
              </Box>
            ) : (
              <p>{t("home.welcome")}</p>
            )}
          </Grid>
          {user && (
            <Grid item xs={6}>
              <p>
                <strong>{t("home.email")}</strong> {user.email}
              </p>
              <p>
                <strong>{t("home.profile")}</strong> {user.profile.profileName}
              </p>
            </Grid>
          )}
        </Grid>
      </BoxContent>
      <VehicleStatus />
    </>
  );
}
