import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, IconButton, MenuItem, Select } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import lineBreak from "../../../utils/lineBreak";

export default function OptionResponse() {
  const { t } = useTranslation();
  const [jsonFinal, setJsonFinal] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [positive, setPositive] = useState<string>("");
  const [negative, setNegative] = useState<string>("");
  const [iten, setIten] = useState<string>("");
  const [itens, setItens] = useState<any[]>([]);
  const [responseType, setResponseType] = useState<string>("");

  const generate = () => {
    const concat = lineBreak(description);
    let jsonGerado: any = {};

    if (responseType === "positiveNegative") {
      jsonGerado = {
        generic: [
          {
            response_type: "text",
            values: [
              {
                text_expression: {
                  concat,
                },
              },
            ],
            selection_policy: "sequential",
          },
          {
            options: [
              {
                label: positive,
                value: {
                  input: {
                    text: positive,
                  },
                },
              },
              {
                label: negative,
                value: {
                  input: {
                    text: negative,
                  },
                },
              },
            ],
            response_type: "option",
            repeat_on_reprompt: true,
          },
        ],
      };
    } else if (responseType === "schedule") {
      jsonGerado = {
        generic: [
          {
            response_type: "text",
            values: [
              {
                text_expression: {
                  concat,
                },
              },
            ],
            selection_policy: "sequential",
          },
          {
            options: [
              {
                label: positive,
                value: {
                  input: {
                    text: responseType,
                  },
                },
              },
              {
                label: negative,
                value: {
                  input: {
                    text: t("transformJson.negativeResponse"),
                  },
                },
              },
            ],
            response_type: "option",
            repeat_on_reprompt: true,
          },
        ],
      };
    } else if (responseType === "itens") {
      jsonGerado = {
        generic: [
          {
            response_type: "text",
            values: [
              {
                text_expression: {
                  concat,
                },
              },
            ],
            selection_policy: "sequential",
            repeat_on_reprompt: false,
          },
          {
            response_type: "option",
            repeat_on_reprompt: true,
            options: itens,
          },
        ],
      };
    }
    setJsonFinal(JSON.stringify(jsonGerado, null, 2));
  };

  const clear = () => {
    setDescription("");
    setPositive("");
    setNegative("");
    setIten("");
    setItens([]);
    setResponseType("");
    setJsonFinal("");
  };

  return (
    <Grid container>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={4}>
          <Select
            displayEmpty
            defaultValue=""
            value={responseType}
            labelId="typeJsonSelect"
            id="handleTypeJsonSelect"
            sx={{ height: 40, width: "100%" }}
            placeholder="Select"
            onChange={(type: any) => {
              setResponseType(type.target.value);
            }}
          >
            <MenuItem disabled value="">
              <em>{t("transformJson.typeResponse")}</em>
            </MenuItem>
            <MenuItem value="positiveNegative">
              {t("transformJson.positiveNegative")}
            </MenuItem>
            <MenuItem value="schedule">{t("transformJson.schedule")}</MenuItem>
            <MenuItem value="itens">{t("transformJson.itens")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {responseType !== "" ? (
        responseType === "itens" ? (
          <Grid container>
            <Grid container>
              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label={t("transformJson.description")}
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={5} sx={{ marginBottom: 2, marginRight: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label={t("transformJson.positive")}
                  variant="outlined"
                  value={iten}
                  onChange={(e) => setIten(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sx={{ marginLeft: 2 }}>
                <IconButton
                  color="primary"
                  aria-label="add"
                  disabled={iten !== "" ? false : true}
                  onClick={(e) => {
                    const aux = {
                      label: iten,
                      value: {
                        input: {
                          text: iten,
                        },
                      },
                    };
                    setItens([...itens, aux]);
                    setIten("");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container>
              {itens.map((aux: any, index: number) => {
                return (
                  <Grid container sx={{ marginBottom: 2 }}>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        size="small"
                        id="outlined-basic"
                        label={t("transformJson.text")}
                        variant="outlined"
                        value={aux.label || ""}
                        onChange={(e) => {
                          const newArr = [...itens];
                          newArr[index].label = e.target.value;
                          newArr[index].value.input.text = e.target.value;
                          setItens(newArr);
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} sx={{ marginLeft: 2 }}>
                      <IconButton
                        color="primary"
                        aria-label="add"
                        onClick={(e) => {
                          const newArr = [...itens];
                          newArr.splice(index, 1);
                          setItens(newArr);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("transformJson.description")}
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={5} sx={{ marginBottom: 2, marginRight: 1 }}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("transformJson.positive")}
                variant="outlined"
                value={positive}
                onChange={(e) => setPositive(e.target.value)}
              />
            </Grid>
            <Grid item xs={5} sx={{ marginBottom: 2 }}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("transformJson.negative")}
                variant="outlined"
                value={negative}
                onChange={(e) => setNegative(e.target.value)}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        )
      ) : null}
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item>
          <Button
            variant="contained"
            disabled={
              description !== "" &&
              (itens.length > 0 || (positive !== "" && negative !== ""))
                ? false
                : true
            }
            onClick={generate}
          >
            {t("button.Generate")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={clear}>
            {t("button.Clean")}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <pre
            style={{
              wordWrap: "break-word",
              maxWidth: "37rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {jsonFinal}
          </pre>
        </Grid>
        <Grid item>
          {jsonFinal !== "" ? (
            <Button
              disabled={false}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(jsonFinal);
              }}
              startIcon={<ContentCopyIcon />}
            >
              {t("button.Copy")}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
