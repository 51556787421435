import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { t } from "i18next";
import { Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import Errors from "./errors";
import DataGridApi from "../../components/dataGridApi";
import BoxContent from "../../components/boxContent";
import MyModal from "../../components/MyModal";
import { ISpreadsheet } from "../../interfaces/ISpreadsheet";
import { CONFIG } from "../../services/config";
import UploadSpreadsheet from "./uploadSpreadsheet";
import Modals from "./modals";

export default function DigitalServicePlan() {
  const [showErrors, setShowErrors] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [spreadSheet, setSpreadSheet] = useState<ISpreadsheet>();
  const [updateCount, setUpdateCount] = useState(0);
  const [enableUpdate, setEnableUpdate] = useState(true);

  const closeModals = () => {
    setShowErrors(false);
    setShowModal(false);
    setEnableUpdate(true);
  };

  const openErrors = (spreadSheet: ISpreadsheet) => {
    setSpreadSheet(spreadSheet);
    setShowErrors(true);
    setEnableUpdate(false);
  };

  const columns: GridColDef[] = [
    {
      field: "excelName",
      headerName: t("digitalServicePlan.excelName"),
      flex: 3,
      headerClassName: "header-style",
      renderCell({ row: { excelName } }: any) {
        const [name] = excelName.split(".");
        return <span>{name}</span>;
      },
    },
    {
      field: "excelType",
      headerName: t("digitalServicePlan.excelType"),
      flex: 2,
      headerClassName: "header-style",
    },
    {
      field: "createdAt",
      headerName: t("digitalServicePlan.createdAt"),
      flex: 1,
      headerClassName: "header-style",
      renderCell({ row: { createdAt } }: any) {
        const [date, hour] = createdAt.split(" ");
        return <span>{`${date.split("-").reverse().join("/")} ${hour}`}</span>;
      },
    },
    {
      field: "importsErros",
      headerName: t("digitalServicePlan.importsErros"),
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "processingStatus",
      headerName: t("digitalServicePlan.processingStatus"),
      flex: 1,
      headerClassName: "header-style",
      renderCell({ row }: any) {
        return (
          <span>{`${t(`digitalServicePlan.${row.processingStatus}`)}`}</span>
        );
      },
    },
    {
      field: "button",
      headerName: "",
      headerClassName: "header-style",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell({ row }: any) {
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setSpreadSheet(row);
                setModalType(row.excelType);
                setShowModal(true);
              }}
            >
              Visualizar
            </Button>
            {!!row.importsErros && (
              <Button
                variant="contained"
                size="small"
                onClick={() => openErrors(row)}
              >
                Ver erros
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <BoxContent title={`${t("digitalServicePlan.title")}`}>
      <Box>
        <UploadSpreadsheet
          updateFiles={() => {
            setUpdateCount((count) => count + 1);
          }}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <span>{`${t("digitalServicePlan.sendedFiles")}`}</span>
          <Button
            style={{ height: "3rem", padding: "0 1.5rem" }}
            variant="contained"
            size="small"
            onClick={() => setUpdateCount((count) => count + 1)}
          >
            Atualizar status
          </Button>
        </div>
        <DataGridApi
          dataUpdated={updateCount}
          host={CONFIG.SOB_API}
          endPoint="excel/summaries?"
          columnsData={columns}
        />
      </Box>
      {showErrors && (
        <MyModal
          modalChange={closeModals}
          title={spreadSheet?.excelName.split(".")[0] || ""}
        >
          <Errors spreadSheet={spreadSheet} />
        </MyModal>
      )}
      {showModal && (
        <Modals
          modalType={modalType}
          onClose={closeModals}
          spreadsheet={spreadSheet}
        />
      )}
    </BoxContent>
  );
}
