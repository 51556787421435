import { Box, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTranslation } from "react-i18next";

export function Unauthorized() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ marginTop: "100px" }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ textAlign: "center" }}>
        <LockOutlinedIcon
          sx={{ color: "var(--blue-900)", width: "135px", height: "135px" }}
        />
        <Box component="p" sx={{ fontWeight: "700", fontSize: "24px" }}>
          {t("unauthorized.title")}
        </Box>
        <Box component="span" sx={{ fontWeight: "400", fontSize: "18px" }}>
          {t("unauthorized.content")}
        </Box>
      </Box>
    </Box>
  );
}
