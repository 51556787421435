import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import BoxContent from "../../components/boxContent";
import { FormInputText } from "../../components/form/FormInputText";
import useMarketingCloud from "../../hooks/MarketingCloud";
import MyModal from "../../components/MyModal";
import Permissions from "./permissions";

interface FormData {
  initialDate: string;
  finalDate: string;
}

export default function MarketingCloud() {
  const { generateRelatory, relatory } = useMarketingCloud();
  const [showPermissions, setShowPermissions] = useState(false);
  const [formValues, setFormValues] = useState<FormData>();

  useEffect(() => {
    if (relatory?.status === "complete") {
      alert("O relatório já está disponível para download.");
    }
  }, [relatory]);

  const convertToDate = (value: string) =>
    moment(`${value?.replace(/\//g, "-")}`);

  const toBeLessThanNow = {
    name: "toBeLesserThanNow",
    test: (value = "") => {
      const date = convertToDate(value);
      return date.isBefore(Date.now());
    },
    message: "Data superior a atual.",
    exclusive: true,
  };

  const toBeGreatherThanInit = (initial = "") => ({
    name: "toBeGreatherThanInit",
    test: (final = "") => {
      const finalDate = convertToDate(final);
      const initialDate = convertToDate(initial);
      return finalDate.isAfter(initialDate);
    },
    message: "Data inferior à inicial.",
    exclusive: true,
  });

  const downloadManualFormData = yup.object().shape({
    initialDate: yup
      .string()
      .required("Data obrigatória")
      .test({ ...toBeLessThanNow }),
    finalDate: yup
      .string()
      .required("Data obrigatória")
      .test({ ...toBeLessThanNow })
      .when("initialDate", (initial) => {
        if (initial) {
          return yup.string().test(toBeGreatherThanInit(initial));
        }
        return yup.string().required("Data obrigatória");
      }),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(downloadManualFormData),
  });

  const generate = () => {
    if (formValues) {
      generateRelatory(formValues)
        .then(() => {
          alert("Extração de dados iniciada!");
        })
        .catch((error) =>
          alert(error?.message || "Ocorreu um erro desconhecido!"),
        );
    }
  };

  const send = (values: FormData) => {
    setFormValues(values);
    const cookies = document.cookie?.split(";") || [];
    const permissionCookie = cookies.find((cookie) =>
      cookie.includes("mk_permission"),
    );
    if (!permissionCookie) {
      setShowPermissions(true);
    } else {
      generate();
    }
  };

  const download = () => {
    window.open(relatory.fileUrl, "_blank");
    window.sessionStorage.removeItem("mk_relatory");
  };

  const onClose = () => {
    setShowPermissions(false);
  };

  const onAccept = (permission: boolean) => {
    setShowPermissions(false);
    if (permission) {
      generate();
    }
  };

  return (
    <BoxContent
      title={t("marketingCloud.title")}
      button
      buttonType="submit"
      button2
      titleButton2="Baixar relatório"
      handleClick2={download}
      disabledButton={relatory?.status === "generating"}
      disabledButton2={relatory?.status !== "complete"}
      titleButton={t("marketingCloud.exportDataButton")}
      component="form"
      onSubmit={handleSubmit(send)}
    >
      <Box style={{ display: "flex", gap: "4rem" }}>
        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
          <div>
            <FormInputText
              type="date"
              label="De"
              error={errors.initialDate}
              {...register("initialDate")}
            />
          </div>
          <div>
            <FormInputText
              type="date"
              label="Até"
              error={errors.finalDate}
              {...register("finalDate")}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {relatory?.status === "generating" && (
            <CircularProgress style={{ marginBottom: "1rem" }} size={25} />
          )}
        </div>
      </Box>
      {showPermissions && (
        <MyModal title="Aviso de Privacidade" modalChange={onClose}>
          <Permissions onClose={onAccept} />
        </MyModal>
      )}
    </BoxContent>
  );
}
