import { AlertColor } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import AddManual from "../../components/manualInstructions/addManual";
import MyModal from "../../components/MyModal";
import { AuthContext } from "../../contexts/AuthContext";

export default function AddVehicleManual() {
  const { t } = useTranslation();

  const [openModalAddVehicleManual, setOpenModalAddVehicleManual] =
    useState(false);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);
  const {
    country: { countryCode },
  } = useContext(AuthContext);

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddVehicleManual(false);
    // setOpenModalEditTerms(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const editTerms = (e: any) => {
    console.log("e.row", e.row);
  };

  const handleClickModaAddTerms = () => {
    setOpenModalAddVehicleManual(true);
  };

  const callback = async (Model: any) => {
    // Add interface IModelData

    setOpenModalAddVehicleManual(true);

    // try {
    //   await axios
    //     .get(`${CONFIG.API_URL}vehicles/${Model.id}`, tokenHeaders)
    //     .then((response) => {
    //       setModelSelected(response.data);
    //     });
    //   setOpenModalEditModel(true);
    // } catch (error: any) {
    //   return alert("erro");
    // }

    // setModelSelected(Model);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("terms.title"),
      flex: 1,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.manualInstructions")}
      button
      titleButton={t("terms.Add ManualInstructions")}
      handleClick={handleClickModaAddTerms}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <DataGridApi
        onRowClick={editTerms}
        // dataUpdated={dataUpdated}
        endPoint={`vehicles/pageable?countryCode=${countryCode}&`}
        columnsData={columns}
        // messageError={messageError}
      />

      {openModalAddVehicleManual && (
        <MyModal
          title={t("terms.Add terms")}
          modalChange={setOpenModalAddVehicleManual}
        >
          <AddManual
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
