import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import DataGridApi from "../../../../components/dataGridApi";
import { ISpreadsheet } from "../../../../interfaces/ISpreadsheet";
import { CONFIG } from "../../../../services/config";

interface Props {
  spreadsheet?: ISpreadsheet;
}

export default function Id4WithoutDSP({ spreadsheet }: Props) {
  const columns: GridColDef[] = [
    {
      field: "vin",
      flex: 3,
      headerName: t("digitalServicePlan.warrantyVin"),
      headerClassName: "header-style",
    },
  ];

  return (
    <DataGridApi
      params1="&param="
      labelParams1="Chassi"
      host={CONFIG.SOB_API}
      endPoint={`excel/warranty/${spreadsheet?.id}?`}
      columnsData={columns}
    />
  );
}
