import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IVehicle } from "../../interfaces/IVehicle";
import { FormInputText } from "../form/FormInputText";

interface IGalleryBox {
  data: IVehicle;
  onClick: any;
}

export default function ModelGallery({ data, onClick }: IGalleryBox) {
  const { t } = useTranslation();

  const handleSendData = () => {
    onClick(data);
  };

  return (
    <Grid key={data.id} item xs={3}>
      <Box
        sx={{
          width: 200,
          overflow: "hidden",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: "relative",
            transitionDuration: "300ms",
            cursor: "pointer",
            height: "150px",
            display: "flex",
            placeItems: "center",

            "&:hover": {
              scale: "1.05",
            },
          }}
        >
          <img
            src={`${data.teaserMediaId}?nocache=${Date.now()}`}
            alt={data.name}
            title={data.name}
            width="100%"
            // height="150px"
            style={{ cursor: "pointer" }}
          />
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 10,
              transitionDuration: "300ms",
              justifyContent: "center",
              placeItems: "center",
              opacity: 0,
              "&:hover": {
                opacity: 0.9,
              },
            }}
          >
            <Button variant="contained" onClick={handleSendData}>
              {t("button.Edit")}
            </Button>
          </Box>
        </Box>
      </Box>
      <FormInputText
        label=""
        defaultValue={data.name}
        disabled
        sx={{ width: 200 }}
      />
    </Grid>
  );
}
