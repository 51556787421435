import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { CONFIG } from "../services/config";

interface Relatory {
  fileName: string;
  fileUrl: string;
  status: "notStarted" | "generating" | "complete";
}

export default function useMarketingCloud() {
  const [relatory, setRelatory] = useState<Relatory>({
    status: "notStarted",
    fileName: "",
    fileUrl: "",
  });
  const {
    tokenHeaders,
    user: { email },
    country: { countryCode },
  } = useContext(AuthContext);

  const verifyRelatoryStatus = async () => {
    const sessionStore = window.sessionStorage.getItem("mk_relatory");
    if (!sessionStore) {
      return;
    }

    const mkRelatory = JSON.parse(sessionStore) as Relatory;
    const { fileName, fileUrl } = mkRelatory;
    const header = { headers: { ...tokenHeaders.headers, fileName } };

    const result = await axios.get(
      `${CONFIG.API_URL}user/checkExtraction`,
      header,
    );

    if (result.data.status) {
      setRelatory({ fileName, fileUrl, status: "complete" });
      const mkRelatory = JSON.parse(sessionStore);
      mkRelatory.status = "complete";
      window.sessionStorage.setItem("mk_relatory", JSON.stringify(mkRelatory));
    }
    return result.data.status;
  };
  const startRelatoryStatusLoopCheck = useCallback(async () => {
    while (true) {
      const idFinished = await verifyRelatoryStatus();
      if (idFinished) {
        break;
      }
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 10000));
    }
  }, [verifyRelatoryStatus, relatory]);
  useEffect(() => {
    const presentRelatory = window.sessionStorage.getItem("mk_relatory");
    if (presentRelatory) {
      const { status, fileName, fileUrl }: Relatory =
        JSON.parse(presentRelatory);
      setRelatory({ status, fileName, fileUrl });
      if (status === "generating") {
        startRelatoryStatusLoopCheck();
      }
    }
  }, []);

  const generateRelatory = (interval: {
    initialDate: string;
    finalDate: string;
  }) => {
    const body = { ...interval, country: countryCode };
    const header = { headers: { ...tokenHeaders.headers, email } };
    return axios
      .post(`${CONFIG.API_URL}user/extractUsers`, body, header)
      .then(({ data: { fileName, fileUrl } }) => {
        const relatory: Relatory = { fileName, fileUrl, status: "generating" };
        setRelatory(relatory);
        window.sessionStorage.setItem("mk_relatory", JSON.stringify(relatory));
        startRelatoryStatusLoopCheck();
      });
  };
  return { generateRelatory, relatory };
}
