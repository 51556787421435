import { Controller, SubmitHandler, useForm } from "react-hook-form";

import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";

import { CONFIG } from "../../../services/config";
import { FormInputText } from "../../form/FormInputText";
import ActionButtons from "../../MyModal/ActionButtons";
import ModalConfirmation from "../../MyModal";
import { ISystem } from "../../../interfaces/ISystem";
import { LabelContainer, LabelForm } from "../Form/label";

type FormValues = {
  id: number;
  name: string;
  systemCode: string;
  active: boolean;
};

export default function EditSystem({
  data,
  watchDataChange,
  messageError,
}: ISystem) {
  const { t } = useTranslation();
  yup.setLocale({
    mixed: {
      required: t(`yup.mixed.required`),
      notType: t(`yup.mixed.notType`),
    },
    string: {
      min: t(`yup.string.min`),
      max: t(`yup.string.max`),
      email: t(`yup.string.email`),
    },
    number: {
      min: t(`yup.number.min`),
      max: t(`yup.number.max`),
      positive: t(`yup.number.positive`),
      integer: t(`yup.number.integer`),
    },
    date: {
      min: t(`yup.date.min`),
      max: t(`yup.date.max`),
    },
    array: {
      min: t(`yup.array.min`),
      max: t(`yup.array.max`),
    },
  });
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);
  const [confirmeInactive, setConfirmInactive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalExisting, setOpenModalExisting] = useState(false);

  const addFormData = yup.object().shape({
    name: yup.string().max(20).required(t("faq.system.Required name")),
    systemCode: yup
      .string()
      .max(20)
      .required(t("faq.system.Required systemCode"))
      .label(t("faq.system.Name")),

    active: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(addFormData),
  });

  const handleInactiveStatus = (event: any) => {
    if (!confirmeInactive && data?.active && !event.target.checked) {
      setOpenModal(true);
    }
  };
  const handleCancelInactivation = () => {
    setValue("active", true);
    setOpenModal(false);
    setConfirmInactive(false);
  };
  const editContact: SubmitHandler<FormValues> = async (values) => {
    try {
      if (!confirmeInactive && data?.active && !values.active) {
        setOpenModal(true);
      } else {
        await axios.put(
          `${CONFIG.API_URL}faq/system/${data?.id}`,
          {
            name: values.name,
            systemCode: values.systemCode,
            active: values.active,
          },
          tokenHeaders,
        );
        watchDataChange(t("faq.system.System edited"));
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return messageError(t("faq.system.channel_exists"));
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
  };

  const deleteContact = async () => {
    try {
      await axios.delete(`${CONFIG.API_URL}faq/system/${data?.id}`, {
        headers: tokenHeaders.headers,
        data: null,
      });
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setOpenModalExisting(true);
        return;
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
    watchDataChange(t("faq.system.System deleted"));
  };

  const handleConfirmInactivation = () => {
    setConfirmInactive(true);
    setOpenModal(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editContact)}>
      <LabelContainer>
        <LabelForm
          required
          label={t("faq.system.Name")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "20",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.system.Name")}
            error={errors.name}
            {...register("name")}
            defaultValue={data?.name}
          />
        </LabelForm>
        <LabelForm
          required
          label={t("faq.system.Name")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "20",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.system.Name")}
            error={errors.systemCode}
            {...register("systemCode")}
            defaultValue={data?.systemCode}
          />
        </LabelForm>
        <LabelForm
          label={t("faq.system.Status")}
          requires={[
            {
              msg: t("faq.requires.active_inactive"),
            },
          ]}
        >
          <Grid item xs={6}>
            <FormGroup sx={{ marginBottom: "1rem" }}>
              <FormControlLabel
                control={
                  <Controller
                    name="active"
                    control={control}
                    defaultValue={data?.active}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          handleInactiveStatus(e);
                        }}
                      />
                    )}
                  />
                }
                label=" "
              />
            </FormGroup>
          </Grid>
        </LabelForm>
      </LabelContainer>
      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteContact}
      />
      {openModal && (
        <ModalConfirmation
          title={t("faq.system.modal.edit.title")}
          modalChange={setOpenModal}
        >
          <Box style={{ padding: "0 32px" }}>
            <p style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
              {t("faq.system.modal.edit.description")}
            </p>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Button variant="contained" onClick={handleConfirmInactivation}>
                {t("faq.system.modal.edit.buttonTitle")}
              </Button>
              <Button
                color="primary"
                style={{
                  color: "#2196f3",
                }}
                variant="outlined"
                onClick={handleCancelInactivation}
              >
                {t("faq.system.modal.edit.buttonTitleCancel")}
              </Button>
            </Box>
          </Box>
        </ModalConfirmation>
      )}
      {openModalExisting && (
        <ModalConfirmation
          title={t("faq.system.modal.delete.title")}
          modalChange={setOpenModalExisting}
        >
          <Box style={{ padding: "0 32px" }}>
            <p style={{ fontSize: 16, marginBottom: 16, fontWeight: 400 }}>
              {t("faq.system.modal.delete.description")}
            </p>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => setOpenModalExisting(false)}
              >
                {t("faq.system.modal.delete.ok")}
              </Button>
            </Box>
          </Box>
        </ModalConfirmation>
      )}
    </Box>
  );
}
