import {
  AlertColor,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { GridColDef } from "@mui/x-data-grid";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import { FormInputText } from "../../components/form/FormInputText";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import ActionButtons from "../../components/MyModal/ActionButtons";
import { AuthContext } from "../../contexts/AuthContext";
import { ICountry } from "../../interfaces/ICountry";
import { ICountryRow } from "../../interfaces/ICountryRow";
import { CONFIG } from "../../services/config";
import AddCountry from "../../components/contries/addCountry";
import EditCountry from "../../components/contries/editCountry";

export default function Countries() {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const ALPHA_REGEX = /^[a-zA-Z-\s]+$/;

  // const addFormData = yup.object().shape({
  //   country: yup.string().required(t("countries.Required country")),
  //   countryCode: yup.string().required(t("countries.Required country code")),
  //   prefix: yup.string().required(t("countries.Required prefix")),
  //   countryGroup: yup.string().required(t("countries.Required country group")),
  //   active: yup.boolean(),
  // });

  // const editFormData = yup.object().shape({
  //   country: yup.string().required(t("countries.Required country")),
  //   prefix: yup.string().required(t("countries.Required prefix")),
  //   countryGroup: yup.string().required(t("countries.Required country group")),
  //   active: yup.boolean(),
  // });

  const [countrySelected, setCountrySelected] = useState<ICountryRow | any>();
  const [openModalAddCountry, setOpenModalAddCountry] = useState(false);
  const [openModalEditCountry, setOpenModalEditCountry] = useState(false);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  // const [imageBase64, setImageBase64] = useState<any>(null);

  // const convertImageToBase64 = (event: any) => {
  //   const file = event.target.files![0];
  //   const preview = document.getElementById("imageChosen") as HTMLImageElement;

  //   if (preview) {
  //     const src = URL.createObjectURL(file);
  //     preview.src = src;
  //   }

  //   if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
  //     alert(t("alert.validImage"));
  //     const imageFile = document.querySelector(
  //       ".imageFile",
  //     ) as HTMLInputElement;
  //     imageFile.value = "";
  //     return false;
  //   }

  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     let image: any = reader.result?.toString();
  //     image = image?.split(",");

  //     setImageBase64(image[1]);
  //   };

  //   reader.readAsDataURL(file);
  // };

  const updateData = (message: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Success");
    setMessageQuickAlert(message ? message : "");
    setSeverityAlert("success");
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddCountry(false);
    setOpenModalEditCountry(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddCountry = () => {
    setOpenModalAddCountry(true);
  };

  // const { register, handleSubmit, reset, formState, clearErrors } =
  //   useForm<ICountry>({
  //     resolver: yupResolver(addFormData),
  //   });

  // const { errors } = formState;

  // const {
  //   register: registerEdit,
  //   handleSubmit: handleSubmitEdit,
  //   reset: resetEdit,
  //   formState: formStateEdit,
  // } = useForm<ICountry>({
  //   resolver: yupResolver(editFormData),
  // });

  // const { errors: errorsEdit } = formStateEdit;

  // useEffect(() => {
  //   clearErrors();
  //   reset();
  //   setImageBase64("");
  // }, [openModalAddCountry, openModalEditCountry]);

  // const addCountry: SubmitHandler<ICountry | FieldValues> = async (values) => {
  //   let updatedActive = 0;
  //   if (values.active) {
  //     updatedActive = 1;
  //   }

  //   try {
  //     await axios.post(
  //       `${CONFIG.API_URL}country`,
  //       {
  //         name: values.country,
  //         code: values.countryCode,
  //         prefix: values.prefix,
  //         cgroup: values.countryGroup,
  //         active: updatedActive,
  //         documentImg: imageBase64,
  //       },
  //       tokenHeaders,
  //     );
  //   } catch (error: any) {
  //     return messageError(error.response.data.message);
  //   }

  //   setMessageQuickAlert(t("countries.Country added"));
  //   watchDataChange(t("countries.Country added"));
  //   reset();
  //   setOpenModalAddCountry(false);
  // };

  const editCountry = (e: ICountryRow) => {
    setOpenModalEditCountry(true);
    // resetEdit();
    setCountrySelected(e.row);
  };

  // const deleteCountry = async () => {
  //   try {
  //     await axios.delete(
  //       `${CONFIG.API_URL}country?countryCode=${countrySelected.countryCode}`,
  //       tokenHeaders,
  //     );
  //   } catch (error: any) {
  //     return messageError(error.response.data.message);
  //   }

  //   // setMessageQuickAlert(t("countries.Country deleted"));
  //   watchDataChange(t("countries.Country deleted"));
  // };

  // const editCountryChanges: SubmitHandler<ICountry | FieldValues> = async (
  //   values,
  // ) => {
  //   let updatedActive = 0;
  //   if (values.active) {
  //     updatedActive = 1;
  //   }

  //   const countryCode = document.querySelector(
  //     "#editCountryCode",
  //   ) as HTMLInputElement | null;

  //   try {
  //     await axios.put(
  //       `${CONFIG.API_URL}country?countryCode=${countrySelected.countryCode}`,
  //       {
  //         name: values.country,
  //         code: countryCode?.value,
  //         prefix: values.prefix,
  //         cgroup: values.countryGroup,
  //         active: updatedActive,
  //         documentImg: imageBase64,
  //       },
  //       tokenHeaders,
  //     );
  //   } catch (error: any) {
  //     return messageError(error.response.data.message);
  //   }

  //   // setMessageQuickAlert(t("countries.Country edited"));
  //   watchDataChange(t("countries.Country edited"));
  // };

  // const switchActive = async () => {
  //   const active = document.querySelector(
  //     "#editActive",
  //   ) as HTMLInputElement | null;

  //   let updatedActive;
  //   if (active?.checked) {
  //     updatedActive = 1;
  //   } else {
  //     updatedActive = 0;
  //   }

  //   await axios.get(
  //     `${CONFIG.API_URL}country/activeDeactive?countryCode=${countrySelected.countryCode}`,

  //     tokenHeaders,
  //   );
  //   const dataChange = dataUpdated + 1;
  //   setDataUpdated(dataChange);
  // };

  // const deleteImage = async () => {
  //   try {
  //     await axios.get(
  //       `${CONFIG.API_URL}country/countryImg?countryCode=${countrySelected.countryCode}`,

  //       tokenHeaders,
  //     );
  //   } catch (error: any) {
  //     return messageError(error.response.data.message);
  //   }

  //   setImageBase64("");
  //   const preview = document.getElementById("imageChosen") as HTMLImageElement;
  //   preview.src = "";
  //   setMessageQuickAlert(t("countries.Image deleted"));
  //   updateData();
  // };

  const columns: GridColDef[] = [
    {
      field: "country",
      headerName: t("countries.Country name"),
      width: 252,
      headerClassName: "header-style",
    },
    {
      field: "countryCode",
      headerName: t("countries.Country code"),
      width: 121,
      headerClassName: "header-style",
    },
    {
      field: "prefix",
      headerName: t("countries.Prefix"),
      width: 123,
      headerClassName: "header-style",
    },
    {
      field: "countryGroup",
      headerName: t("countries.Country group"),
      width: 121,
      headerClassName: "header-style",
      renderCell({ row }: any) {
        const { countryGroup }: { countryGroup: string } = row;
        return <span>{countryGroup.toUpperCase()}</span>;
      },
    },
    {
      field: "document",
      headerName: t("countries.Image"),
      renderCell: (params) =>
        params.value ? (
          <img src={params.value} alt="No document" width="40px" />
        ) : (
          ""
        ),
      width: 120,
      headerClassName: "header-style",
    },
    {
      field: "active",
      headerName: t("countries.Active"),
      width: 121,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("countries.countries")}
      button
      titleButton={t("countries.Add country")}
      handleClick={handleClickModaAddCountry}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      {openModalAddCountry && (
        <MyModal
          title={t("countries.Add country")}
          modalChange={setOpenModalAddCountry}
        >
          <AddCountry
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
          {/* <Grid
            container
            spacing={2}
            component="form"
            onSubmit={handleSubmit(addCountry)}
          >
            <Grid item xs={6}>
              <FormInputText
                onKeyDown={(event) => {
                  if (!ALPHA_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                label={t("countries.Country name")}
                error={errors.country}
                {...register("country")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                onKeyDown={(event) => {
                  if (!ALPHA_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 2,
                }}
                label={t("countries.Country code")}
                error={errors.countryCode}
                {...register("countryCode")}
              />
            </Grid>

            <Grid item xs={6}>
              <FormInputText
                type="number"
                inputProps={{
                  max: 999,
                }}
                label={t("countries.Prefix")}
                error={errors.prefix}
                {...register("prefix")}
              />
            </Grid>

            <Grid item xs={6}>
              <FormInputText
                onKeyDown={(event) => {
                  if (!ALPHA_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 3,
                }}
                label={t("countries.Country group")}
                error={errors.countryGroup}
                {...register("countryGroup")}
              />
            </Grid>

            <Grid item xs={6}>
              <input
                accept=".png, .jpg, .jpeg"
                className="imageFile"
                type="file"
                name="fileInput"
                onChange={(event) => {
                  convertImageToBase64(event);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormGroup sx={{ marginBottom: "1rem" }}>
                <FormControlLabel
                  control={
                    <Switch
                      id="addActive"
                      {...register("active")}
                      defaultChecked
                    />
                  }
                  label={t("countries.Active")}
                />
              </FormGroup>
            </Grid>

            <Box>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: "1rem" }}
              >
                {t("button.Add")}
              </Button>
            </Box>
          </Grid> */}
        </MyModal>
      )}

      <DataGridApi
        onRowClick={editCountry}
        dataUpdated={dataUpdated}
        endPoint="country/pg?"
        columnsData={columns}
        messageError={messageError}
      />

      {openModalEditCountry && (
        <MyModal
          title={t("countries.Edit country")}
          modalChange={setOpenModalEditCountry}
          // component="form"
          // onSubmit={handleSubmitEdit(editCountryChanges)}
        >
          <EditCountry
            watchDataChange={watchDataChange}
            messageError={messageError}
            countrySelected={countrySelected}
            updateData={updateData}
          />
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormInputText
                onKeyDown={(event) => {
                  if (!ALPHA_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                label={t("countries.Country name")}
                id="editCountry"
                defaultValue={countrySelected?.country}
                error={errorsEdit.country}
                {...registerEdit("country")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                onKeyDown={(event) => {
                  if (!ALPHA_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 2,
                }}
                label={t("countries.Country code")}
                id="editCountryCode"
                defaultValue={countrySelected?.countryCode}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputText
                type="number"
                inputProps={{
                  max: 999,
                }}
                label={t("countries.Prefix")}
                id="editPrefix"
                defaultValue={countrySelected?.prefix}
                error={errorsEdit.prefix}
                {...registerEdit("prefix")}
              />
            </Grid>

            <Grid item xs={6}>
              <FormInputText
                onKeyDown={(event) => {
                  if (!ALPHA_REGEX.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 3,
                }}
                label={t("countries.Country group")}
                id="editCountryGroup"
                defaultValue={countrySelected?.countryGroup}
                error={errorsEdit.countryGroup}
                {...registerEdit("countryGroup")}
              />
            </Grid>

            <Grid item xs={6}>
              <input
                accept=".png, .jpg, .jpeg"
                className="imageFile"
                type="file"
                name="fileInput"
                onChange={(event) => {
                  convertImageToBase64(event);
                  // imageTest(event);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                {countrySelected?.active ? (
                  <FormControlLabel
                    control={
                      <Switch
                        onClick={switchActive}
                        id="editActive"
                        defaultChecked
                        {...registerEdit("active")}
                      />
                    }
                    label={t("countries.Active")}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        onClick={switchActive}
                        id="editActive"
                        {...registerEdit("active")}
                      />
                    }
                    label={t("countries.Active")}
                  />
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={6} id="imagePrev">
              {countrySelected.document && (
                <>
                  <span>{t("countries.Current image")}:</span>
                  <br />
                  <br />
                  <img
                    src={countrySelected?.document}
                    alt="No document"
                    width="100px"
                    id="imageChosen"
                  />
                  <br />
                  <Box
                    style={{
                      color: "var(--red-100)",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={deleteImage}
                  >
                    Excluir imagem
                  </Box>
                </>
              )}
            </Grid>
          </Grid>

          <ActionButtons
            typeButton="submit"
            saveName={t("button.Save")}
            confirmDelete={deleteCountry}
          /> */}
        </MyModal>
      )}
    </BoxContent>
  );
}
