import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";

export default function SelectLink({ title, iconChange, ...rest }: any) {
  return (
    <Box
      sx={{
        height: "1rem",
        paddingLeft: "1rem",
        paddingY: "1rem",
        overflow: "hidden",
        boxShadow: "0 1px 3px rgb(0 0 0 / 15%)",
        verticalAlign: "center",
        color: "var(--gray-100)",
        cursor: "pointer",
        display: "flex",
      }}
    >
      <a
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
        {...rest}
      >
        <span>{title}</span>
        <Box
          sx={{
            paddingRight: "1rem",
            paddingLeft: "1rem",
            display: "flex",
            placeItems: "center",
          }}
        >
          {iconChange ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </Box>
      </a>
    </Box>
  );
}
