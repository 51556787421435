// import { Button } from "@mui/material";
import { AlertColor } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import AddNewContact from "../../components/support/addNewContact";
import EditContact from "../../components/support/editContact";
import EditContactList from "../../components/support/editContactList";

export default function Support() {
  const { t } = useTranslation();

  const [openModalAddContact, setOpenModalAddPath] = useState(false);
  const [openModalEditContact, setOpenModalEditContact] = useState(false);

  const [contact, setContactSelected] = useState<any>();

  const editSupport = (e: any) => {
    setOpenModalEditContact(true);
    setContactSelected(e.row);
  };

  const handleClickModaAddContact = () => {
    setOpenModalAddPath(true);
  };

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const updateData = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddPath(false);
    setOpenModalEditContact(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("support.Name"),
      width: 429,
      headerClassName: "header-style",
    },
    {
      field: "description",
      headerName: t("support.Description"),
      width: 429,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.support")}
      button
      titleButton={t("support.Add contact")}
      handleClick={handleClickModaAddContact}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <DataGridApi
        onRowClick={editSupport}
        dataUpdated={dataUpdated}
        messageError={messageError}
        endPoint="contacts?"
        columnsData={columns}
      />

      {openModalAddContact && (
        <MyModal
          title={t("support.Add contact")}
          modalChange={setOpenModalAddPath}
        >
          <AddNewContact
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditContact && (
        <MyModal
          title={t("support.Edit contact")}
          modalChange={setOpenModalEditContact}
        >
          {contact.type === "list" ? (
            <EditContactList
              data={contact}
              watchDataChange={watchDataChange}
              updateData={updateData}
              messageError={messageError}
            />
          ) : (
            <EditContact
              data={contact}
              watchDataChange={watchDataChange}
              messageError={messageError}
            />
          )}
        </MyModal>
      )}
    </BoxContent>
  );
}
