import { Button, CircularProgress, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { GridCloseIcon } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";

interface IReportData {
  processed: string;
  imported: string;
  failed: string;
}

export default function ImportCalculator({
  watchDataChange,
  messageError,
  updateData,
}: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [report, setReport] = useState(false);
  const [reportData, setReportData] = useState<IReportData>();
  const [loading, setLoading] = useState(false);

  const convertImageToBase64 = async (event: any) => {
    const file = event.target.files![0];
    setLoading(true);

    // console.log("file", file);

    if (!file.name.match(/\.(csv)$/)) {
      alert(t("alert.validCsv"));
      const csvFileClass = document.querySelector(
        ".csvFileClass",
      ) as HTMLInputElement;
      csvFileClass.value = "";
      setLoading(false);
      return false;
    }

    const { authorization, idToken, code, countryCode } = tokenHeaders.headers;

    const newHeader = {
      headers: {
        authorization,
        idToken,
        code,
        countryCode,
        "Content-Type": "multipart/form-data",
      },
    };

    // console.log("new", newHeader);

    try {
      await axios
        .post(
          `${CONFIG.API_URL}calculator/mass`,
          {
            file,
          },
          newHeader,
        )
        .then((response) => {
          console.log("response formdata", response);
          setReport(true);
          setReportData(response.data);
        });
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    watchDataChange("Atualizado");
    setLoading(false);
  };

  const refreshData = () => {
    setReport(false);
    updateData();
  };

  return (
    <>
      {!report && (
        <Box>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box>
              <strong style={{ fontSize: 14 }}>Template .csv</strong>
              <br />
              <span style={{ fontSize: 12 }}>
                {t("calculator.uploadInstructions")}
              </span>
            </Box>

            <Box>
              <Button variant="contained">
                <label htmlFor="csvFile">
                  <input
                    accept=".csv"
                    className="csvFileClass"
                    type="file"
                    name="csvFile"
                    id="csvFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      convertImageToBase64(event);
                    }}
                  />
                  {t("calculator.importFile")}
                </label>
              </Button>
            </Box>
          </Stack>

          <Box
            sx={{
              marginBottom: 2,
              fontSize: 12,
              backgroundColor: "var(--gray-100)",
              paddingY: 1,
              paddingX: 2,
              borderRadius: 2,
              lineHeight: 1,
            }}
          >
            <p>
              MODEL_CODE; MODEL_YEAR; MODEL_EDITION; IS_FLEX_FUEL;
              AUTONOMY_ETH_CITY; AUTONOMY_ETH_ROAD; AUTONOMY_ETH_COMB;
              AUTONOMY_GAS_CITY; AUTONOMY_GAS_ROAD; AUTONOMY_GAS_COMB;
              CO2_ETH_CITY; CO2_ETH_ROAD; CO2_ETH_COMB; CO2_GAS_CITY;
              CO2_GAS_ROAD; CO2_GAS_COMB
            </p>
            <p> ABC123;2022;1;1;2;2;2;2;2;2;2;2;2;2;2;2</p>
            <p> DEF456;2019;0;0;3;3;3;3;3;3;3;3;3;3;3;3</p>
            <p>
              CH14BY;2021;2;1;8.3;6.4;7.0;3.3;3.0;2.1;9.1;6.0;5.5;5.1;5.1;8.3
            </p>
          </Box>
        </Box>
      )}

      {report && (
        <Box
          sx={{
            marginBottom: 2,
            paddingY: 1,
            paddingX: 2,
            backgroundColor: "var(--gray-100)",
            borderRadius: 2,
            postion: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
              marginTop: 1,
            }}
          >
            <span>{t("manual.importReport")}</span>

            <GridCloseIcon
              onClick={refreshData}
              sx={{
                cursor: "pointer",
                // position: "absolute",
              }}
            />
          </Box>
          <li>{reportData?.failed}</li>

          <li>{reportData?.imported}</li>

          <li>{reportData?.processed}</li>
        </Box>
      )}

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
