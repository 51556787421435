import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { AlertColor, Box, Button, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { IPills } from "../../interfaces/IPills";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ModalConfirmation from "../MyModal/modalConfirmation";
import DataGridApi from "../dataGridApi";

export default function ManageCategories({
  watchDataChange,
  messageError,
  dataUpdated,
  updateData,
}: any) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const apiRef = useGridApiRef();

  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idDeleteSelect, setIdDeleteSelect] = useState<number>();
  const [rowClicked, setRowClicked] = useState<any>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  // const [dataUpdated, setDataUpdated] = useState(0);

  const addFormData = yup.object().shape({
    title: yup.string().required(t("pills.Required category")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPills | any>({
    mode: "onBlur",
    resolver: yupResolver(addFormData),
  });

  const addCategory: SubmitHandler<IPills> = async (values) => {
    console.log("values", values);

    const name = document.querySelector("#name") as HTMLInputElement;

    try {
      await axios.post(
        `${CONFIG.API_URL}pills/category?countryCode=${countryCode}`,
        {
          active: true,
          countryCode,
          description: values.title,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    name.value = "";
    watchDataChange(t("pills.addedCategory"));
  };

  // const editCategory = async (e: any) => {
  //   console.log("row", e.row);
  //   setLoading(true);

  //   if (e.row.description === "") {
  //     setLoading(false);
  //     return alert(t("pills.alertCategory"));
  //   }

  //   try {
  //     await axios.put(
  //       `${CONFIG.API_URL}pills/category?id=${e.row.pillCategoryId}`,
  //       {
  //         description: e.row.description,
  //       },
  //       tokenHeaders,
  //     );
  //     setLoading(false);
  //   } catch (error: any) {
  //     setLoading(false);

  //     return messageError(error.response.data.message);
  //   }
  //   watchDataChange(t("pills.addedCategory"));
  // };

  const deleteCategory = async () => {
    setLoading(true);

    try {
      await axios.delete(
        `${CONFIG.API_URL}pills/category?id=${idDeleteSelect}`,

        tokenHeaders,
      );
      setLoading(false);
      setOpenDelete(false);
    } catch (error: any) {
      setLoading(false);
      setOpenDelete(false);
      return messageError(error.response.data.message);
    }
    watchDataChange(t("pills.deletedCategory"));
  };

  const openDeleteConfimation = (id: number) => {
    setIdDeleteSelect(id);
    setOpenDelete(true);
  };

  const onCellEditStart = (params: GridCellParams) => {
    setRowClicked(params.row);
  };

  const onRowEditCommit = async (params: GridCellEditCommitParams) => {
    console.log("🚀 ~ file: manageCategories.tsx ~ line 164 ~ params", params);
    console.log("rowClicked", rowClicked);

    if (params.value === "") {
      return alert(t("pills.alertCategory"));
    }

    if (params.value === rowClicked.description) {
      return false;
      // return alert(t("pills.alertCategory"));
    }

    try {
      await axios.put(
        `${CONFIG.API_URL}pills/category?id=${rowClicked.pillCategoryId}`,
        {
          description: params.value,
        },
        tokenHeaders,
      );
      // setLoading(false);
    } catch (error: any) {
      // setLoading(false);
      return messageError(error.response.data.message);
    }
    watchDataChange(t("pills.editedCategory"));
  };

  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: t("pills.name"),
      flex: 1,
      editable: true,
      renderCell: (params) =>
        params.value ? <Box>{params.row.description}</Box> : "",
    },
    {
      field: "pillCategoryId",
      headerName: "",
      width: 60,
      editable: true,
      renderCell: (params) =>
        params.value ? (
          <Box>
            {/* <IconButton onClick={() => editCategory(params)} id={params.value}>
              <SaveIcon sx={{ color: "var(--blue-100)" }} />
            </IconButton> */}
            <IconButton
              onClick={() => openDeleteConfimation(params.value)}
              id={params.value}
            >
              <DeleteIcon sx={{ color: "var(--red-100)" }} />
            </IconButton>
          </Box>
        ) : (
          ""
        ),
    },
  ];

  return (
    <Box>
      <Box
        component="form"
        onSubmit={handleSubmit(addCategory)}
        sx={{ marginBottom: 2 }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ width: "100%" }}>
            <FormInputText
              id="name"
              label={t("pills.name")}
              type="text"
              {...register("title")}
              error={errors.title}
            />
          </Box>

          <Box>
            <Button variant="contained" type="submit">
              {t("button.Add")}
            </Button>
          </Box>
        </Box>
      </Box>

      <DataGridApi
        columnsData={columns}
        messageError={messageError}
        endPoint={`pills/category/all?countryCode=${countryCode}&`}
        dataUpdated={dataUpdated}
        onCellEditStart={onCellEditStart}
        onCellEditCommit={onRowEditCommit}
        idRow="pillCategoryId"
      />

      {openDelete && (
        <ModalConfirmation
          openDelete={setOpenDelete}
          confirmDelete={deleteCategory}
        />
      )}
    </Box>
  );
}
