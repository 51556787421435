import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IImageSelected } from "../../interfaces/IImageSelected";
import { IPills } from "../../interfaces/IPills";
import { IPillsCategory } from "../../interfaces/IPillsCategory";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import VehiclePill from "./vehiclePill";

interface IPill {
  errors: any;
  editPill?: boolean;
  data?: IPills;
}

export default function Pill({ errors, editPill, data }: IPill) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  console.log("pill data", data);
  console.log("errors", errors);

  const [imageSelected, setImageSelected] = useState<IImageSelected>();
  const [visibleImage, setVisibleImage] = useState(false);
  const [visibleThumb, setVisibleThumb] = useState(false);
  const [messageThumb, setMessageThumb] = useState(false);
  const [archiveThumb, setArchiveThumb] = useState(false);
  const [visiblePathBox, setVisiblePathBox] = useState(false);

  const [pillsCategory, setPillsCategory] = useState<IPillsCategory[]>([]);

  useEffect(() => {
    try {
      axios
        .get(
          `${CONFIG.API_URL}pills/category/all?countryCode=${countryCode}`,
          tokenHeaders,
        )
        .then((response) => {
          setPillsCategory(response.data.content);
        });
    } catch (error) {
      console.log("error", error);
    }

    if (data?.thumb !== "") {
      setVisibleThumb(true);
    }
  }, [countryCode]);

  const setDefatulURL = () => {
    const youtubeLink = document.querySelector(
      "#youtubeLink",
    ) as HTMLInputElement;

    if (youtubeLink.value === "") {
      youtubeLink.value = "https://";
      youtubeLink.focus();
    }
  };

  const convertThumbToBase64 = (event: any) => {
    const preview64 = document.getElementById(
      "thumbimageChosen",
    ) as HTMLImageElement;
    const mediaFile64 = document.getElementById(
      "thumbmediaFile",
    ) as HTMLInputElement;
    const mediaFileName64 = document.getElementById(
      "thumbmediaFileName",
    ) as HTMLInputElement;
    const extensionInput64 = document.getElementById(
      "thumbextension",
    ) as HTMLInputElement;

    const file = event.target.files![0];

    const lastIndex = file.name.lastIndexOf(".");

    const name = file.name.slice(0, lastIndex);
    const extension = file.name.slice(lastIndex + 1);

    if (file.size > 2000000) {
      alert(`${t("alert.imageSize20")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImageFile"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (preview64) {
      const src = URL.createObjectURL(file);
      preview64.src = src;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      const imageBase = image[1];
      mediaFile64.value = imageBase;
      mediaFile64.focus();

      mediaFileName64.value = name;
      mediaFileName64.focus();

      extensionInput64.value = extension;
      extensionInput64.focus();

      setVisibleThumb(true);
      setMessageThumb(true);
    };

    reader.readAsDataURL(file);
  };

  const convertImageToBase64 = (event: any) => {
    const preview64 = document.getElementById(
      "imageChosen",
    ) as HTMLImageElement;
    const mediaFile64 = document.getElementById(
      "mediaFile",
    ) as HTMLInputElement;
    const mediaFileName64 = document.getElementById(
      "mediaFileName",
    ) as HTMLInputElement;
    const extensionInput64 = document.getElementById(
      "extension",
    ) as HTMLInputElement;

    const file = event.target.files![0];

    const lastIndex = file.name.lastIndexOf(".");

    const name = file.name.slice(0, lastIndex);
    const extension = file.name.slice(lastIndex + 1);

    console.log("file.size", file.size);

    if (file.size > 30000000 && (extension === "mp4" || extension === "mkv")) {
      alert(`${t("alert.imageSize30")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (
      file.size > 2000000 &&
      (extension === "png" || extension === "jpg" || extension === "jpeg")
    ) {
      alert(`${t("alert.imageSize20")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png|mp4|pdf|mkv)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    if (preview64) {
      const src = URL.createObjectURL(file);
      preview64.src = src;
    }

    const reader = new FileReader();

    const thumbimageChosen = document.getElementById(
      "thumbimageChosen",
    ) as HTMLImageElement;
    const thumbmediaFile = document.getElementById(
      "thumbmediaFile",
    ) as HTMLInputElement;
    const thumbmediaFileName = document.getElementById(
      "thumbmediaFileName",
    ) as HTMLInputElement;
    const thumbextension = document.getElementById(
      "thumbextension",
    ) as HTMLInputElement;

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");
      const imageBase = image[1];

      mediaFile64.value = imageBase;
      mediaFile64.focus();
      mediaFileName64.value = name;
      mediaFileName64.focus();
      extensionInput64.value = extension;
      extensionInput64.focus();

      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        thumbmediaFile.value = imageBase;
        thumbmediaFile.focus();
        thumbmediaFileName.value = name;
        thumbmediaFileName.focus();
        thumbextension.value = extension;
        thumbextension.focus();

        const src = URL.createObjectURL(file);
        thumbimageChosen.src = src;
        setVisibleThumb(true);
        setMessageThumb(true);

        // setArchiveThumb(true);
        // convertThumbToBase64(event);
      } else {
        preview64.alt = `${name}.${extension}`;
      }

      setVisibleImage(true);
    };

    // if (extension !== "png" || extension !== "jpg" || extension !== "jpeg") {
    //   thumbmediaFile.value = "";
    //   thumbmediaFile.focus();
    //   thumbmediaFileName.value = "";
    //   thumbmediaFileName.focus();
    //   thumbextension.value = "";
    //   thumbextension.focus();

    //   setArchiveThumb(false);
    // } else {
    //   setArchiveThumb(true);
    // }

    reader.readAsDataURL(file);
  };

  const deleteImage = (type: string) => {
    const preview = document.getElementById(
      `${type !== "" ? type : ""}imageChosen`,
    ) as HTMLImageElement;
    const media = document.getElementById(
      `${type !== "" ? type : ""}media`,
    ) as HTMLInputElement;
    const mediaFile = document.getElementById(
      `${type !== "" ? type : ""}mediaFile`,
    ) as HTMLInputElement;
    const mediaFileName = document.getElementById(
      `${type !== "" ? type : ""}mediaFileName`,
    ) as HTMLInputElement;
    const extensionInput = document.getElementById(
      `${type !== "" ? type : ""}extension`,
    ) as HTMLInputElement;

    if (type === "") {
      const thumbmediaFile = document.getElementById(
        "thumbmediaFile",
      ) as HTMLInputElement;
      const thumbmediaFileName = document.getElementById(
        "thumbmediaFileName",
      ) as HTMLInputElement;
      const thumbextension = document.getElementById(
        "thumbextension",
      ) as HTMLInputElement;

      thumbmediaFile.value = "";
      thumbmediaFile.focus();

      thumbmediaFileName.value = "";
      thumbmediaFileName.focus();

      thumbextension.value = "";
      thumbextension.focus();
    }

    media.value = "";
    media.focus();

    mediaFile.value = "";
    mediaFile.focus();

    mediaFileName.value = "";
    mediaFileName.focus();

    extensionInput.value = "";
    extensionInput.focus();

    preview.src = "";

    if (type !== "") {
      setVisibleThumb(false);
      setMessageThumb(false);
    } else {
      const preview64 = document.getElementById(
        "imageChosen",
      ) as HTMLImageElement;
      preview64.alt = "";
      setVisibleImage(false);
      setVisiblePathBox(true);
    }
  };

  const { register } = useFormContext();

  return (
    <Box>
      <FormInputText
        label={t("pills.title")}
        type="text"
        {...register("title")}
        error={errors.title}
      />

      <FormControl fullWidth>
        <FormInputText
          multiline
          minRows={2}
          maxRows={2}
          fullWidth
          label={t("pills.description")}
          inputProps={{ maxLength: 200 }}
          {...register("description")}
          error={errors.description}
        />
      </FormControl>

      <Grid container spacing={2} sx={{ marginTop: 0.5, marginBottom: 2 }}>
        <Grid item xs={9} sx={{ borderRight: "1px solid var(--gray-100)" }}>
          <Box
            sx={{
              marginBottom: 1.5,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnImageOrVideoOrPDF")}</span>
            <input
              accept=".png, .jpg, .jpeg, .mp4, .pdf, .mkv"
              className="imageFile"
              type="file"
              id="fileInput"
              name="fileInput"
              onChange={(event) => {
                convertImageToBase64(event);
              }}
            />
          </Box>

          {/* Image previwew */}
          {data?.media && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "flex-end",
                  gap: 2,
                  // marginBottom: 1,
                }}
              >
                {data && (
                  <Box
                    sx={{
                      display: "flex",
                      // justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={data.media}>
                      <IconButton href={data?.media} download={data.title}>
                        <DownloadIcon
                          sx={{
                            // cursor: "pointer",
                            color: "var(--blue-100)",
                            width: 30,
                            height: 30,
                          }}
                        />
                      </IconButton>
                    </Tooltip>

                    <span>{t("pills.downloadPill")}</span>
                  </Box>
                )}
                {/* <Box>
              <img
                alt={imageSelected?.name}
                width="130px"
                id="imageChosen"
                src={data ? (data.thumb !== "" ? data.thumb : "") : ""}
              />
              <br />
              {visibleImage && (
                <Box
                  style={{
                    color: "var(--red-100)",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteImage("")}
                >
                  {t("pills.delete")}
                </Box>
              )}
              {!visibleImage && !visiblePathBox && data?.thumb ? (
                <Box
                  id="pathBox"
                  style={{
                    color: "var(--red-100)",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteImage("")}
                >
                  {t("pills.delete")}
                </Box>
              ) : (
                ""
              )}
            </Box> */}
              </Box>

              {data && <span style={{ fontSize: 12 }}>{data?.media}</span>}
            </Box>
          )}
        </Grid>

        {/* IF NOT A VIDEO */}
        {/* {!archiveThumb && ( */}
        <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 2,
            }}
          >
            <Button
              variant="text"
              component="label"
              sx={{
                width: 180,
                height: 130,
                cursor: "pointer",
                display: "flex",
                placeItems: "center",
                justifyContent: "center",
                border: "1px solid  var(--gray-200)",
                color: "var(--gray-900)",
                position: "absolute",
              }}
            >
              <input
                hidden
                accept=".png, .jpg, .jpeg"
                className="imageFile"
                type="file"
                id="fileInput"
                name="fileInput"
                // value={image64}
                onChange={(event) => {
                  convertThumbToBase64(event);
                }}
              />
            </Button>
            <Box
              sx={{
                overflow: "hidden",
                width: 180,
                height: 130,
                display: "flex",
                flexDirection: !messageThumb && !editPill ? "column" : "row",
                placeItems: "center",
              }}
            >
              <img
                alt={imageSelected?.name}
                id="thumbimageChosen"
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  margin: "auto",
                }}
                src={data ? (data.thumb !== "" ? data.thumb : "") : ""}
              />
              {!messageThumb && !editPill && (
                <span style={{ marginTop: "25%", textAlign: "center" }}>
                  {t("pills.chooseThumb")}
                </span>
              )}
            </Box>
            {messageThumb && (
              <Box
                style={{
                  color: "var(--red-100)",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => deleteImage("thumb")}
              >
                {t("pills.delete")}
              </Box>
            )}
            {!messageThumb && data?.thumb ? (
              <Box
                id="pathBox"
                style={{
                  color: "var(--red-100)",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => deleteImage("thumb")}
              >
                {t("pills.delete")}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        {/* )} */}
      </Grid>

      <Box
        sx={{
          position: "absolute",
          left: "-10000px",
          top: "auto",
          width: "1px",
          height: "1px",
          overflow: "hidden",
        }}
      >
        <FormInputText
          label={t("pills.media")}
          type="text"
          id="media"
          {...register("media")}
        />
        <FormInputText
          label={t("pills.mediaFile")}
          type="text"
          id="mediaFile"
          {...register("mediaFile")}
        />
        <FormInputText
          label={t("pills.mediaFileName")}
          type="text"
          id="mediaFileName"
          {...register("mediaFileName")}
        />
        <FormInputText
          label={t("pills.extension")}
          type="text"
          id="extension"
          {...register("mediaExtension")}
        />

        {/* THUMB IMAGE */}
        <FormInputText
          label={t("pills.thumbMedia")}
          type="text"
          id="thumbmedia"
          {...register("thumbMedia")}
        />
        <FormInputText
          label={t("pills.thumbMediaFile")}
          type="text"
          id="thumbmediaFile"
          {...register("thumbFile")}
        />
        <FormInputText
          label={t("pills.thumbMediaFileName")}
          type="text"
          id="thumbmediaFileName"
          {...register("thumbFileName")}
        />
        <FormInputText
          label={t("pills.thumbExtension")}
          type="text"
          id="thumbextension"
          {...register("thumbExtension")}
        />
      </Box>

      <FormInputText
        label={t("pills.youtubeLink")}
        type="url"
        id="youtubeLink"
        onFocus={() => setDefatulURL()}
        {...register("youtubeLink")}
        error={errors.youtubeLink}
      />

      <Select
        displayEmpty
        labelId="categorySelect"
        id="categorySelect"
        placeholder="Select"
        // onChange={handleChange}
        {...register("category")}
        defaultValue={data ? data.pillsCategory.pillCategoryId : ""}
        sx={{ height: 40, width: "100%" }}
        // required
      >
        <MenuItem disabled value="">
          <em>{t("pills.selectCategory")}</em>
        </MenuItem>
        {pillsCategory?.map((category: IPillsCategory) => {
          return (
            <MenuItem
              key={category.pillCategoryId}
              value={category.pillCategoryId}
            >
              {category.description}
            </MenuItem>
          );
        })}
      </Select>

      <FormHelperText
        sx={{
          marginBottom: "1rem",
          fontSize: 14,
          color: "#ff0000 !important",
          marginLeft: 0,
        }}
        error={!!errors.category}
      >
        {errors.category?.message}
      </FormHelperText>

      <VehiclePill errors={errors} editPill={editPill} data={data} />

      <FormGroup sx={{ marginTop: "1rem" }}>
        <FormControlLabel
          control={
            <Switch
              id="vwPlay"
              {...register("active")}
              defaultChecked={
                data ? (data.active === true ? true : false) : true
              }
            />
          }
          label={t("pills.active")}
        />
      </FormGroup>
    </Box>
  );
}
