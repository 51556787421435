import { Box, Button } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IPillsOrdering } from "../../interfaces/IPillsOrdering";
import { CONFIG } from "../../services/config";

export default function VehiclePills({
  data,
  vehicleId,
  vehicleVersionId,
  vehicleVersionYear,
  messageError,
  watchDataChange,
}: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [items, setItems] = useState<any[]>(data);
  console.log("items", items);

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string,
  ) => {
    // console.log("sourceIndex", sourceIndex);
    // console.log("targetIndex", targetIndex);
    // console.log("item sourceIndex", items[sourceIndex]);
    // console.log("item targetIndex", items[targetIndex]);

    // try {
    //   axios.put(
    //     `${CONFIG.API_URL}pills/ordering?id=${items[sourceIndex].pillsOrderingId}&orderNum=${targetIndex}`,
    //     {},
    //     tokenHeaders,
    //   );
    // } catch (error) {
    //   console.log("error", error);
    // }

    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  };

  const handleSaveOrder = async () => {
    const newOrder: any = [];
    items.forEach((item, i) => {
      // console.log("item", item);
      const newObj = {
        pillsOrderingId: item.pillsOrderingId,
        orderingNo: i,
      };
      newOrder.push(newObj);
    });

    try {
      axios.put(
        `${CONFIG.API_URL}pills/ordering`,
        { orderList: newOrder },
        tokenHeaders,
      );
    } catch (error: any) {
      messageError(error.response.data.message);
    }
    watchDataChange(t("pills.orderSuccess"));
  };

  return (
    <Box sx={{ borderTop: "1px solid var(--gray-300)" }}>
      <Box sx={{ marginBottom: 4 }}>
        <Box sx={{ marginTop: 2 }}>
          <span>{t("pills.instructions1")}</span>
          <br />
          <span>{t("pills.instructions2")}</span>
        </Box>
      </Box>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={3}
          rowHeight={340}
          style={{ height: 340 * Math.ceil(items.length / 3) }}
          // style={{ height: items.length * 118 }}
        >
          {items.map((item: any) => (
            <GridItem key={item.pillsOrderingId}>
              <Box
                sx={{
                  border: "1px solid var(--gray-300)",
                  borderRadius: 2,
                  overFlow: "hidden",
                  cursor: "-webkit-grab",
                  marginRight: 2,
                  webkitUserSelect: "none",
                  khtmlUserSelect: "none",
                  mozUserSelect: "none",
                  msUserSelect: "none",
                  oUserSelect: "none",
                  userSelect: "none",
                }}
              >
                <Box
                  sx={{
                    // backgroundColor: "var(--gray-100)",
                    position: "relative",
                    transitionDuration: "300ms",
                    minHeight: 150,
                    display: "flex",
                    placeItems: "center",
                    overFlow: "hidden",
                    borderBottom: "1px solid var(--gray-100)",
                  }}
                >
                  <img
                    // src={
                    //   item.pillVehicleId.pillsDomain.mediaId !== ""
                    //     ? item.pillVehicleId.pillsDomain.mediaId
                    //     : "https://vw-digital-cdn-br.itd.vw.com.br/assets/myvw-cdn-br-ds/vw-prod_volkswagen-t-cross-brasil-14"
                    // }
                    src={item.pillVehicleId.pillsDomain.thumbId}
                    alt={item.title}
                    width="100%"
                    style={{
                      borderTopRightRadius: 8,
                      borderTopLeftRadius: 8,
                      maxHeight: 150,
                    }}
                  />
                </Box>
                <Box sx={{ paddingX: 2, backgroundColor: "white" }}>
                  <p style={{ fontSize: 14, fontWeight: "bold" }}>
                    {item.pillVehicleId.pillsDomain.title}
                  </p>
                  <p
                    style={{
                      fontSize: 13,
                      color: "var(--gray-300)",
                      whiteSpace: "normal",
                      width: "100%",
                      height: "3em",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.pillVehicleId.pillsDomain.description}
                  </p>
                </Box>
                <Box
                  sx={{
                    paddingX: 2,
                    display: "flex",
                    rowGap: 1,
                    columnGap: 3,
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      // position: "relative",
                      // width: "100%",
                      color: "white",
                      borderRadius: 5,
                      paddingX: 1,
                      paddingY: 0.5,
                      fontWeight: "bold",
                      fontSize: 10,
                      backgroundColor: "var(--blue-400)",
                      display: "flex",
                      placeItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    {item.vehicleId.name} | {item.vehicleVersionId.name}
                  </Box>
                </Box>
              </Box>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
      {/* <button type="button" onClick={() => console.log("state", items)}>
          state
        </button> */}
      <Button variant="contained" onClick={handleSaveOrder}>
        {t("pills.save")}
      </Button>
    </Box>
  );
}
