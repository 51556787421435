import { AlertColor } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import BoxContent from "../../components/boxContent";
// import AddModule from "../../components/modules/addModule";
// import EditModule from "../../components/modules/editModule";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import AddModule from "../../components/modules/addModule";
import EditModule from "../../components/modules/editModule";

// import AddModule from "../../components/Module/addModule";
// import EditModule from "../../components/Module/editModule";
// import ModuleGallery from "../../components/Module/ModuleGallery";
import MyModal from "../../components/MyModal";
// import { IModuleData } from "../../interfaces/IModule";
import modulesMock from "./modules.mock.json";

export default function Features() {
  const { t } = useTranslation();

  const [openModalAddModule, setOpenModalAddModule] = useState(false);

  const [openModalEditModule, setOpenModalEditModule] = useState(false);
  const [ModuleSelected, setModuleSelected] = useState<any>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddModule(false);
    setOpenModalEditModule(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddModule = () => {
    setOpenModalAddModule(true);
  };

  const editModule = (e: any) => {
    setOpenModalEditModule(true);
    console.log("e.row", e.row);
    setModuleSelected(e.row);
  };

  const columns: GridColDef[] = [
    {
      field: "modulesName",
      headerName: t("modules.moduleName"),
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "modulesTitle",
      headerName: t("modules.moduleTitle"),
      flex: 1,
      headerClassName: "header-style",
    },
  ];

  const addFormData = yup.object().shape({
    moduleName: yup.string().required(t("modules.requiredName")),
    moduleTitle: yup.string().required(t("modules.requiredTitle")),
    // active: yup.string(),
  });

  return (
    <BoxContent
      title={t("menu.modules")}
      button
      titleButton={t("modules.addModule")}
      handleClick={handleClickModaAddModule}
      global
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <DataGridApi
        onRowClick={editModule}
        dataUpdated={dataUpdated}
        endPoint="countryModules?"
        columnsData={columns}
        messageError={messageError}
        // mock={modulesMock}
      />

      {openModalAddModule && (
        <MyModal
          title={t("modules.addModule")}
          modalChange={setOpenModalAddModule}
        >
          <AddModule
            watchDataChange={watchDataChange}
            messageError={messageError}
            addFormData={addFormData}
          />
        </MyModal>
      )}

      {openModalEditModule && (
        <MyModal
          title={t("modules.editModule")}
          modalChange={setOpenModalEditModule}
        >
          <EditModule
            data={ModuleSelected}
            watchDataChange={watchDataChange}
            messageError={messageError}
            addFormData={addFormData}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
