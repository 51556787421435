import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { AuthContext } from "../../contexts/AuthContext";
import { ICountry } from "../../interfaces/ICountry";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";
import modulesMock from "./modules.mock.json";
import { IModule } from "../../interfaces/IModule";

export default function EditCountry({
  watchDataChange,
  messageError,
  updateData,
  countrySelected,
}: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);
  console.log("countrySelected.modules", countrySelected.modules);
  const [editCheckBox, setEditCheckBox] = useState<string[]>([]);

  const [moduleArray, setModuleArray] = useState<IModule[]>([]);
  const [countryModules, setCountryModules] = useState<any>([]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${CONFIG.API_URL}countryModules`, tokenHeaders)
        .then((response) => {
          setModuleArray(response.data.content);
        })
        .catch(() => {
          console.log("erro");
        });
    }, 500);

    const arrayEdit: any = [];
    const array: any = [];
    countrySelected.modules.map((item: any) => {
      arrayEdit.push(item.id.toString());
      return array.push(item.id);
    });
    setEditCheckBox(arrayEdit);
    setCountryModules(array);
  }, []);

  const ALPHA_REGEX = /^[a-zA-Z-\s]+$/;

  const editFormData = yup.object().shape({
    country: yup.string().required(t("countries.Required country")),
    prefix: yup.string().required(t("countries.Required prefix")),
    countryGroup: yup.string().required(t("countries.Required country group")),
    active: yup.boolean(),
  });

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    formState: formStateEdit,
    clearErrors,
    reset,
  } = useForm<ICountry>({
    resolver: yupResolver(editFormData),
  });

  const { errors: errorsEdit } = formStateEdit;

  const [imageBase64, setImageBase64] = useState<any>(null);

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];
    const preview = document.getElementById("imageChosen") as HTMLImageElement;

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      setImageBase64(image[1]);
    };

    reader.readAsDataURL(file);
  };

  const deleteCountry = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}country?countryCode=${countrySelected.countryCode}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    // setMessageQuickAlert(t("countries.Country deleted"));
    watchDataChange(t("countries.Country deleted"));
  };

  const editCountryChanges: SubmitHandler<ICountry | FieldValues> = async (
    values,
  ) => {
    console.log("🚀 ~ file: editCountry.tsx:134 ~ values", values);
    let updatedActive = 0;
    if (values.active) {
      updatedActive = 1;
    }

    const countryCode = document.querySelector(
      "#editCountryCode",
    ) as HTMLInputElement | null;

    try {
      await axios.put(
        `${CONFIG.API_URL}country?countryCode=${countrySelected.countryCode}`,
        {
          name: values.country,
          code: countryCode?.value,
          prefix: values.prefix,
          cgroup: values.countryGroup,
          active: updatedActive,
          documentImg: imageBase64,
          modules: editCheckBox,
        },
        tokenHeaders,
      );
      setEditCheckBox([]);
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    // setMessageQuickAlert(t("countries.Country edited"));
    watchDataChange(t("countries.Country edited"));
  };

  // const switchActive = async () => {
  //   const active = document.querySelector(
  //     "#editActive",
  //   ) as HTMLInputElement | null;

  //   let updatedActive;
  //   if (active?.checked) {
  //     updatedActive = 1;
  //   } else {
  //     updatedActive = 0;
  //   }

  //   await axios.get(
  //     `${CONFIG.API_URL}country/activeDeactive?countryCode=${countrySelected.countryCode}`,

  //     tokenHeaders,
  //   );
  //   // const dataChange = dataUpdated + 1;
  //   updateData(t("countries.countryStatus"));
  // };

  const deleteImage = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}country/countryImg?countryCode=${countrySelected.countryCode}`,

        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    setImageBase64("");
    const preview = document.getElementById("imageChosen") as HTMLImageElement;
    preview.src = "";
    // setMessageQuickAlert(t("countries.Image deleted"));
    updateData(t("countries.Image deleted"));
  };

  useEffect(() => {
    clearErrors();
    reset();
    setImageBase64("");
  }, []);

  const handleEditCheckBox = (event: any) => {
    if (event.target.checked) {
      const module = event.target.value;
      setEditCheckBox((current) => [...current, ...[module]]);
    } else {
      const module = event.target.value;
      editCheckBox.map((name) => {
        if (name === module) {
          const array = editCheckBox;
          const index = array.indexOf(module);
          if (index !== -1) {
            array.splice(index, 1);
            setEditCheckBox(array);
          }
        }
        return editCheckBox;
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmitEdit(editCountryChanges)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            label={t("countries.Country name")}
            id="editCountry"
            defaultValue={countrySelected?.country}
            error={errorsEdit.country}
            {...registerEdit("country")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 2,
            }}
            label={t("countries.Country code")}
            id="editCountryCode"
            defaultValue={countrySelected?.countryCode}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormInputText
            type="number"
            inputProps={{
              max: 999,
            }}
            label={t("countries.Prefix")}
            id="editPrefix"
            defaultValue={countrySelected?.prefix}
            error={errorsEdit.prefix}
            {...registerEdit("prefix")}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInputText
            onKeyDown={(event) => {
              if (!ALPHA_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{
              maxLength: 3,
            }}
            label={t("countries.Country group")}
            id="editCountryGroup"
            defaultValue={countrySelected?.countryGroup}
            error={errorsEdit.countryGroup}
            {...registerEdit("countryGroup")}
          />
        </Grid>

        <Grid item xs={6}>
          <input
            accept=".png, .jpg, .jpeg"
            className="imageFile"
            type="file"
            name="fileInput"
            onChange={(event) => {
              convertImageToBase64(event);
              // imageTest(event);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  // onClick={switchActive}
                  id="editActive"
                  defaultChecked={countrySelected.active ? true : false}
                  {...registerEdit("active")}
                />
              }
              label={t("countries.Active")}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6} id="imagePrev">
          {countrySelected.document && (
            <>
              <span>{t("countries.Current image")}:</span>
              <br />
              <br />
              <img
                src={countrySelected?.document}
                alt="No document"
                width="100px"
                id="imageChosen"
              />
              <br />
              <Box
                style={{
                  color: "var(--red-100)",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={deleteImage}
              >
                Excluir imagem
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      {moduleArray.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <h3>{t("countries.modules")}</h3>
          <Grid
            container
            rowSpacing={1}
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ paddingLeft: "1.5rem" }}
          >
            {moduleArray.map((item) => {
              return (
                <Grid xs={6} sm={4} md={3} key={item.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={countryModules.includes(item.id)}
                      />
                    }
                    id={item.modulesTitle}
                    name={item.modulesTitle}
                    value={item.id}
                    onChange={(e) => handleEditCheckBox(e)}
                    label={item.modulesTitle}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <CircularProgress />
      )}

      <ActionButtons
        typeButton="submit"
        saveName={t("button.Save")}
        confirmDelete={deleteCountry}
      />
    </Box>
  );
}
