import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ModelVehicle from "./modelVehicle";

import { FormInputText } from "../form/FormInputText";

export default function Vehicle({
  errors,
  editVehicle,
  watchScroll,
  data,
}: any) {
  const { t } = useTranslation();

  const [colorPicker, setColorPicker] = useState<string>(
    editVehicle ? data.backgroundColor : "",
  );

  // console.log("vehicle Data", data);
  // const [newData, setNewData] = useState<IVehicle>(data);

  // aqui vou ter pegar o ID e buscar em uma API o modelo
  // para setar no estado

  // useEffect(() => {
  //   setNewData(data);
  // }, []);

  const [imageSelected, setImageSelected] = useState<string>();
  const { register } = useFormContext();
  console.log("errors", errors);

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    const preview = document.getElementById(
      `thumbnailPreview`,
    ) as HTMLImageElement;
    const extensionInput = document.querySelector(
      `#extension`,
    ) as HTMLInputElement;

    const lastIndex = file.name.lastIndexOf(".");
    const name = file.name.slice(0, lastIndex);

    setImageSelected(name);

    const extension = file.name.slice(lastIndex + 1);
    extensionInput.value = extension;
    extensionInput.focus();

    let limitSize;

    if (extension === "mp4") {
      limitSize = 30000000;
    } else {
      limitSize = 2000000;
    }

    if (file.size > limitSize) {
      alert(`${t("alert.imageSize")}`);
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      setImageSelected("");
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImageFile"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      setImageSelected("");
      return false;
    }

    if (preview) {
      const src = URL.createObjectURL(file);
      preview.src = src;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      const imageBase = image[1];

      const teaser = document.querySelector("#teaser") as HTMLInputElement;
      teaser.value = imageBase;
      teaser.focus();
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box>
      <Grid
        sx={{ marginTop: 1 }}
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={8}>
          <FormInputText
            label={t("models.name")}
            type="text"
            {...register("name")}
            error={errors.name}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              sx={{
                marginBottom: 2.5,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button variant="contained" component="label" htmlFor="fileInput">
                <input
                  accept=".png, .jpg, .jpeg, .mp4"
                  className="imageFile"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    convertImageToBase64(event);
                  }}
                />
                {t("models.addTeaser")}
              </Button>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Button
                variant="contained"
                component="label"
                htmlFor="fileInput"
                sx={{ cursor: "pointer" }}
              >
                <label style={{ cursor: "pointer" }} htmlFor="head">
                  {t("models.chooseColor")}
                </label>
              </Button>
            </Box>
            <Box>
              <input
                type="color"
                id="head"
                {...register("backgroundColor")}
                style={{
                  opacity: 0,
                  marginRight: 40,
                  cursor: "default",
                }}
                onChange={(e) => {
                  setColorPicker(e.target.value);
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              width: 200,
              height: 130,
            }}
          >
            <img
              alt={imageSelected ? imageSelected : ""}
              id="thumbnailPreview"
              style={{
                width: 189,
                // height: 64,
                backgroundColor: colorPicker,
                paddingTop: 20,
                paddingBottom: 20,
                borderRadius: 8,
              }}
              src={data ? data.teaserMediaId : ""}
            />
          </Box>

          <Box
            sx={{
              position: "absolute",
              left: "-10000px",
              top: "auto",
              width: "1px",
              height: "1px",
              overflow: "hidden",
            }}
          >
            <FormInputText
              id="teaser"
              label="image"
              type="text"
              {...register("teaserMediaId")}
            />
            <FormInputText
              id="extension"
              label="image"
              type="text"
              {...register("extension")}
            />
          </Box>
        </Grid>
      </Grid>

      <ModelVehicle
        errors={errors}
        editVehicle={editVehicle}
        data={data}
        watchScroll={watchScroll}
      />
    </Box>
  );
}
