import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import { useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import { AuthContext } from "../../contexts/AuthContext";

export default function OwnersManual() {
  const navigate = useNavigate();

  useLayoutEffect(() => {}, []);

  const {
    country: { countryCode },
  } = useContext(AuthContext);

  const columns: GridColDef[] = [
    {
      field: "vehicle",
      headerName: t("ownersManual.vehicle"),
      flex: 1,
      headerClassName: "header-style",
      renderCell: ({ row }) => {
        const { name } = row;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p>{name}</p>
            <IconButton>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <BoxContent title={t("menu.ownersmanual")}>
      <DataGridApi
        columnsData={columns}
        onRowClick={({ row }: any) => navigate("versions-list", { state: row })}
        endPoint={`vehicles/pageable?countryCode=${countryCode}&`}
      />
    </BoxContent>
  );
}
