import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TableRowsIcon from "@mui/icons-material/TableRows";
import WindowIcon from "@mui/icons-material/Window";
import {
  AlertColor,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import * as yup from "yup";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import GalleryView from "../../components/galleryView";
import AddModel from "../../components/models/addModel";
import EditModel from "../../components/models/editModel";
import ModelGallery from "../../components/models/modelGallery";

import MyModal from "../../components/MyModal";
import { AuthContext } from "../../contexts/AuthContext";
import { IVehicle } from "../../interfaces/IVehicle";
import { CONFIG } from "../../services/config";

export default function Models() {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const addFormData = yup.object().shape({
    name: yup.string().required(t("models.Required vehicleName")),
    teaserMediaId: yup.string(),
    extension: yup.string(),
    backgroundColor: yup.string(),

    versions: yup.array().of(
      yup.object().shape({
        modelCode: yup.string().required(t("models.Required modelCode")),
        name: yup.string().required(t("models.Required version")),
        year: yup.string().required(t("models.Required year")),
        vwPlay: yup.string(),
        isCognitive: yup.string(),
        version: yup.array().of(
          yup.object().shape({
            name: yup.string(),
            extension: yup.string(),
            imageBase: yup.string(),
            colorId: yup.string().required("colorId"),
            originalColorId: yup.string(),
          }),
        ),
      }),
    ),
  });

  const [openModalAddModel, setOpenModalAddModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicleId, setVehicleId] = useState<number>();

  const [openModalEditModel, setOpenModalEditModel] = useState(false);
  const [modelSelected, setModelSelected] = useState<IVehicle | any>();
  const [galleryView, setGalleryView] = useState(true);

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const updateData = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddModel(false);
    setOpenModalEditModel(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddModel = () => {
    setOpenModalAddModel(true);
  };

  const editModel = async (e: any) => {
    // resetEdit();
    setVehicleId(e.row.id);
    setLoading(true);

    await axios
      .get(`${CONFIG.API_URL}vehicles/${e.row.id}`, tokenHeaders)
      .then(async (response) => {
        setModelSelected(response.data);
      });
    setOpenModalEditModel(true);
    setLoading(false);
  };

  const callback = async (Model: any) => {
    // Add interface IModelData
    setVehicleId(Model.id);
    setLoading(true);
    try {
      await axios
        .get(`${CONFIG.API_URL}vehicles/${Model.id}`, tokenHeaders)
        .then((response) => {
          console.log(
            "🚀 ~ file: index.tsx:130 ~ .then ~ response.data",
            response.data,
          );
          setModelSelected(response.data);
        });

      setOpenModalEditModel(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      return alert("erro");
    }

    // setModelSelected(Model);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("models.name"),
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      sortable: false,
      filterable: false,
      renderCell: (params) =>
        params.value === "Aguardando Aprovação" ? (
          <Tooltip title={t("models.waitingAproval")}>
            <AccessTimeIcon sx={{ color: "var(--blue-900)" }} />
          </Tooltip>
        ) : (
          ""
        ),
      headerClassName: "header-style",
    },
  ];

  return (
    <>
      <BoxContent
        title={t("menu.models")}
        button
        titleButton={t("models.addModel")}
        handleClick={handleClickModaAddModel}
        noGlobal
      >
        <QuickAlert
          visibility={visibleAlert}
          description={messageQuickAlert}
          severity={severityAlert}
          title={titleMessage}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            placeItems: "center",
            color: "var(--gray-700)",
          }}
        >
          <span>{t("gallery.changeView")}:</span>
          <IconButton onClick={() => setGalleryView(false)}>
            <TableRowsIcon
              sx={{
                color: !galleryView ? "var(--blue-100)" : "var(--gray-700)",
              }}
            />
          </IconButton>
          <IconButton onClick={() => setGalleryView(true)}>
            <WindowIcon
              sx={{
                color: galleryView ? "var(--blue-100)" : "var(--gray-700)",
              }}
            />
          </IconButton>
        </Box>

        {!galleryView && (
          <DataGridApi
            onRowClick={editModel}
            dataUpdated={dataUpdated}
            endPoint={`vehicles/pageable?countryCode=${countryCode}&`}
            columnsData={columns}
            messageError={messageError}
          />
        )}

        {openModalAddModel && (
          <MyModal
            title={t("models.addModel")}
            modalChange={setOpenModalAddModel}
          >
            <AddModel
              addFormData={addFormData}
              watchDataChange={watchDataChange}
              messageError={messageError}
            />
          </MyModal>
        )}

        {openModalEditModel && (
          <MyModal
            title={t("models.editModel")}
            modalChange={setOpenModalEditModel}
          >
            <EditModel
              addFormData={addFormData}
              data={modelSelected}
              watchDataChange={watchDataChange}
              vehicleId={vehicleId}
              // updateData={updateData}
              messageError={messageError}
            />
          </MyModal>
        )}
        {galleryView && (
          <GalleryView
            onClick={callback}
            dataUpdated={dataUpdated}
            endPoint={`vehicles/pageable?countryCode=${countryCode}&`}
            messageError={messageError}
            Component={ModelGallery}
            columnsGrid={{ xs: 4, sm: 8, md: 8, lg: 12 }}
          />
        )}
      </BoxContent>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "black",
            display: "flex",
            placeItems: "center",
            justifyContent: "center",
            opacity: 0.5,
            zIndex: 99999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
