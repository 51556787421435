import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

export default function AddManual({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const addFormData = yup.object().shape({
    name: yup.string().required("name"),
    age: yup.string().required("age"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(addFormData) }); // Add interface IManual

  const addManual: SubmitHandler<any> = async (values) => {
    // Add interface IManual
    console.log("values", values);

    // try {
    //   await axios.post(
    //     `${CONFIG.API_URL}calculator`,
    //     {
    //       name: values.name,
    //       age: values.age
    //     },
    //     tokenHeaders,
    //   );
    // } catch (error: any) {
    //   return messageError(error.response.data.message);
    // }
    // watchDataChange('teste');
  };

  return (
    <Box component="form" onSubmit={handleSubmit(addManual)}>
      <FormInputText label="name" error={errors.name} {...register("name")} />
      <FormInputText label="age" error={errors.age} {...register("age")} />

      <ActionButtons saveName={t("button.Add")} typeButton="submit" />
    </Box>
  );
}
