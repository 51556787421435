import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface IRequires {
  msg: string;
  value?: string;
}
interface ILabelForm {
  label: string;
  requires?: IRequires[];
  children: ReactNode;
  required?: boolean;
}
export function LabelForm({
  label,
  requires = [],
  children,
  required = false,
}: ILabelForm) {
  return (
    <Box
      display="flex"
      alignItems="baseline"
      mb="16px"
      pb="16px"
      borderBottom="1px solid #DFE4E8"
    >
      <Box mr="32px" width="200px">
        <Typography fontWeight={400} fontSize="16px" lineHeight="20px">
          {`${label}:${required ? "*" : ""}`}
        </Typography>
        {requires.map((item) => (
          <Typography fontWeight={400} fontSize="12px" lineHeight="16px">
            {`${item.msg}${item.value ? `: ${item.value}` : ""}`}
          </Typography>
        ))}
      </Box>
      <Box flex="1">{children}</Box>
    </Box>
  );
}
