import { Box, TextField } from "@mui/material";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { IFormInput } from "../../interfaces/IFormInput";

const InputBase: ForwardRefRenderFunction<HTMLInputElement, IFormInput> = (
  { label = "", error = null, ...rest },
  ref,
) => {
  return (
    <>
      <TextField
        fullWidth
        label={label}
        size="small"
        sx={{
          marginBottom: "1rem",
          fontWeight: "bold",
        }}
        ref={ref}
        {...rest}
      />
      {!!error && (
        <Box
          sx={{
            marginBottom: "1rem",
            marginTop: "-1rem",
            fontSize: "14px",
            color: "var(--red-100)",
          }}
        >
          {error.message}
        </Box>
      )}
    </>
  );
};

export const FormInputText = forwardRef(InputBase);
