import { AlertColor } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import AddColor from "../../components/colors/addColor";
import EditColor from "../../components/colors/editColor";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
// import AddColor from "../../components/Color/addColor";
// import EditColor from "../../components/Color/editColor";
// import ColorGallery from "../../components/Color/ColorGallery";
import MyModal from "../../components/MyModal";
import { AuthContext } from "../../contexts/AuthContext";
// import { IColorData } from "../../interfaces/IColor";

export default function Colors() {
  const { t } = useTranslation();

  const [openModalAddColor, setOpenModalAddColor] = useState(false);

  const [openModalEditColor, setOpenModalEditColor] = useState(false);
  const [ColorSelected, setColorSelected] = useState<any>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);
  const {
    country: { countryCode },
  } = useContext(AuthContext);

  const updateData = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddColor(false);
    setOpenModalEditColor(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddColor = () => {
    setOpenModalAddColor(true);
  };

  const editColor = (e: any) => {
    setOpenModalEditColor(true);
    console.log("e.row", e.row);
    setColorSelected(e.row);
  };

  const callback = (Color: any) => {
    // Add interface IColorData
    setOpenModalEditColor(true);
    setColorSelected(Color);
  };

  const columns: GridColDef[] = [
    {
      field: "colorCode",
      headerName: t("colors.code"),
      width: 429,
      headerClassName: "header-style",
    },
    {
      field: "name",
      headerName: t("colors.name"),
      renderCell: (params) =>
        params.value ? <p title={params.value}>{params.value}</p> : "",
      width: 429,
      headerClassName: "header-style",
    },
  ];

  return (
    <BoxContent
      title={t("menu.colors")}
      button
      titleButton={t("colors.addColor")}
      handleClick={handleClickModaAddColor}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <DataGridApi
        onRowClick={editColor}
        dataUpdated={dataUpdated}
        endPoint={`vehiclecolors?countryCode=${countryCode}&`}
        columnsData={columns}
        messageError={messageError}
      />

      {openModalAddColor && (
        <MyModal
          title={t("colors.addColor")}
          modalChange={setOpenModalAddColor}
        >
          <AddColor
            watchDataChange={watchDataChange}
            messageError={messageError}
          />
        </MyModal>
      )}

      {openModalEditColor && (
        <MyModal
          title={t("colors.editColor")}
          modalChange={setOpenModalEditColor}
        >
          <EditColor
            data={ColorSelected}
            watchDataChange={watchDataChange}
            updateData={updateData}
            messageError={messageError}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
