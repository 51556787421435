import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { Button } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import lineBreak from "../../../utils/lineBreak";

export default function ClickToCallResponse() {
  const { t } = useTranslation();
  const [jsonFinal, setJsonFinal] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [value, setValue] = useState<any>({
    text: "",
    contact_to_call: "",
    telephone: "",
  });

  const [values, setValues] = useState<any[]>([]);

  const generate = () => {
    const concat = lineBreak(description);
    values.forEach((item) => {
      if (item.text) {
        item.text = item.text.replaceAll("\\n", "\n");
      }
      if (item.contact_to_call) {
        item.contact_to_call = item.contact_to_call.replaceAll("\\n", "\n");
      }
      if (item.telephone) {
        item.telephone = item.telephone.replaceAll("\\n", "\n");
      }
    });

    const jsonGerado = {
      generic: [
        {
          response_type: "text",
          values: [
            {
              text_expression: {
                concat,
              },
            },
          ],
          selection_policy: "sequential",
        },
        {
          user_defined: {
            type: "click_to_call",
            value: {
              click_to_call: {
                options: values,
              },
            },
          },
          response_type: "user_defined",
        },
      ],
    };
    setJsonFinal(JSON.stringify(jsonGerado, null, 2));
  };

  const clear = () => {
    setDescription("");
    setValue({
      text: "",
      contact_to_call: "",
    });
    setValues([]);
    setJsonFinal("");
  };

  return (
    <Grid container>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.description")}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.text")}
            variant="outlined"
            value={value.text}
            onChange={(e) => setValue({ ...value, text: e.target.value })}
          />
        </Grid>
        <Grid item xs={3} sx={{ marginLeft: 2 }}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.contactToCall")}
            variant="outlined"
            value={value.contact_to_call}
            onChange={(e) =>
              setValue({ ...value, contact_to_call: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={3} sx={{ marginLeft: 2 }}>
          <TextField
            fullWidth
            size="small"
            id="outlined-basic"
            label={t("transformJson.telephone")}
            variant="outlined"
            value={value.telephone}
            onChange={(e) => setValue({ ...value, telephone: e.target.value })}
          />
        </Grid>
        <Grid item xs={1} sx={{ marginLeft: 2 }}>
          <IconButton
            color="primary"
            aria-label="add"
            disabled={
              value.text !== "" && value.contact_to_call !== "" ? false : true
            }
            onClick={(e) => {
              setValues(value);
              setValues([...values, value]);
              setValue({
                text: "",
                contact_to_call: "",
              });
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      {values.map((aux: any, index: number) => {
        return (
          <Grid container sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("transformJson.text")}
                variant="outlined"
                value={aux.text || ""}
                onChange={(e) => {
                  const newArr = [...values];
                  newArr[index].text = e.target.value;
                  setValue(newArr);
                }}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginLeft: 2 }}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("transformJson.contactToCall")}
                variant="outlined"
                value={aux.contact_to_call || ""}
                onChange={(e) => {
                  const newArr = [...values];
                  newArr[index].contact_to_call = e.target.value;
                  setValue(newArr);
                }}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginLeft: 2 }}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                label={t("transformJson.telephone")}
                variant="outlined"
                value={aux.telephone || ""}
                onChange={(e) => {
                  const newArr = [...values];
                  newArr[index].telephone = e.target.value;
                  setValue(newArr);
                }}
              />
            </Grid>
            <Grid item xs={1} sx={{ marginLeft: 2 }}>
              <IconButton
                color="primary"
                aria-label="add"
                onClick={(e) => {
                  const newArr = [...values];
                  newArr.splice(index, 1);
                  setValues(newArr);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item>
          <Button
            variant="contained"
            disabled={description === "" || values.length === 0 ? true : false}
            onClick={generate}
          >
            {t("button.Generate")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={clear}>
            {t("button.Clean")}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <pre
            style={{
              wordWrap: "break-word",
              maxWidth: "37rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {jsonFinal}
          </pre>
        </Grid>
        <Grid item>
          {jsonFinal !== "" ? (
            <Button
              disabled={false}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(jsonFinal);
              }}
              startIcon={<ContentCopyIcon />}
            >
              {t("button.Copy")}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
