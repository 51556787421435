import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function MenuLink({ title, toRoute }: any) {
  const activeStyle = {
    backgroundColor: "var(--blue-400)",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100%",
    paddingLeft: "1rem",
    display: "flex",
    placeItems: "center",
    color: "var(--white)",
  };

  const inativeStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    paddingLeft: "1rem",
    display: "flex",
    placeItems: "center",
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "1rem",
        paddingLeft: "1rem",
        paddingY: "1rem",
        overflow: "hidden",
        boxShadow: "0 1px 3px rgb(0 0 0 / 15%)",
        verticalAlign: "center",
        color: "var(--gray-100)",
      }}
    >
      <NavLink
        to={toRoute}
        style={({ isActive }): any => (isActive ? activeStyle : inativeStyle)}
      >
        {title}
      </NavLink>
    </Box>
  );
}
