import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { IColor } from "../../interfaces/IColor";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

export default function EditColor({
  watchDataChange,
  messageError,
  data,
}: any) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const addFormData = yup.object().shape({
    name: yup.string().required(t("colors.Required name")),
    code: yup.string().required(t("colors.Required code")),
    // active: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IColor>({ resolver: yupResolver(addFormData) }); // Add interface IColor

  const editColor: SubmitHandler<IColor> = async (values) => {
    // Add interface IColor
    console.log("values", values);

    try {
      await axios.put(
        `${CONFIG.API_URL}vehiclecolors/${data.id}`,
        {
          name: values.name,
          colorCode: values.code,
          countryCode,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("colors.colorEdited"));
  };

  const deleteColor = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}vehiclecolors/${data.id}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("colors.colorDeleted"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editColor)}>
      <FormInputText
        label={t("colors.code")}
        error={errors.code}
        defaultValue={data.colorCode}
        {...register("code")}
        inputProps={{
          maxLength: 4,
          minLength: 4,
        }}
      />
      <FormInputText
        label={t("colors.name")}
        error={errors.name}
        defaultValue={data.name}
        {...register("name")}
      />

      {/* <FormGroup sx={{ marginBottom: "1rem" }}>
        <FormControlLabel
          control={
            <Switch
              id="isCognitive"
              {...register("active")}
              defaultChecked={data.active ? true : false}
            />
          }
          label={t("colors.active")}
        />
      </FormGroup> */}

      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteColor}
      />
    </Box>
  );
}
