import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import lineBreak from "../../../utils/lineBreak";

export default function TextResponse() {
  const { t } = useTranslation();
  const [jsonFinal, setJsonFinal] = useState<string>("");
  const [value, setValue] = useState<any>({
    text: "",
  });

  const generate = () => {
    const concat = lineBreak(value.text);
    const jsonGerado = {
      generic: [
        {
          response_type: "text",
          values: [
            {
              text_expression: {
                concat,
              },
            },
          ],
          selection_policy: "sequential",
        },
      ],
    };
    setJsonFinal(JSON.stringify(jsonGerado, null, 2));
  };

  const clear = () => {
    setValue({
      text: "",
    });
    setJsonFinal("");
  };

  return (
    <Grid container>
      <Grid container sx={{ marginBottom: 1 }}>
        <Grid item xs={12}>
          <TextField
            multiline
            rows="4"
            fullWidth
            id="outlined-basic"
            label={t("transformJson.text")}
            variant="outlined"
            value={value.text}
            onChange={(e) => setValue({ ...value, text: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: 1 }}>
        <Grid item>
          <Button
            variant="contained"
            sx={{ marginTop: "8px" }}
            disabled={value.text === "" ? true : false}
            onClick={generate}
          >
            {t("button.Generate")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ marginTop: "8px" }} onClick={clear}>
            {t("button.Clean")}
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <pre
            style={{
              wordWrap: "break-word",
              maxWidth: "37rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {jsonFinal}
          </pre>
        </Grid>
        <Grid item>
          {jsonFinal !== "" ? (
            <Button
              disabled={false}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(jsonFinal);
              }}
              startIcon={<ContentCopyIcon />}
            >
              {t("button.Copy")}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
