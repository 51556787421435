import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import { useState } from "react";
import DataGridApi from "../../../components/dataGridApi";
import { ISpreadsheet } from "../../../interfaces/ISpreadsheet";
import { ISpreadsheetError } from "../../../interfaces/ISpreadsheetError";
import { CONFIG } from "../../../services/config";

interface Props {
  spreadSheet?: ISpreadsheet;
}

export default function Errors({ spreadSheet }: Props) {
  const columns: GridColDef[] = [
    {
      field: "errorDescription",
      headerName: t("digitalServicePlan.errorModalDescription"),
      flex: 1,
      headerClassName: "header-style",
    },
  ];

  const [errors, setErrors] = useState<ISpreadsheetError[]>([]);
  return (
    <DataGridApi
      columnsData={columns}
      host={CONFIG.SOB_API}
      endPoint={`excel/summaries/erros/${spreadSheet?.id}?`}
    />
  );
}
