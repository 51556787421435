import PublicIcon from "@mui/icons-material/Public";
import {
  FormControl,
  IconButton,
  InputLabel,
  Select,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";

export default function ButtonCountry() {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:899px)");
  const {
    user: { countries },
    country: { country },
    setCountry,
  } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCountryChangeButton = (selectedCountry: string) => {
    const selected = countries.find(
      ({ country }) => country === selectedCountry,
    );
    if (selected) {
      const { country, countryCode } = selected;
      setCountry({ country, countryCode });
    }
    setAnchorEl(null);
  };

  const handleChange = (selectCountry: string) => {
    const selected = countries.find(({ country }) => country === selectCountry);
    if (selected) {
      const { country, countryCode } = selected;
      setCountry({ country, countryCode });
    }
  };

  return (
    <>
      {matches && (
        <>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <PublicIcon sx={{ color: "var(--gray-900)" }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {countries.map(({ country, countryCode }) => {
              // console.log("item", item);
              return (
                <MenuItem
                  key={countryCode}
                  onClick={() => handleCountryChangeButton(country)}
                  data-my-value={countryCode}
                  value={country}
                >
                  {country}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}

      {!matches && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="countryInput">{t("header.Country")}</InputLabel>
          <Select
            labelId="countryInput"
            id="lanaguageSelect"
            value={country}
            onChange={($event) => handleChange($event.target.value)}
            label="Country"
          >
            {countries.map(({ country, countryCode }) => (
              <MenuItem key={countryCode} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
