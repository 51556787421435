import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, Box, Divider, Fade, IconButton, Modal } from "@mui/material";
import { ReactNode } from "react";

interface IMyModal {
  title: string;
  children: ReactNode;
  modalChange: any;
  component?: any;
  onSubmit?: any;
  width?: string;
}

export default function MyModal({
  title,
  children,
  modalChange,
  component,
  onSubmit,
  width,
}: IMyModal) {
  const handleClose = () => {
    modalChange(false);
  };

  const open: boolean = true;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      // onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        // position: "absolute",
        // top: "5%",
        overflowY: "scroll",
        display: "block",
        height: "100%",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: width || "50rem",
            width: width || "80%",
            bgcolor: "var(--white)",
            boxShadow: "0 2px 3px rgb(0 0 0 / 15%)",
            borderRadius: "4px",
            padding: 2,
            height: "fit-content",
            overflowY: "auto",
            maxHeight: "90%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              placeItems: "center",
              height: 30,
              marginTop: 2,
            }}
          >
            <h2>{title}</h2>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ marginY: "1.5rem" }} />

          <Box component={component} onSubmit={onSubmit}>
            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
