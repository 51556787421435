import { SubmitHandler, useForm } from "react-hook-form";
// import { Button } from "@mui/material";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as yup from "yup";
// import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IContact } from "../../interfaces/IContact";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

type FormValues = {
  id: number;
  name: string;
  description: string;
  value: string | number;
  type: string;
  countryCode: string;
  children: {
    name: string;
    value: string;
    type: string;
  }[];
};

export default function EditContact({
  data,
  watchDataChange,
  messageError,
}: IContact) {
  const { t } = useTranslation();
  const { tokenHeaders } = useContext(AuthContext);

  const [imageBase64, setImageBase64] = useState<any>(null);
  useEffect(() => {
    setImageBase64(data?.image);
  }, []);

  const addFormData = yup.object().shape({
    name: yup.string().required(t("support.Required name")),
    description: yup.string().required(t("support.Required description")),
    value: yup.string().required(t("support.Required value")),
    type: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(addFormData),
  });

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    const src = URL.createObjectURL(file);
    const preview = document.getElementById("imageChosen") as HTMLImageElement;
    preview.src = src;

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      setImageBase64(image[1]);
    };

    reader.readAsDataURL(file);
  };

  const editContact: SubmitHandler<FormValues> = async (values) => {
    try {
      await axios.put(
        `${CONFIG.API_URL}contacts/${data?.id}`,
        {
          countryCode: data?.countryCode,
          name: values.name,
          type: data?.type,
          description: values.description,
          image: imageBase64,
          value: values.value,
          children: [],
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("support.Contact edited"));
  };

  const deleteContact = async () => {
    try {
      await axios.delete(`${CONFIG.API_URL}contacts/${data?.id}`, tokenHeaders);
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("support.Contact deleted"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editContact)}>
      <FormInputText
        label={t("support.Name")}
        error={errors.name}
        {...register("name")}
        defaultValue={data?.name}
      />

      <FormInputText
        label={t("support.Description")}
        error={errors.description}
        {...register("description")}
        defaultValue={data?.description}
      />

      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnImage")}</span>
            <input
              accept=".png, .jpg, .jpeg"
              className="imageFile"
              type="file"
              name="fileInput"
              onChange={(event) => {
                convertImageToBase64(event);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          {data?.image && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <span>{t("countries.Current image")}:</span>
              <Box>
                <img
                  src={data?.image}
                  alt="No document"
                  width="60px"
                  id="imageChosen"
                />
                <br />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            overflow: "visible",
            lineHeight: "0.4375rem",
          }}
        >
          Tipo
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={t("users.profiles")}
          size="small"
          defaultValue={data?.type}
          {...register("type")}
          disabled
        >
          <MenuItem value="phone">{t("support.Phone")}</MenuItem>
          <MenuItem value="url">{t("support.URL")}</MenuItem>
          <MenuItem value="chat">{t("support.Chat")}</MenuItem>
          <MenuItem value="list">{t("support.List")}</MenuItem>
        </Select>
      </FormControl>

      <FormInputText
        label={t("support.Value")}
        {...register("value")}
        error={errors.value}
        defaultValue={data?.value}
        type={["url", "chat"].includes(data?.type || "") ? "url" : "number"}
      />

      <ActionButtons
        saveName={t("button.Save")}
        typeButton="submit"
        confirmDelete={deleteContact}
      />
    </Box>
  );
}
