import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { IPills } from "../../interfaces/IPills";
import ActionButtons from "../MyModal/ActionButtons";
import Pill from "./pill";
import { CONFIG } from "../../services/config";

export default function AddPills({
  watchDataChange,
  messageError,
  addFormData,
}: any) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IPills | any>({
    mode: "onBlur",
    resolver: yupResolver(addFormData),
  });

  const addPills: SubmitHandler<IPills> = async (values) => {
    console.log("values", values);
    let youtubeLink: string = values.youtubeLink;

    if (
      (youtubeLink !== "" && values.mediaFileName !== "") ||
      values.media !== ""
    ) {
      return alert(t("pills.linkOrMedia"));
    }

    if (youtubeLink === "" && values.mediaFileName === "") {
      return alert(t("pills.insertSomething"));
    }

    if (values.thumbFile === "") {
      return alert(t("pills.insertThumb"));
    }

    if (values.pillsVehicles.length <= 0) {
      return alert(t("pills.pillsVehiclesEmpty"));
    }

    if (values.youtubeLink !== "") {
      const newItem = values.youtubeLink.split("v=");
      youtubeLink = newItem[1];
    }

    if (
      (values.mediaExtension === "pdf" ||
        values.mediaExtension === "mkv" ||
        values.mediaExtension === "mp4") &&
      values.thumbFile === ""
    ) {
      return alert(t("pills.insertThumb"));
    }

    let flag;
    switch (values.mediaExtension) {
      case "mp4":
        flag = "video";
        break;
      case "mkv":
        flag = "video";
        break;
      case "pdf":
        flag = "pdf";
        break;
      case "jpg":
        flag = "image";
        break;
      case "png":
        flag = "image";
        break;
      case "jpeg":
        flag = "image";
        break;
      default:
        flag = "youtube";
        break;
    }

    try {
      await axios.post(
        `${CONFIG.API_URL}pills?countryCode=${countryCode}`,
        {
          title: values.title,
          description: values.description,
          active: values.active,
          pillCategoryId: values.category,
          mediaExtension: values.mediaExtension,
          mediaFile: values.mediaFile,
          mediaFileName: values.mediaFileName,
          thumbExtension: values.thumbExtension,
          thumbFile: values.thumbFile,
          thumbFileName: values.thumbFileName,
          youtubeLink: values.youtubeLink,
          type: flag,
          pillsVehicles: values.pillsVehicles,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("pills.addedPill"));
  };

  const methods = useFormContext();

  return (
    <FormProvider
      {...methods}
      reset={reset}
      register={register}
      control={control}
    >
      <Box component="form" onSubmit={handleSubmit(addPills)}>
        <Pill errors={errors} />

        <ActionButtons saveName={t("button.Add")} typeButton="submit" />
      </Box>
    </FormProvider>
  );
}
