import { Box, Slide } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useEffect, useState } from "react";
import { IQuickAlert } from "../../interfaces/IQuickAlert";

export default function QuickAlert({
  description,
  title,
  severity = "success",
  visibility = false,
}: IQuickAlert) {
  const [alertVisibility, setAlertVisibility] = useState(true);

  useEffect(() => {
    setAlertVisibility(visibility);
  }, [visibility]);

  return (
    <Slide
      direction="left"
      in={alertVisibility}
      timeout={{ enter: 1000, exit: 1000 }}
      addEndListener={() => {
        setTimeout(() => {
          setAlertVisibility(false);
        }, 3000);
      }}
    >
      <Box
        sx={{
          width: "25rem",
          position: "fixed",
          top: 70,
          bottom: 0,
          right: 0,
          zIndex: 9999,
        }}
      >
        <Alert severity={severity} variant="standard" className="alert">
          <AlertTitle>{title}</AlertTitle>
          {description}
        </Alert>
      </Box>
    </Slide>
  );
}
