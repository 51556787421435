import { AlertColor, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReceiptIcon from "@mui/icons-material/Receipt";
import moment from "moment";
import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import { CONFIG } from "../../services/config";
import { AuthContext } from "../../contexts/AuthContext";
import AddAndEditSendPush from "../../components/sendPush/addAndEditSendPush";

export default function SendPush() {
  const { t } = useTranslation();

  const [openModalAddSendPush, setOpenModalAddSendPush] = useState(false);
  const [openModalEditSendPush, setOpenModalEditSendPush] = useState(false);
  const [sendPushSelected, setSendPushSelected] = useState<any>();
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);
  const { tokenHeaders } = useContext(AuthContext);

  const stautsType: any[] = [
    {
      id: 1,
      name: "sendpush.success",
    },
    {
      id: 2,
      name: "sendpush.error",
    },
    {
      id: 3,
      name: "sendpush.warning",
    },
    {
      id: 4,
      name: "sendpush.canceled",
    },
    {
      id: 5,
      name: "sendpush.scheduled",
    },
    {
      id: 6,
      name: "sendpush.processing",
    },
  ];

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddSendPush(false);
    setOpenModalEditSendPush(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddSendPush = () => {
    setOpenModalAddSendPush(true);
  };

  const report = async (id: number) => {
    const now = Date();
    try {
      const b64 = await axios.get(
        `${CONFIG.API_URL}sendPush/report/${id}`,
        tokenHeaders,
      );
      const downloadLink = document.createElement("a");
      const fileName = `export_push${id}_${moment(now).format("L")}.xlsx`;
      downloadLink.href = b64.data;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
  };

  const renderDetailsButton = (params: any) => {
    return (
      <IconButton
        id="basic-button"
        onClick={() => {
          report(params.row.id);
        }}
      >
        <ReceiptIcon
          sx={{
            color: "white",
            backgroundColor: "#00AFF2",
            borderRadius: 50,
            padding: 1,
          }}
        />
      </IconButton>
    );
  };

  const editSendPush = (e: any) => {
    setOpenModalEditSendPush(true);
    console.log("e.row", e.row);
    setSendPushSelected(e.row);
  };

  const columns: GridColDef[] = [
    {
      field: "sendDate",
      headerName: t("sendpush.sendDate"),
      flex: 1,
      headerClassName: "header-style",
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "title",
      headerName: t("sendpush.title"),
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "status",
      headerName: t("sendpush.status"),
      flex: 1,
      headerClassName: "header-style",
      valueGetter: (params: any) => {
        const value = stautsType.find(
          (element) => element.id === params.row.status.id,
        );
        return t(value.name);
      },
    },
    {
      field: "id",
      headerName: t("sendpush.report"),
      flex: 0.5,
      headerClassName: "header-style",
      type: "actions",
      renderCell: renderDetailsButton,
    },
  ];

  return (
    <BoxContent
      title={t("menu.sendpush")}
      button
      titleButton={t("sendpush.addSendPush")}
      handleClick={handleClickModaAddSendPush}
      global
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />
      <DataGridApi
        onRowClick={editSendPush}
        dataUpdated={dataUpdated}
        endPoint="sendPush?"
        columnsData={columns}
        messageError={messageError}
      />
      {openModalAddSendPush && (
        <MyModal
          title={t("sendpush.addSendPush")}
          modalChange={setOpenModalAddSendPush}
        >
          <AddAndEditSendPush
            watchDataChange={watchDataChange}
            messageError={messageError}
            isEdit={false}
          />
        </MyModal>
      )}
      {openModalEditSendPush && (
        <MyModal
          title={t("sendpush.editSendPush")}
          modalChange={setOpenModalEditSendPush}
        >
          <AddAndEditSendPush
            watchDataChange={watchDataChange}
            messageError={messageError}
            isEdit
            data={sendPushSelected}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
