import { Box } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import { IPills } from "../../interfaces/IPills";
import ActionButtons from "../MyModal/ActionButtons";
import Pill from "./pill";
import { CONFIG } from "../../services/config";

interface IEditPills {
  watchDataChange: any;
  messageError: any;
  data: IPills;
  addFormData: any;
  editPill?: boolean;
}

export default function EditPills({
  watchDataChange,
  messageError,
  data,
  addFormData,
  editPill,
}: IEditPills) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [archive, setArchive] = useState<any>();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IPills | any>({
    mode: "onBlur",
    resolver: yupResolver(addFormData),
    defaultValues: data,
  });

  const editPills: SubmitHandler<IPills> = async (values) => {
    console.log("values", values);
    console.log("archive", archive);

    let youtubeLink: string = values.youtubeLink;

    if (youtubeLink !== "" && values.media !== null) {
      return alert(t("pills.linkOrMedia"));
    }

    if (youtubeLink === "" && values.media === "") {
      return alert(t("pills.insertSomething"));
    }

    if (values.pillsVehicles.length <= 0) {
      return alert(t("pills.pillsVehiclesEmpty"));
    }

    if (values.youtubeLink !== "") {
      const newItem = values.youtubeLink.split("v=");
      youtubeLink = newItem[1];
    }

    let flag;
    switch (values.mediaExtension) {
      case "mp4":
        flag = "video";
        break;
      case "mkv":
        flag = "video";
        break;
      case "pdf":
        flag = "pdf";
        break;
      case "jpg":
        flag = "image";
        break;
      case "png":
        flag = "image";
        break;
      case "jpeg":
        flag = "image";
        break;
      default:
        flag = data.type;
        break;
    }

    try {
      await axios.put(
        `${CONFIG.API_URL}pills?id=${data?.pillId}&countryCode=${countryCode}`,
        {
          title: values.title,
          description: values.description,
          active: values.active,
          pillCategoryId: values.category,
          mediaExtension: values.mediaExtension,
          mediaFile: values.mediaFile,
          mediaFileName: values.mediaFileName,
          thumbExtension: values.thumbExtension,
          thumbFile: values.thumbFile,
          thumbFileName: values.thumbFileName,
          youtubeLink: values.youtubeLink,
          type: flag,
          pillsVehicles: values.pillsVehicles,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("pills.editedPill"));
  };

  const deletePills = async () => {
    try {
      await axios.delete(
        `${CONFIG.API_URL}pills?id=${data?.pillId}`,
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("pills.deletedPill"));
  };

  const methods = useFormContext();

  return (
    <FormProvider
      {...methods}
      reset={reset}
      register={register}
      control={control}
    >
      <Box component="form" onSubmit={handleSubmit(editPills)}>
        {data && <Pill errors={errors} data={data} editPill />}

        <ActionButtons
          saveName={t("button.Save")}
          typeButton="submit"
          confirmDelete={deletePills}
        />
      </Box>
    </FormProvider>
  );
}
