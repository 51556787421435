import { Box, Grid, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BoxContent from "../../components/boxContent";
import TextResponse from "./textResponse";
import ImageResponse from "./imageResponse";
import MenuImageResponse from "./menuImageResponse";
import MenuResponse from "./menuResponse";
import CardsImageResponse from "./cardsImageResponse";
import CardsResponse from "./cardsResponse";
import AppActionLinkResponse from "./appActionLinkResponse";
import ClickToCallResponse from "./clickToCallResponse";
import VideoResponse from "./videoResponse";
import OptionResponse from "./optionResponse";

export default function TransformJson() {
  const [responseType, setResponseType] = useState("");
  const { t } = useTranslation();

  return (
    <BoxContent title={t("menu.transformJson")} global>
      <Box>
        <Grid
          container
          spacing={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={4}>
            <Select
              displayEmpty
              defaultValue=""
              labelId="typeJsonSelect"
              id="handleTypeJsonSelect"
              sx={{ height: 40, width: "100%" }}
              placeholder="Select"
              onChange={(type: any) => {
                setResponseType(type.target.value);
              }}
            >
              <MenuItem disabled value="">
                <em>{t("transformJson.typeResponse")}</em>
              </MenuItem>
              <MenuItem value="text">{t("transformJson.textPure")}</MenuItem>
              <MenuItem value="image">
                {t("transformJson.textWithImage")}
              </MenuItem>
              <MenuItem value="cards">{t("transformJson.cardImage")}</MenuItem>
              <MenuItem value="cards_image">
                {t("transformJson.cardwithImage")}
              </MenuItem>
              <MenuItem value="app_action_link">
                {t("transformJson.clickToService")}
              </MenuItem>
              <MenuItem value="click_to_call">
                {t("transformJson.clickToCall")}
              </MenuItem>
              <MenuItem value="menu_image">
                {t("transformJson.menuWithImage")}
              </MenuItem>
              <MenuItem value="menu">{t("transformJson.menuImage")}</MenuItem>
              <MenuItem value="video">{t("transformJson.video")}</MenuItem>
              <MenuItem value="option">{t("transformJson.option")}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
      {responseType === "text" ? <TextResponse /> : null}
      {responseType === "image" ? <ImageResponse /> : null}
      {responseType === "cards" ? <CardsResponse /> : null}
      {responseType === "cards_image" ? <CardsImageResponse /> : null}
      {responseType === "app_action_link" ? <AppActionLinkResponse /> : null}
      {responseType === "click_to_call" ? <ClickToCallResponse /> : null}
      {responseType === "menu_image" ? <MenuImageResponse /> : null}
      {responseType === "menu" ? <MenuResponse /> : null}
      {responseType === "video" ? <VideoResponse /> : null}
      {responseType === "option" ? <OptionResponse /> : null}
    </BoxContent>
  );
}
