import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Switch, FormControlLabel, TextField, Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import { CONFIG } from "../../../services/config";
import { FormInputText } from "../../form/FormInputText";
import ActionButtons from "../../MyModal/ActionButtons";
import { LabelForm, LabelContainer } from "../Form/label";

type FormValues = {
  id?: number;
  name: string;
  description: string;
  active: boolean;
  image: any;
};

export default function AddNewCategory({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  yup.setLocale({
    mixed: {
      required: t(`yup.mixed.required`),
      notType: t(`yup.mixed.notType`),
    },
    string: {
      min: t(`yup.string.min`),
      max: t(`yup.string.max`),
      email: t(`yup.string.email`),
    },
    number: {
      min: t(`yup.number.min`),
      max: t(`yup.number.max`),
      positive: t(`yup.number.positive`),
      integer: t(`yup.number.integer`),
    },
    date: {
      min: t(`yup.date.min`),
      max: t(`yup.date.max`),
    },
    array: {
      min: t(`yup.array.min`),
      max: t(`yup.array.max`),
    },
  });
  const {
    tokenHeaders,
    country: { countryCode },
    user: { email },
  } = useContext(AuthContext);

  const [imageBase64, setImageBase64] = useState<string | null>(null);

  const addFormData = yup.object().shape({
    name: yup
      .string()
      .max(45)
      .required(t("faq.category.Required name"))
      .label(t("faq.category.Name")),
    description: yup
      .string()
      .max(45)
      .required(t("faq.category.Required description"))
      .label(t("faq.category.Description")),
    active: yup.boolean().required(),
    image: yup
      .mixed()
      .required(t("faq.category.Required image"))
      .label(t("faq.category.Image")),
  });

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(addFormData) });

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setImageBase64(reader.result?.toString().split(",")[1] || null);
    };

    reader.readAsDataURL(file);
  };

  const addCategory: SubmitHandler<FormValues> = async (values) => {
    if (!imageBase64) {
      setError("image", {
        message: t("faq.category.Required image"),
        type: "required",
      });
      return;
    }

    try {
      await axios.post(
        `${CONFIG.API_URL}faq/category`,
        {
          name: values.name,
          description: values.description,
          active: values.active,
          image: imageBase64,
          createdBy: email,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return messageError(t("faq.category.category_exists"));
      }
      if (error?.response?.data?.message) {
        return messageError(error.response.data.message);
      }

      return messageError(error.message);
    }
    watchDataChange(t("faq.category.Category added"));
  };

  return (
    <Box component="form" onSubmit={handleSubmit(addCategory)}>
      <LabelContainer>
        <LabelForm
          required
          label={t("faq.category.Name")}
          requires={[
            {
              msg: t("faq.requires.max_characters"),
              value: "45",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.category.Name")}
            error={errors.name}
            {...register("name")}
          />
        </LabelForm>

        <LabelForm
          required
          label={t("faq.category.Description")}
          requires={[
            {
              msg: t("faq.category.labels.description"),
            },
            {
              msg: t("faq.requires.max_characters"),
              value: "45",
            },
          ]}
        >
          <FormInputText
            placeholder={t("faq.category.Description")}
            error={errors.description}
            {...register("description")}
          />
        </LabelForm>

        <LabelForm
          required
          label={t("faq.category.Image")}
          requires={[
            {
              msg: t("faq.requires.recommended_dimension"),
              value: "32 x 32",
            },
            {
              msg: t("faq.requires.image_type"),
              value: "JPEG",
            },
            {
              msg: t("faq.requires.maximum_size"),
              value: "5 MB",
            },
          ]}
        >
          <Grid container spacing={2} columns={{ xs: 6, sm: 8, md: 12 }}>
            <Grid item xs={6}>
              {/* <span>{t("faq.category.UploadImage")}</span> */}

              <input
                accept=".png, .jpg, .jpeg"
                type="file"
                {...register("image")}
                onChange={convertImageToBase64}
              />
            </Grid>
            <Grid item xs={6} bgcolor="#fff">
              <Box display="flex" alignItems="center">
                <p>Imagem atual:</p>
                <div style={{ width: 50, height: 50, marginLeft: "16px" }}>
                  {imageBase64 && (
                    <img
                      src={`data:image/png;base64,${imageBase64}`}
                      alt="icon"
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                    />
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>

          {errors.image && (
            <Box
              sx={{
                color: "var(--red-100)",
                marginBottom: 3,
              }}
            >
              <span>{String(errors?.image?.message)}</span>
            </Box>
          )}
        </LabelForm>
        <LabelForm label={t("faq.category.Active")}>
          <FormControlLabel
            control={<Switch {...register("active")} />}
            label=""
          />
        </LabelForm>
      </LabelContainer>

      <ActionButtons
        // save={addCategory}
        saveName={t("button.Add")}
        typeButton="submit"
      />
    </Box>
  );
}
