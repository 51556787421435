import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Button, Grid, IconButton, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/logo-vw.svg";
import { AuthContext } from "../../contexts/AuthContext";
import useCookies from "../../services/CookieService";
import ButtonCountry from "../button/ButtonCountry";
import ButtonLanguage from "../button/ButtonLanguage";
import HamburguerMenu from "../menu/HamburguerMenu";

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { signOut, user } = useContext(AuthContext);

  const matches = useMediaQuery("(max-width:899px)");

  const logout = () => {
    signOut();
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0 2px 3px rgb(0 0 0 / 15%)",
        flexGrow: 1,
        // position: "sticky",
        top: 0,
        zIndex: 99999,
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: "auto",
          maxWidth: "1250px !important",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={3}>
            {!matches && (
              <Box
                sx={{
                  paddingTop: "5px",
                  textAlign: "center",
                  // width: "15rem",
                  backgroundColor: "var(--white-200)",
                }}
              >
                <NavLink to="/home">
                  <img src={vwLogo} alt="Volkswagen Logo" width="50px" />
                </NavLink>
              </Box>
            )}

            {matches && <HamburguerMenu />}
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                display: "flex",
                placeItems: "center",
                gap: "1rem",
                height: "100%",
                placeContent: "flex-end",
              }}
            >
              <ButtonLanguage />
              {user && <ButtonCountry />}
              {/* <Button variant="contained" onclick={signOut}>
            {t("header.Exit")}
          </Button> */}

              {user && matches && (
                <IconButton type="button" onClick={logout}>
                  <LogoutIcon sx={{ color: "var(--gray-900)" }} />
                </IconButton>
              )}
              {user && !matches && (
                <Button sx={{ color: "var(--gray-900)" }} onClick={logout}>
                  {t("header.Exit")}
                </Button>
              )}
              {!user && matches && (
                <IconButton type="button" onClick={logout}>
                  <LogoutIcon sx={{ color: "var(--gray-900)" }} />
                </IconButton>
              )}
              {!user && !matches && (
                <Button sx={{ color: "var(--gray-900)" }} onClick={logout}>
                  {t("header.Sign in")}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
