import { useContext } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locale/en/index.json";
import es from "./locale/es/index.json";
import ptbr from "./locale/ptbr/index.json";
import useCookies from "../CookieService";

const { findCookie } = useCookies();

const resources = {
  en: {
    translations: en,
  },
  ptbr: {
    translations: ptbr,
  },
  es: {
    translations: es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: findCookie("language")?.value || "en",
  lng: findCookie("language")?.value || "en",
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ptbr", "es"];

export default i18n;
