import { AlertColor, Box, Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import BoxContent from "../../components/boxContent";
import DataGridApi from "../../components/dataGridApi";
import QuickAlert from "../../components/form/quickAlert";
import MyModal from "../../components/MyModal";
import AddPills from "../../components/pills/addPills";
import EditPills from "../../components/pills/editPills";
import ManageCategories from "../../components/pills/manageCategories";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import pillsMock from "./pills.mock.json";

export default function Pills() {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [openModalAddPills, setOpenModalAddPills] = useState(false);
  const [openModalCategories, setOpenModalCategories] = useState(false);
  const [openModalEditPills, setOpenModalEditPills] = useState(false);
  const [pillsSelected, setPillsSelected] = useState<any>();

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [messageQuickAlert, setMessageQuickAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<AlertColor>("success");
  const [titleMessage, setTitleMessage] = useState("");
  const [dataUpdated, setDataUpdated] = useState(0);

  const addFormData = yup.object().shape({
    title: yup.string().required(t("pills.Required title")),
    description: yup.string().required(t("pills.Required description")),
    category: yup.string().required(t("pills.Required category")),
    youtubeLink: yup.string(),
    active: yup.string(),
    mediaFile: yup.string(),
    mediaFileName: yup.string(),
    mediaExtension: yup.string(),
    media: yup.string().nullable(true),
    thumbFile: yup.string(),
    thumbFileName: yup.string(),
    thumbExtension: yup.string(),
    thumbMedia: yup.string(),
    pillsVehicles: yup.array().of(
      yup.object().shape({
        vehicleId: yup.string().nullable(true),
        vehicleName: yup.string().nullable(true),
        vehicleVersionId: yup.string().nullable(true),
        vehicleVersionName: yup.string().nullable(true),
        vehicleVersionYear: yup.string().nullable(true),
        // vehicleVersionYear: yup
        //   .string()
        //   .required(t("pills.Required vehicleVersionYear")),
        vehicleVersionEdition: yup.string().nullable(true),
        packageVehicle: yup.string().nullable(true),
      }),
    ),
  });

  const updateData = () => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);
  };

  const watchDataChange = (succesMessage?: string) => {
    const dataChange = dataUpdated + 1;
    setDataUpdated(dataChange);

    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setOpenModalAddPills(false);
    setOpenModalEditPills(false);
    setTitleMessage("Success");
    setMessageQuickAlert(succesMessage ? succesMessage : "");
    setSeverityAlert("success");
  };

  const messageError = (errorMessage?: string) => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 3000);

    setTitleMessage("Error");
    setMessageQuickAlert(errorMessage ? errorMessage : "There was an error");
    setSeverityAlert("error");
  };

  const handleClickModaAddPills = () => {
    setOpenModalAddPills(true);
  };

  const editPills = async (e: any) => {
    console.log("e.row", e.row);
    // resetEdit();
    // setPillsSelected(e.row);
    // setPillsSelected({});

    await axios
      .get(`${CONFIG.API_URL}pills/${e.row.pillId}`, tokenHeaders)
      .then((response) => {
        console.log("response.data.content", response.data);
        setPillsSelected(response.data);
      });

    setOpenModalEditPills(true);
  };

  const columns: GridColDef[] = [
    {
      field: "thumb",
      headerName: t("countries.Image"),
      renderCell: (params) =>
        params.value ? (
          <img
            src={params.value}
            alt="No document"
            style={{ maxWidth: "100%", maxHeight: "150px", margin: "auto" }}
          />
        ) : (
          ""
        ),
      width: 200,
      headerClassName: "header-style",
    },
    {
      field: "title",
      headerName: t("pills.title"),
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "pillsCategory",
      headerName: t("pills.category"),
      flex: 1,
      headerClassName: "header-style",
      renderCell: (params) =>
        params.value ? (
          <p title={params.value.description}>{params.value.description}</p>
        ) : (
          ""
        ),
    },
    {
      field: "pillsVehicles",
      headerName: t("pills.models"),
      flex: 1,
      headerClassName: "header-style",
      renderCell: (params) =>
        params.value ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              columnGap: 3,
              flexWrap: "wrap",
              marginTop: 2,
            }}
          >
            {params.value.map((item: any, idx: number) => {
              return (
                <Box key={idx}>
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: 5,
                      paddingX: 1,
                      paddingY: 0.2,
                      backgroundColor: "var(--blue-400)",
                      display: "flex",
                      placeItems: "center",
                    }}
                  >
                    <span style={{ color: "white", fontSize: 12 }}>
                      {item.vehicleName === null
                        ? t("pills.allVehicles")
                        : item.vehicleName}{" "}
                      |{" "}
                      {item.vehicleVersionName !== "" &&
                      item.vehicleVersionName !== null ? (
                        <span>{item.vehicleVersionName}</span>
                      ) : (
                        t("pills.allVersion")
                      )}
                    </span>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          ""
        ),
    },
  ];

  return (
    <BoxContent
      title={t("menu.pills")}
      button
      titleButton={t("pills.addPill")}
      handleClick={handleClickModaAddPills}
      noGlobal
    >
      <QuickAlert
        visibility={visibleAlert}
        description={messageQuickAlert}
        severity={severityAlert}
        title={titleMessage}
      />

      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}>
        <Button
          variant="contained"
          onClick={() => setOpenModalCategories(true)}
        >
          {t("pills.managePills")}
        </Button>
      </Box>

      <DataGridApi
        onRowClick={editPills}
        dataUpdated={dataUpdated}
        endPoint={`pills?countryCode=${countryCode}&`}
        columnsData={columns}
        messageError={messageError}
        rowHeight={160}
        // mock={pillsMock}
      />

      {openModalAddPills && (
        <MyModal title={t("pills.addPill")} modalChange={setOpenModalAddPills}>
          <AddPills
            watchDataChange={watchDataChange}
            messageError={messageError}
            addFormData={addFormData}
          />
        </MyModal>
      )}

      {openModalEditPills && (
        <MyModal
          title={t("pills.editPill")}
          modalChange={setOpenModalEditPills}
        >
          <EditPills
            watchDataChange={watchDataChange}
            messageError={messageError}
            data={pillsSelected}
            addFormData={addFormData}
          />
        </MyModal>
      )}

      {openModalCategories && (
        <MyModal
          title={t("pills.managePills")}
          modalChange={setOpenModalCategories}
        >
          <ManageCategories
            watchDataChange={watchDataChange}
            messageError={messageError}
            dataUpdated={dataUpdated}
            updateData={updateData}
          />
        </MyModal>
      )}
    </BoxContent>
  );
}
