import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalConfirmation({
  openDelete,
  confirmDelete,
  title,
}: any) {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    openDelete(false);
  };

  return (
    <div>
      <Dialog
        open
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {title ? title : <span>{t("button.Confirm delete")}</span>}:
        </DialogTitle>
        <DialogActions>
          {loading && <CircularProgress />}
          <Button variant="contained" onClick={handleClose}>
            {t("button.No")}
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={async () => {
              setLoading(true);
              await confirmDelete();
              setLoading(false);
              handleClose();
            }}
          >
            {t("button.Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
