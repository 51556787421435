import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import welcome from "../../assets/images/welcomeVW.png";
import useCookies from "../../services/CookieService";

export default function Welcome() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const matches = useMediaQuery("(min-width:900px)");

  const { removeAll } = useCookies();
  const navegateToLogin = () => {
    removeAll("language", "languageName", "countryCode", "countryName");
    navigate("../login");
  };
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <Grid
        item
        xs={7}
        sx={{
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
          justifyContent: "space-around",
          // backgroundColor: "red",
          padding: matches ? 0 : 2,
        }}
      >
        <Box
          sx={{
            padding: matches ? 10 : 2,
            width: matches ? 480 : "100%",
          }}
        >
          <p style={{ fontWeight: "700", fontSize: 16, lineHeight: "20px" }}>
            Volkswagen ID
          </p>
          <p style={{ fontWeight: "700", fontSize: 32, lineHeight: "40px" }}>
            {t("welcome.welcome")}
          </p>
          <p style={{ fontWeight: "300", fontSize: 24, lineHeight: "32px" }}>
            {t("welcome.text")}
          </p>
          <Box
            sx={{
              marginTop: matches ? 10 : 5,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={navegateToLogin}
              sx={{
                backgroundColor: "var(--blue-900)",
                borderRadius: 50,
                width: 295,
                height: 48,
                "&:hover": {
                  backgroundColor: "var(--blue-900)",
                  opacity: 0.9,
                },
              }}
            >
              {t("welcome.signIn")}
            </Button>
          </Box>
        </Box>

        <Box sx={{ opacity: 0 }}>s</Box>
      </Grid>
      <Grid
        item
        xs={5}
        style={{
          position: "relative",
          maxHeight: "100vh",
        }}
      >
        {/* <Box style={{ position: "absolute", backgroundColor: "red", width:100% }}> */}
        <img
          src={welcome}
          alt="Welcome"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        {/* </Box> */}
      </Grid>
    </Grid>
  );
}
